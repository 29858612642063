import {
  Box,
  Flex,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { memo, useEffect } from "react";
import InfoStep1 from "./infoStep1";
import IconX from "../../../assets/images/booking/x-circle.svg";
import InfoStep2 from "./infoStep2";
import { useTab } from "../../../contexts/useTab";

const FormDetail = memo(({ isOpen, onClose, defaultValues }) => {
  const { commissionInfoTab, setCommissionInfoTab } = useTab();

  const tabInfo = [
    {
      title: "Xác nhận tính hoa hồng",
      content: <InfoStep1 defaultValues={defaultValues} />,
    },
    {
      title: "Thanh toán hoa hồng",
      content: <InfoStep2 defaultValues={defaultValues}/>,
    },
  ];

  const setCommissionInfoTabData = (index) => { 
    if(defaultValues?.confirmCommissionStatus==='APPROVED' && index===1){
      setCommissionInfoTab(index);
    }else if(index===0){
      setCommissionInfoTab(index);
    }
  }

  useEffect(()=>{
    if(isOpen){
      setCommissionInfoTab(0);
    }
  },[isOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="var(--color-main)"
        borderRadius={"16px"}
        position={"relative"}
        w={"100%"}
        maxW={"625px"}
      >
        <Box
          h={"86.5vh"}
          w={"100%"}
          overflowY={"scroll"}
          sx={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              width: "4px",
              borderRadius: "50px",
              background: "var(--color-background)",
            },
            "&::-moz-scrollbar": {
              width: "4px",
            },
            "&::-moz-scrollbar-thumb": {
              width: "4px",
              borderRadius: "50px",
              background: "var(--color-background)",
            },
          }}
        >
          <Box>
            <Flex
              m={"25px 24px 0"}
              alignItems={"center"}
              position={"relative"}
              alignContent={"center"}
            >
              <Text
                color={"var(--color-header-employee)"}
                fontSize={"16px"}
                fontWeight={"600"}
              >
                Tính hoa hồng
              </Text>
              <Box
                position={"absolute"}
                right={0}
                cursor={"pointer"}
                onClick={onClose}
              >
                <Image src={IconX} />
              </Box>
            </Flex>
            <Box
              mt={"25px"}
              borderBottom={"0.5px solid var(--color-black-2)"}
            >
              <Flex
                margin={"0 auto"}
                maxW={"484px"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={{ base: "24px" }}
              >
                {tabInfo.map((e, index) => {
                  return (
                    <Flex
                    alignItems={"center"}
                    justifyContent={"center"}
                    key={index}
                    flex={1}
                    >
                      <Flex
                        alignItems={"center"}
                        justifyContent={index === 0 ? "flex-start" : "flex-end"}
                        onClick={() => setCommissionInfoTabData(index)}
                        cursor={"pointer"}
                        zIndex={"10"}
                        flex={1}
                      >
                        <Text
                          position={"relative"}
                          paddingBottom={"8px"}
                          _before={{
                            content: '""',
                            position: "absolute",
                            bottom: "-1px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            width: "50px",
                            height: {
                              base: commissionInfoTab === index ? "2px" : "0",
                            },
                            backgroundColor: "var(--color-secondary)",
                          }}
                          fontSize={{
                            base: "16px",
                          }}
                          fontWeight={500}
                          lineHeight={"normal"}
                          fontFamily="var(--font-heading-main)"
                          color={
                            commissionInfoTab === index
                              ? "var(--color-secondary)"
                              : "var(--color-black-5)"
                          }
                          textAlign={"center"}
                          whiteSpace="nowrap"
                        >
                          {e?.title}
                        </Text>
                      </Flex>
                      {index === 0 && (
                        <Flex flex={1} justifyContent={"flex-end"}>
                        <Box
                          w={"1px"}
                          h={"12px"}
                          bg={"var(--color-black-2)"}
                        ></Box>
                        </Flex>
                      )}
                    </Flex>
                  );
                })}
              </Flex>
            </Box>
            {tabInfo[commissionInfoTab].content}
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
});

export default FormDetail;
