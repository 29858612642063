import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import TableCommissionConfirmation from "./tableCommissionConfirmation";
import iconExcel from "../../assets/images/employee/iconExcel.svg";
import { useCommissionConfirmation } from "../../hooks/useCommissionConfirmation";
import toast from "react-hot-toast";
import { bookingEventCall } from "../../api/bookingEvent";
import { exportFile } from "../../utils/tools";

export default function CommissionConfirmation() {
  const [paramExport, setParamExport] = useState(null);

  const { isRefetching, entries, bookingEventData } =
    useCommissionConfirmation();

  const handleExport = async () => {
    const checkInColumnMapping = {
      code: "MĐNBK",
      name: "Tên đợt booking",
      project: "Tên dự án",
      product: "Sản phẩm",
      startTime: "TG bắt đầu",
      endTime: "TG kết thúc",
      price: "Số tiền booking",
      creator: "Nhân viên tạo",
      createdAt: "Ngày tạo",
    };
    if (bookingEventData?.length) {
      const data = await bookingEventCall.export(entries, paramExport);
      if (data) {
        exportFile(data, checkInColumnMapping, "bang_dot_nhan_booking.xlsx");
        toast.success("Download file thành công");
      } else {
        toast.error("Không có dữ liệu");
      }
    } else toast.error("Không có dữ liệu");
  };

  return (
    <Box
      w={{ base: "100%" }}
      backgroundColor="var(--color-backgroundmain)"
      p={{ base: "24px 16px 16px" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={{ base: "74px" }}>
          <Text fontSize={{ base: "20px" }} fontWeight={600}>
            Xác nhận hoa hồng
          </Text>
        </Flex>
        <Flex alignItems={"center"} gap={"16px"}>
          <Button
            display={"flex"}
            alignItems={"center"}
            p={"10px 16px 10px 12px"}
            gap={"8px"}
            h={"44px"}
            backgroundImage="var(--linear-gradient-excel)"
            _hover={{
              filter: "brightness(90%)",
            }}
            onClick={handleExport}
          >
            <Image src={iconExcel} />
            <Text
              fontSize={{ base: "15px" }}
              fontWeight={600}
              color="var(--color-main)"
            >
              Xuất excel
            </Text>
          </Button>
        </Flex>
      </Flex>
      <Box mt={{ base: "23px" }}>
        <TableCommissionConfirmation
          isRefetchingWithCreate={isRefetching}
          paramData={setParamExport}
        />
      </Box>
    </Box>
  );
}
