import moment from "moment-timezone";
const TIMEZONE = "Asia/Ho_Chi_Minh";

const toNonAccentVietnamese = function (str) {
  str = str.replace(/A|Á|À|Ã|Ạ|Â|Ấ|Ầ|Ẫ|Ậ|Ă|Ắ|Ằ|Ẵ|Ặ/g, "A");
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/E|É|È|Ẽ|Ẹ|Ê|Ế|Ề|Ễ|Ệ/, "E");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/I|Í|Ì|Ĩ|Ị/g, "I");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/O|Ó|Ò|Õ|Ọ|Ô|Ố|Ồ|Ỗ|Ộ|Ơ|Ớ|Ờ|Ỡ|Ợ/g, "O");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/U|Ú|Ù|Ũ|Ụ|Ư|Ứ|Ừ|Ữ|Ự/g, "U");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/Y|Ý|Ỳ|Ỹ|Ỵ/g, "Y");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/Đ/g, "D");
  str = str.replace(/đ/g, "d");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
  return str;
};

export const normalize = function (string) {
  let str = String(string).toLowerCase();
  return toNonAccentVietnamese(str);
};

export const formatDate = (dateString) => {
  if (!dateString) return "";

  const date = moment(dateString, [
    "DD/MM/YYYY",
    "YYYY-MM-DD",
    "YYYY-MM-DDTHH:mm:ss.SSSZ",
    "MM/DD/YYYY",
  ]).tz(TIMEZONE);

  if (!date.isValid()) return "Invalid date";

  return date.format("DD/MM/YYYY");
};

export const formatDateForInput = (dateString) => {
  if (!dateString) {
    return ""; // Trả về chuỗi rỗng nếu không có giá trị đầu vào
  }

  const date = moment(dateString, [
    "YYYY-MM-DD", // Định dạng ISO ngắn
    "YYYY-MM-DDTHH:mm:ssZ", // ISO đầy đủ
    "DD/MM/YYYY", // Định dạng ngày tháng
    "MM/DD/YYYY", // Định dạng Mỹ
  ]).tz(TIMEZONE);

  if (!date.isValid()) {
    return "";
  }

  return date.format("YYYY-MM-DD");
};

export function delay(timeout) {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
}

export const isEndTimeGreater = (start, end) => {
  const [startHour, startMinute] = start.split(":").map(Number);
  const [endHour, endMinute] = end.split(":").map(Number);

  const startTimeInMinutes = startHour * 60 + startMinute;
  const endTimeInMinutes = endHour * 60 + endMinute;

  return endTimeInMinutes > startTimeInMinutes;
};

export const isEndDateGreater = (start, end) => {
  if (!start || !end) return false;

  const startDate = new Date(start);
  const endDate = new Date(end);

  return endDate > startDate;
};

export const customDateTimeFormat = (timestamp) => {
  if (timestamp === "" || timestamp === "_" || timestamp === "-") {
    return timestamp;
  }
  const date = new Date(timestamp);

  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = date.getUTCFullYear();

  const formattedDate = `${hours}:${minutes} ${day}/${month}/${year}`;
  return formattedDate;
};

export const customDateFormat = (timestamp) => {
  if (timestamp === "" || timestamp === "_" || timestamp === "-") {
    return timestamp;
  }
  const date = new Date(timestamp);

  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = date.getUTCFullYear();

  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

export const customDateFormatType2 = (timestamp) => {
  if (timestamp === "" || timestamp === "_" || timestamp === "-") {
    return timestamp;
  }
  const date = new Date(timestamp);

  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = date.getUTCFullYear();

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const formatDateToLocal = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const generateCodeFromName = (name) => {
  if (!name) return "";
  return name
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\s+/g, "_")
    .toUpperCase();
};

export const formatDateToUTC = (dateString) => {
  const date = new Date(dateString);
  date.setHours(date.getHours() - 7); // Điều chỉnh múi giờ GMT+7 về UTC

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

//bỏ dấu
export const removeAccents = (str) => {
  const accents = [
    { base: "a", letters: /[áàảãạăắằẳẵặâấầẩẫậ]/g },
    { base: "e", letters: /[éèẻẽẹêếềểễệ]/g },
    { base: "i", letters: /[íìỉĩị]/g },
    { base: "o", letters: /[óòỏõọôốồổỗộơớờởỡợ]/g },
    { base: "u", letters: /[úùủũụưứừửữự]/g },
    { base: "y", letters: /[ýỳỷỹỵ]/g },
    { base: "d", letters: /[đ]/g },
  ];

  accents.forEach(({ base, letters }) => {
    str = str.replace(letters, base);
  });

  return str;
};

export const formatTime = (dateString) => {
  const date = new Date(dateString);

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
};

export function formatNumber(number) {
  return number.toLocaleString('vi-VN');
}

export function formatNumberStyle(number) {
  let [integer, decimal] = number.toString().split('.');  
  integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');  
  return decimal ? `${integer}.${decimal}` : integer; 
}

export function formatCash(str) {
  return str
    .split("")
    .reverse()
    .reduce((prev, next, index) => {
      return (index % 3 ? next : next + ".") + prev;
    });
}

export const formatPrice = (price) => {
  return new Intl.NumberFormat("en-DE").format(price);
};

export const formatNumberFloat = (number) => {
  if (number % 1 === 0) {
    return number;
  }
  const decimalPart = number.toString().split('.')[1];
  if (decimalPart && decimalPart.length > 2) {
    return parseFloat(number.toFixed(2));
  }
  return number;
}