import {
  Avatar,
  Box,
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  //
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  HStack,
  Tag,
  TagLabel,
  Divider,
  Stack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import iconHeader from "../../assets/images/icon-bell-header.png";
import { useSelector } from "react-redux";
import lockIcon from "../../assets/images/lock.svg";
import { commonCall } from "../../api/common";
import userIcon from "../../assets/images/user.svg";
import logoutIcon from "../../assets/images/log-out.svg";
import FormEmployee from "../form/formEmployee";
import { useEmployeeInfo } from "../../hooks/useEmployeeInfo";
import { employeeCall } from "../../api/employee";
import { store } from "../../redux/store";
import { loginUpdateData, logOutSuccess } from "../../redux/slices/authSlice";
import toast from "react-hot-toast";
import PopupChangePasswordCurrent from "../popup/popupChangePasswordCurrent";
import DeleteDialog from "../dialog/delete";
import { useNotifications } from "../../hooks/useNotification";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdNotificationsActive } from "react-icons/md";
import { TbActivityHeartbeat } from "react-icons/tb";
const API_URL = process.env.REACT_APP_BACKEND_API || "http://localhost:3001";
const Header = () => {
  const user = useSelector((state) => state.auth.login?.currentUser);
  const [messageStatus, setMessageStatus] = useState(null);
  const {
    notifications,
    totalPages,
    currentPage,
    entries,
    startEntry,
    endEntry,
    markAsRead,
    removeMessage,
    hasUnread,
  } = useNotifications(user?.user?._id, messageStatus);

  const department = user?.user?.department || 0;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showInfo, setShowInfo] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [tab, setTab] = useState(0);

  const { employeeData, refetch } = useEmployeeInfo(
    department,
    user?.user?._id
  );

  const handleShowInfo = (type) => {
    setIsLogout(false);
    setShowInfo(type);
    onOpen();
  };

  const handleLogout = () => {
    setIsLogout(true);
    onOpen();
  };

  const onSubmit = async (data) => {
    const toastUpdate = toast.loading("Đang cập nhật ...");
    try {
      const { id } = data;
      let filteredData = Object.fromEntries(
        Object.entries(data).filter(
          ([_, value]) => value !== "" && value !== undefined && value !== null
        )
      );
      if (filteredData?.file) {
        const { filePath } = await commonCall.uploadAvatar(filteredData?.file);
        if (filePath) filteredData.avatar = filePath;
      }
      delete filteredData.id;
      delete filteredData.file;

      if (filteredData?.status === "1") {
        filteredData.endTime = new Date();
      }

      await employeeCall.update(id, { ...filteredData });

      const userData = await employeeCall.get(data?.id);

      store.dispatch(loginUpdateData(userData));

      await refetch();
      toast.success("Cập nhật thành công");
      onClose();
    } catch (error) {
      console.log({ error });
    }
    toast.dismiss(toastUpdate);

    setShowInfo(false);
  };

  const onSubmitChangePassword = async (data) => {
    const toastUpdate = toast.loading("Đang cập nhật ...");
    try {
      await employeeCall.updatePasswordCurrent(data);
      await refetch();
      toast.success("Cập nhật thành công");
      onClose();
    } catch (error) {
      console.log({ error });
    }
    toast.dismiss(toastUpdate);

    setShowInfo(false);
  };

  const onSubmitDelete = async () => {
    const toastUpdate = toast.loading("Đang đăng xuất ...");
    try {
      await employeeCall.logout();
      await refetch();
      store.dispatch(logOutSuccess());
      toast.success("Đăng xuất thành công");
      onClose();
    } catch (error) {
      console.log({ error });
    }
    toast.dismiss(toastUpdate);

    setShowInfo(false);
  };

  return (
    <Flex
      width={"100%"}
      h={{ base: "80px" }}
      justifyContent={"flex-end"}
      borderBottom={"0.5px solid var(--color-boder)"}
    >
      <Box p={"15px 32px"}>
        <Flex
          alignItems={"center"}
          justifyContent={"flex-end"}
          gap={{ base: "32px" }}
          cursor={"pointer"}
        >
          <Box zIndex={999}>
            <Popover>
              <PopoverTrigger>
                <Button
                  w={{ base: "50px" }}
                  h={{ base: "50px" }}
                  borderRadius={{ base: "50%" }}
                >
                  {!hasUnread ? (
                    <Image src={iconHeader} />
                  ) : (
                    <MdNotificationsActive
                      fontSize={"40px"}
                      color="var(--color-secondary2)"
                      style={{
                        animation: "shakePause 7s infinite",
                      }}
                    />
                  )}
                  {/* <Image src={iconHeader} /> */}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>
                  <Text
                    fontSize={"18px"}
                    fontWeight={500}
                    color={"var(--color-black)"}
                  >
                    Thông báo
                  </Text>
                </PopoverHeader>
                <PopoverBody>
                  <HStack spacing={2}>
                    {[
                      { label: "Tất cả", status: null },
                      { label: "Đã đọc", status: "read" },
                    ].map((item, index) => (
                      <Tag
                        size={"md"}
                        key={index}
                        borderRadius="full"
                        variant="solid"
                        background={
                          tab === index
                            ? "var(--color-secondary)"
                            : "var(--color-main)"
                        }
                        onClick={() => {
                          setTab(index);
                          setMessageStatus(item.status);
                        }}
                      >
                        <TagLabel
                          color={
                            tab === index
                              ? "var(--color-main)"
                              : "var(--color-black)"
                          }
                          fontSize={"12px"}
                        >
                          {item.label}
                        </TagLabel>
                      </Tag>
                    ))}
                  </HStack>
                  <Divider my={"8px"} />
                  <Stack direction={"column"} gap={"4px"}>
                    {notifications && notifications.length > 0 ? (
                      notifications.map((item, index) => {
                        return (
                          <Flex
                            key={index}
                            cursor={"pointer"}
                            borderRadius={"4px"}
                            onClick={() => {
                              if (item?.messageId) {
                                markAsRead(item?.messageId);
                              }
                            }}
                            _hover={{
                              bg: "gray.300",
                              transform: "scale(1.02)",
                              boxShadow: "lg",
                            }}
                            transition="all 0.2s ease-in-out"
                            padding={"8px"}
                          >
                            <Flex
                              w={"full"}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <Box maxW={"calc(100% - 32px)"} flexShrink={1}>
                                <Text
                                  fontWeight={500}
                                  fontSize={"12px"}
                                  color={
                                    item?.status === "read" &&
                                    "var(--color-placeHolder-employee)"
                                  }
                                >
                                  {item?.notification?.title} - {item?.createAt}
                                </Text>
                                <Text
                                  fontSize={"14px"}
                                  color={
                                    item?.status === "read" &&
                                    "var(--color-placeHolder-employee)"
                                  }
                                  noOfLines={2}
                                >
                                  {item?.notification?.body}
                                </Text>
                              </Box>
                              <RiDeleteBin6Line
                                cursor={"pointer"}
                                onClick={() => {
                                  if (item?.messageId) {
                                    removeMessage(item?.messageId);
                                  }
                                }}
                              />
                            </Flex>
                          </Flex>
                        );
                      })
                    ) : (
                      <Flex>
                        <Text fontSize={"16px"}>Không có thông báo</Text>
                      </Flex>
                    )}
                  </Stack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>
          <Menu>
            <MenuButton
              _hover={{ backgroundColor: "none" }}
              _active={{ backgroundColor: "none" }}
              // p={"20px"}
            >
              <Flex alignItems={"center"} gap={{ base: "16px" }}>
                <Avatar
                  w={{ base: "50px" }}
                  h={{ base: "50px" }}
                  borderRadius={{ base: "50%" }}
                  src={
                    user?.user?.avatar &&
                    `${process.env.REACT_APP_BACKEND_API}/${user?.user.avatar}`
                  }
                />
                <Flex gap={{ base: "6px" }} flexDirection={{ base: "column" }}>
                  <Text fontSize={{ base: "16px" }} fontWeight={600}>
                    {user?.user.fullName}
                  </Text>
                  <Text fontSize={{ base: "14px" }} fontWeight={500}>
                    {user?.user.jobPosition?.name}
                  </Text>
                </Flex>
              </Flex>
            </MenuButton>
            <MenuList
              fontSize={{ base: "14px" }}
              fontWeight={500}
              lineHeight={"140%"}
              color={"var(--color-info-employee)"}
              p={"10px 0"}
              borderRadius="16px"
            >
              <Box marginBottom={"10px"}>
                <MenuItem
                  gap={"16px"}
                  p={"10px 20px"}
                  onClick={() => handleShowInfo(true)}
                >
                  <Image src={lockIcon} /> Tài khoản
                </MenuItem>
                <MenuItem
                  gap={"16px"}
                  p={"10px 20px"}
                  onClick={() => handleShowInfo(false)}
                >
                  <Image src={userIcon} /> Đổi mật khẩu
                </MenuItem>
                <MenuItem
                  gap={"16px"}
                  p={"10px 20px"}
                  onClick={() => {
                    const url = API_URL + "/logs";
                    window.open(url, "_blank");
                  }}
                >
                  <TbActivityHeartbeat size={20} /> Hoạt động
                </MenuItem>
              </Box>
              <Box borderTop={"1px solid var(--bg-line-employee)"}>
                <MenuItem
                  gap={"16px"}
                  p={"10px 20px"}
                  color={"var(--color-option-employee-hover)"}
                  onClick={handleLogout}
                >
                  <Image src={logoutIcon} /> Đăng xuất
                </MenuItem>
              </Box>
            </MenuList>
          </Menu>
        </Flex>
      </Box>
      {isLogout ? (
        <DeleteDialog
          isOpen={isOpen}
          onClose={onClose}
          action={onSubmitDelete}
          typeLogout={true}
          title="Bạn có chắc bạn muốn đăng xuất?"
        />
      ) : showInfo ? (
        <FormEmployee
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={onSubmit}
          department={department}
          defaultValues={employeeData}
          showInfo={true}
        />
      ) : (
        <PopupChangePasswordCurrent
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={onSubmitChangePassword}
        />
      )}
    </Flex>
  );
};

export default Header;
