import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
  Button,
} from "@chakra-ui/react";
import CloseIconBlack from "../../../assets/images/booking/x-circle.svg";
import {
  InputControl,
  InputDatePicker,
  SelectControl,
} from "../../../components/form/formControls";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { formatPrice, isEndDateGreater } from "../../../utils/utils";
import { useCategories } from "../../../hooks/useCategories";

const schema = Yup.object().shape({
  name: Yup.string().required("Tên căn hộ là bắt buộc"),
  projectId: Yup.string().required("Dự án là bắt buộc"),
  price: Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      if (typeof originalValue === "string") {
        return originalValue.trim() === "" ? null : Number(originalValue);
      }
      return originalValue === null ? null : Number(originalValue);
    })
    .required("Số tiền booking là bắt buộc"),
  startTime: Yup.string().required("Vui lòng nhập ngày bắt đầu"),
  endTime: Yup.string()
    .required("Vui lòng nhập ngày kết thúc")
    .test(
      "is-end-greater",
      "Ngày kết thúc phải lớn hơn ngày bắt đầu",
      function (value) {
        const { startTime } = this.parent;
        return isEndDateGreater(startTime, value);
      }
    ),
});

const AddBooking = ({ isOpen, onClose, defaultValues, isEdit, onSubmit }) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: defaultValues || {},
  });

  const { constantData } = useCategories();

  const formatEntries = (entries) =>
    Object.entries(entries || {}).map(([key, value]) => ({
      value: key,
      label: value,
    }));

  const projects = useMemo(
    () => formatEntries(constantData?.project),
    [constantData?.project]
  );

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
      if (defaultValues)
        setDisplayValue(formatPrice(defaultValues?.price || 0));
    }
  }, [isOpen, defaultValues, reset]);

  const [zIndex, setZIndex] = useState("");
  const [focusStates, setFocusStates] = useState({});
  const [inputColors, setInputColors] = useState({
    startTime: "var(--text-gray-form)",
    endTime: "var(--text-gray-form)",
  });

  const handleFocus = (field) => {
    setZIndex(field);
    setFocusStates((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field) => {
    setZIndex("");
    setFocusStates((prev) => ({ ...prev, [field]: false }));
  };

  const onchangeProject = (option) => {
    setValue("projectId", option);
  };

  const handleDateChange = (selectedDate, inputName) => {
    setValue(inputName, selectedDate, { shouldValidate: true });

    // setInputColors((prevColors) => ({
    //   ...prevColors,
    //   [inputName]: selectedDate
    //     ? "var(--color-info-employee)"
    //     : "var(--color-text-unclick)",
    // }));
  };

  const submitHandler = (data) => {
    onSubmit(data);
  };

  // Price
  const [displayValue, setDisplayValue] = useState(0);
  const onChangePrice = useCallback(
    (event) => {
      const { value } = event.target;
      const numericValue = value.replace(/[^0-9]/g, "");
      setDisplayValue(formatPrice(numericValue));
      setValue("price", Number(numericValue), { shouldValidate: true });
    },
    [setValue, watch, formatPrice]
  );
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="var(--color-main)"
        borderRadius={"16px"}
        h={"625px"}
        w={"100%"}
        maxW={"625px"}
        overflowY={"auto"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
          "&::-moz-scrollbar": {
            width: "4px",
          },
          "&::-moz-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
        }}
      >
        <form onSubmit={handleSubmit(submitHandler)}>
          <Flex minH={"625px"} flexDirection={"column"}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"10px"}
              padding={"25px 24px"}
              borderBottom={"1px solid var(--bg-line-employee)"}
            >
              <Text
                color="var(--color-header-employee)"
                fontSize={"18px"}
                fontWeight={"600"}
              >
                {isEdit
                  ? "Chỉnh sửa đợt nhận booking"
                  : "Tạo đợt nhận booking mới"}
              </Text>
              <Box cursor={"pointer"} onClick={onClose}>
                <Image src={CloseIconBlack} />
              </Box>
            </Flex>
            <Box flex={1} w={"545px"} m={"24px auto"}>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
                mb={"16px"}
              >
                <InputControl
                  label="Tên đợt nhận booking"
                  placeholder="Nhập tên đợt nhận booking"
                  errors={errors.name}
                  register={register("name")}
                  onFocus={() => handleFocus("name")}
                  onBlur={() => handleBlur("name")}
                  state={focusStates["name"]}
                  mwidth={"100%"}
                />
              </Flex>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
                mb={"16px"}
              >
                <SelectControl
                  label="Dự án"
                  dataOption={projects}
                  placeholder="Chọn dự án"
                  selectedMain={watch("projectId")}
                  onFocus={() => handleFocus("projectId")}
                  onBlur={() => handleBlur("projectId")}
                  state={focusStates["projectId"]}
                  onChange={onchangeProject}
                  register={register("projectId")}
                  errorMessage={errors.projectId}
                />
                <InputControl
                  // type="number"
                  label="Số tiền booking"
                  placeholder="Ví dụ: 195000000000 "
                  errors={errors.price}
                  onFocus={() => handleFocus("price")}
                  onBlur={() => handleBlur("price")}
                  onChange={onChangePrice}
                  value={displayValue}
                  state={focusStates["price"]}
                  register={register("price")}
                  mwidth={"100%"}
                />
              </Flex>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
                mb={"16px"}
              >
                {/* start time */}
                <InputDatePicker
                  label={"Từ ngày"}
                  focusStates={focusStates["startTime"]}
                  selected={watch("startTime")}
                  onFocus={() => handleFocus("startTime")}
                  onBlur={() => handleBlur("startTime")}
                  onChange={(date) => handleDateChange(date, "startTime")}
                  dateFormat="dd/MM/yyyy"
                  locale="vi"
                  placeholderText="dd/mm/yy"
                  errors={errors?.startTime}
                  formControlStyle={{
                    maxW: "100%",
                  }}
                  formLabelStyle={{
                    fontWeight: 400,
                    lineHeight: "15px",
                    fontSize: "12px",
                    mb: "6px",
                  }}
                  zIndex={zIndex === "startTime" ? 3 : 0}
                />
                {/* end time */}
                <InputDatePicker
                  label={"đến ngày"}
                  focusStates={focusStates["endTime"]}
                  selected={watch("endTime")}
                  onFocus={() => handleFocus("endTime")}
                  onBlur={() => handleBlur("endTime")}
                  onChange={(date) => handleDateChange(date, "endTime")}
                  dateFormat="dd/MM/yyyy"
                  locale="vi"
                  placeholderText="dd/mm/yy"
                  errors={errors?.endTime}
                  formControlStyle={{
                    maxW: "100%",
                  }}
                  formLabelStyle={{
                    fontWeight: 400,
                    lineHeight: "15px",
                    fontSize: "12px",
                    mb: "6px",
                  }}
                  zIndex={zIndex === "endTime" ? 3 : 0}
                />
              </Flex>
            </Box>
            <Flex p={"24px"} justifyContent={"flex-end"}>
              {isEdit ? (
                <Flex gap={"16px"}>
                  <Button
                    p={"13px 16px 13px 20px"}
                    fontWeight={600}
                    fontSize={"15px"}
                    lineHeight={"18px"}
                    color={"var(--color-option-employee-hover)"}
                    border={"1px solid var(--color-option-employee-hover)"}
                    bg={"var(--color-main)"}
                    onClick={onClose}
                    _hover={{
                      filter: "brightness(90%)",
                    }}
                  >
                    Hủy
                  </Button>
                  <Button
                    type="submit"
                    p={"13px 16px 13px 20px"}
                    fontWeight={600}
                    fontSize={"15px"}
                    lineHeight={"18px"}
                    color={"var(--color-main)"}
                    bg={"var(--color-button)"}
                    _hover={{
                      filter: "brightness(90%)",
                    }}
                  >
                    Lưu
                  </Button>
                </Flex>
              ) : (
                <Button
                  type="submit"
                  p={"13px 16px 13px 20px"}
                  fontWeight={600}
                  fontSize={"15px"}
                  lineHeight={"18px"}
                  color={"var(--color-main)"}
                  bg={"var(--color-button)"}
                  _hover={{
                    filter: "brightness(90%)",
                  }}
                >
                  {defaultValues ? "Sửa đợt Booking" : "Thêm đợt nhận booking"}
                </Button>
              )}
            </Flex>
          </Flex>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default AddBooking;
