import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { clientAPI } from "../api/client";
import { NumberOfPerPage } from "../utils/globalVariable";
import { formatDateDb } from "../utils/tools";
import {
  formatNumber,
} from "../utils/utils";

const NUMBER_NFT_PER_PAGE = NumberOfPerPage.bookingEvent;
const queryKey = "bookingEvent";

async function fetchCommissionConfirmation(
  currentPage,
  search,
  projectId,
  employeeId
) {
  try {
    const options = {
      limit: NUMBER_NFT_PER_PAGE,
      page: currentPage,
      status: 'APPROVED',
    };

    if (search !== undefined) options.search = search;
    if (projectId) options.projectId = projectId;
    if (employeeId) options.approverConfirmId = employeeId;

    const queryParams = new URLSearchParams(options).toString();
    let data = await clientAPI("get", `/locking?${queryParams}`);
    const totalPages = Math.ceil(data?.pagination?.total / NUMBER_NFT_PER_PAGE);
    const entries = data?.pagination?.total;
    const startEntry =
      (data?.pagination?.page - 1) * data?.pagination?.limit + 1;
    const endEntry = Math.min(
      data?.pagination?.page * data?.pagination?.limit,
      entries
    );

    const newData = data?.items.map((item, index) => { 
      return {
        id: item?._id || "",
        code: item?.code || "",
        confirmCommissionStatus: item?.confirmCommissionStatus || "-",
        saleOpeningEvent: item?.saleOpeningEvent?.name || "-",
        project: item?.project?.name || "-",
        priceForCommission: item?.apartment?.priceForCommission ? `${formatNumber(item?.apartment?.priceForCommission)} VNĐ` : "",
        serviceFeeType: item?.serviceFeeType ? "PDV Giới thiệu Dự án" : "-",
        approver: item?.approverConfirm?.fullName || "-",
        lockingParticipant: item?.lockingParticipant || "-",
        bookingTime: item?.bookingTime ? formatDateDb(item?.bookingTime) : "-",
        detail: {
          id: item?._id || "",
          code: item?.code || "",
          confirmCommissionStatus: item?.confirmCommissionStatus || "",
          project: item?.project?.name || "",
          apartment: item?.apartment?.name || "",
          serviceFeeType: item?.serviceFeeType ? "PDV Giới thiệu Dự án" : "-",
          kda: item?.project?.kRate || "",
          priceForCommission: item?.apartment?.priceForCommission ? `${formatNumber(item?.apartment?.priceForCommission)} VNĐ` : "",
          booker: item?.booker?.fullName || "",
          bookingTime: item?.bookingTime ? formatDateDb(item?.bookingTime) : "",
          createdAt: item?.createdAt ? formatDateDb(item?.createdAt) : "",
          lockingId: item?._id || "-",
          approver: item?.approverConfirm?.fullName || "",
          approverConfirmDate: item?.approverConfirmDate ? formatDateDb(item?.approverConfirmDate) : "",
          lockingParticipant: item?.lockingParticipant || "",
        },
        edit: {
          id: item?._id || "",
          code: item?.code || "",
          confirmCommissionStatus: item?.confirmCommissionStatus || "",
          project: item?.project?.name || "",
          apartment: item?.apartment?.name || "",
          serviceFeeId: item?.serviceFeeId || "",
          kRate: item?.kRate || "",
          priceForCommission: item?.priceForCommission || "",
          booker: item?.booker?.fullName || "",
          bookingTime: item?.bookingTime || "",
          lockingId: item?._id || "-",
          approver: item?.approverConfirm?.fullName || "",
          createdAt: item?.createdAt ? formatDateDb(item?.createdAt) : "",
          approverConfirmDate: item?.approverConfirmDate ? formatDateDb(item?.approverConfirmDate) : "",
          lockingParticipant: item?.lockingParticipant || "",
        },
      };
    });

    return { data: newData, totalPages, entries, startEntry, endEntry };
  } catch (error) {
    console.error("Error fetching checkIn data:", error);
    return { data: [], totalPages: 0 };
  }
}

export function useCommissionConfirmation(search, projectId, employeeId) {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: [queryKey, currentPage, search, projectId, employeeId],
    queryFn: () =>
      fetchCommissionConfirmation(currentPage, search, projectId, employeeId),
    refetchOnWindowFocus: false,
    staleTime: 30000,
    keepPreviousData: true,
    onError: (error) => {
      console.error("Error fetching checkIn data:", error);
    },
  });

  const nextPage = () => {
    if (currentPage < data?.totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return {
    commissionConfirmationData: data?.data,
    totalPages: data?.totalPages,
    refetch,
    isLoading,
    isRefetching,
    prevPage,
    nextPage,
    setCurrentPage,
    currentPage,
    entries: data?.entries,
    startEntry: data?.startEntry,
    endEntry: data?.endEntry,
  };
}
