import toast from "react-hot-toast";

const { clientAPI } = require("../client");
export const lockingParticipantCall = {
  create: async (data) => { 
    try {
      const response = await clientAPI("post", "/lockingParticipant", data);
      return response;
    } catch (error) {
      console.error("Create error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  createAndUpdateMany: async (data) => { 
    try {
      const response = await clientAPI("post", "/lockingParticipant/bulk", data);
      return response;
    } catch (error) {
      console.error("Create error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  update: async (id,data) => {
    try {
      const response = await clientAPI("put", `/lockingParticipant/${id}`, data);
      return response;
    } catch (error) {
      console.error("Update error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  delete: async (id) => {
    try {
      const response = await clientAPI("delete", `/lockingParticipant/${id}`);
      return response;
    } catch (error) {
      console.error("Delete error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await clientAPI(
        "get",
        `/lockingParticipant?page=1&limit=9999`
      );
      return response;
    } catch (error) {
      console.error("update error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  getByLockingId: async (id) => {
    try {
      const response = await clientAPI(
        "get",
        `/lockingParticipant?lockingId=${id}&page=1&limit=9999`
      );

      let mappedData = null;
      if(response?.items){
        mappedData = response?.items.map((item) => ({
          id: item?._id || "",
          employeeId: item?.employeeId || "",
          commissionRate: item?.commissionRate || "",
          employeeJobTitle: item?.employeeJobTitle || "",
        }));
      }

      return mappedData;
    } catch (error) {
      console.error("update error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  getInfoLockingId: async (id) => {
    try {
      const response = await clientAPI(
        "get",
        `/lockingParticipant?lockingId=${id}&page=1&limit=9999`
      );

      let mappedData = null;
      if(response?.items){
        mappedData = response?.items.map((item) => ({
          employee: item?.employee?.fullName || "",
          employeeJobTitle: item?.employeeJobTitle || "",
          commissionRate: item?.commissionRate || "",
        }));
      }

      return mappedData;
    } catch (error) {
      console.error("update error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
};