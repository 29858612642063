import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useMemo } from "react";
import {
  setMessage,
  markMessageAsRead,
  deleteMessage,
  clearAllMessages,
} from "../redux/slices/fcmSlice";
import moment from "moment-timezone";

const itemsPerPage = 10;

export function useNotifications(employeeId = null, readStatus = null) {
  const dispatch = useDispatch();
  const { messages = [] } = useSelector((state) => state.fcm);

  const [currentPage, setCurrentPage] = useState(1);

  const filteredMessages = useMemo(() => {
    if (!messages.length) return [];
    return messages
      .filter((message) => {
        if (
          employeeId !== null &&
          employeeId !== undefined &&
          message.employeeId !== employeeId
        ) {
          return false;
        }

        if (
          readStatus !== null &&
          readStatus !== undefined &&
          message.status !== readStatus
        ) {
          return false;
        }

        return true;
      })
      .sort((a, b) => {
        const timeA = moment(a.createAt, "HH:mm:ss DD/MM/YYYY").valueOf();
        const timeB = moment(b.createAt, "HH:mm:ss DD/MM/YYYY").valueOf();
        return timeB - timeA;
      });
  }, [messages, employeeId, readStatus]);

  const paginatedMessages = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredMessages.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredMessages, currentPage]);

  const totalPages = useMemo(
    () => Math.ceil(filteredMessages.length / itemsPerPage),
    [filteredMessages.length]
  );

  const hasUnread = useMemo(
    () => filteredMessages.some((msg) => msg.status === "unread"),
    [filteredMessages]
  );

  useEffect(() => {
    if (messages.length === 0) {
      setCurrentPage(1);
    }
  }, [messages]);

  const addMessage = (message) => {
    dispatch(setMessage(message));
  };

  const markAsRead = (messageId) => {
    dispatch(markMessageAsRead({ messageId }));
  };

  const removeMessage = (messageId) => {
    dispatch(deleteMessage({ messageId }));
  };

  const clearMessages = () => {
    dispatch(clearAllMessages());
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const startEntry = (currentPage - 1) * itemsPerPage + 1;
  const endEntry = Math.min(
    currentPage * itemsPerPage,
    filteredMessages.length
  );

  return {
    notifications: paginatedMessages,
    totalPages,
    currentPage,
    entries: filteredMessages.length,
    startEntry,
    endEntry,
    addMessage,
    markAsRead,
    removeMessage,
    clearMessages,
    nextPage,
    prevPage,
    setCurrentPage,
    hasUnread,
  };
}
