import { useQuery } from "@tanstack/react-query";
import { clientAPI } from "../api/client";

async function fetchConstantData(saleAreaId, projectId) {
  const searchOptions = {
    limit: 100,
    page: 1,
  };

  // if (projectId){
  //   searchOptions.status = 'OPEN';
  // }
  const searchQueryParams = new URLSearchParams(searchOptions).toString();
  let searchQueryParamsProject = new URLSearchParams(searchOptions).toString();
  let searchSaleBlockQueryParams = searchQueryParams;
  if (saleAreaId)
    searchSaleBlockQueryParams =
      searchQueryParams + "&" + new URLSearchParams({ saleAreaId }).toString();
  if (projectId)
    searchQueryParamsProject =
      searchQueryParams + "&" + new URLSearchParams({ projectId }).toString() ;

  // Fetch constant data concurrently
  const [
    jobPositionBussinesData,
    jobPositionSupportBussinesData,
    jobPositionAdminsData,
    jobTitleData,
    departmentData,
    saleBlockData,
    saleAreaData,
    branchData,
    supportDepartmentData,
    referralCodeData,
    businessCategoryData,
    guaranteeTypeData,
    investor,
    city,
    projectType,
    employee,
    project,
    bookingEvent,
    apartment,
    customer,
    apartmentType,
    division,
    property,
    direction,
    serviceFee,
  ] = await Promise.all([
    clientAPI("get", "/jobPosition/allowType?type=1"),
    clientAPI("get", "/jobPosition/allowType?type=0"),
    clientAPI("get", "/jobPosition/allowType?type=2"),
    clientAPI("get", `/jobTitle?${searchQueryParams}`),
    clientAPI("get", `/department?${searchQueryParams}`),
    clientAPI("get", `/saleBlock?${searchSaleBlockQueryParams}`),
    clientAPI("get", `/saleArea?${searchQueryParams}`),
    clientAPI("get", `/branch?${searchQueryParams}`),
    clientAPI("get", `/supportDepartment?${searchQueryParams}`),
    clientAPI("get", `/referralCode?${searchQueryParams}`),
    clientAPI("get", `/businessCategory?${searchQueryParams}`),
    clientAPI("get", `/guaranteeType?${searchQueryParams}`),
    clientAPI("get", `/investor?${searchQueryParams}`),
    clientAPI("get", `/city?${searchQueryParams}`),
    clientAPI("get", `/projectType?${searchQueryParams}`),
    clientAPI("get", `/employee?${searchQueryParams}`),
    clientAPI("get", `/project?${searchQueryParams}`),
    clientAPI("get", `/bookingEvent?${searchQueryParams}`),
    clientAPI("get", `/apartment?${searchQueryParams}`),
    clientAPI("get", `/customer?${searchQueryParams}`),
    clientAPI("get", `/apartmentType?${searchQueryParams}`),
    clientAPI("get", `/division?${searchQueryParamsProject}`),
    clientAPI("get", `/property?${searchQueryParams}`),
    clientAPI("get", `/direction?${searchQueryParams}`),
    clientAPI("get", `/serviceFee?${searchQueryParams}`),
  ]);

  // Index data by 'id' to improve lookup performance
  return {
    jobPositionBussines: jobPositionBussinesData?.items?.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.name }),
      {}
    ),
    jobPositionSupportBussines: jobPositionSupportBussinesData?.items?.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.name }),
      {}
    ),
    jobPositionAdminsData: jobPositionAdminsData?.items?.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.name }),
      {}
    ),
    jobTitles: jobTitleData?.items?.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.name }),
      {}
    ),
    departments: departmentData?.items?.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.name }),
      {}
    ),
    departmentByNumbers: departmentData?.items?.reduce(
      (acc, item) => ({ ...acc, [item.id]: item.name }),
      {}
    ),
    saleBlocks: saleBlockData?.items?.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.name }),
      {}
    ),
    saleAreas: saleAreaData?.items?.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.name }),
      {}
    ),
    branches: branchData?.items?.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.name }),
      {}
    ),
    supportDepartments: supportDepartmentData?.items?.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.name }),
      {}
    ),
    referralCodes: referralCodeData?.items?.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.name }),
      {}
    ),
    businessCategorys: businessCategoryData?.items?.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.name }),
      {}
    ),
    guaranteeTypes: guaranteeTypeData?.items?.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.name }),
      {}
    ),
    investor: investor?.items?.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.name }),
      {}
    ),
    city: city?.items?.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.name }),
      {}
    ),
    projectType: projectType?.items?.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.name }),
      {}
    ),
    employee: employee?.items?.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.fullName }),
      {}
    ),
    project: project?.items?.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.name }),
      {}
    ),
    bookingEvent: bookingEvent?.items?.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.name }),
      {}
    ),
    apartment: apartment?.items?.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.name }),
      {}
    ),
    customer: customer?.items?.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.name }),
      {}
    ),
    apartmentType: apartmentType?.items?.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.name }),
      {}
    ),
    division: division?.items?.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.name }),
      {}
    ),
    property: property?.items?.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.name }),
      {}
    ),
    direction: direction?.items?.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.name }),
      {}
    ),
    serviceFee: serviceFee?.items?.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.name }),
      {}
    ),
  };
}

export function useCategories(saleAreaId, projectId) {
  // Fetch constant data once
  const {
    data: constantData,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["categories", saleAreaId, projectId],
    queryFn: () => fetchConstantData(saleAreaId, projectId),
    refetchOnWindowFocus: false,
  });

  return {
    constantData,
    isLoading,
    isFetching,
    refetch,
  };
}
