import {
  Box,
  Flex,
  Text,
  Modal,
  ModalContent,
  ModalOverlay,
  Image,
  Button,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Table,
  Thead,
} from "@chakra-ui/react";
import React, { memo, useEffect, useState } from "react";
import IconX from "../../../assets/images/booking/x-circle.svg";
import IconCheck from "../../../assets/images/booking/check-circle.svg";
import IconRed from "../../../assets/images/booking/x-red.svg";
import { formatTableValue } from "../formatTable";
import toast from "react-hot-toast";
import { lockingCall } from "../../../api/locking";
import { lockingHistoryCall } from "../../../api/lockingHistory";
import { formatDateDb } from "../../../utils/tools";
import { lockingParticipantCall } from "../../../api/lockingParticipant";

const baseURL = process.env.REACT_APP_BACKEND_API || "http://localhost:3001";

const FormInfo = memo(({ isOpen, onClose, defaultValues, refetch }) => {
  const [infident1, setInfident1] = useState(null);
  const [infident2, setInfident2] = useState(null);
  const [imagePayment, setImagePayment] = useState(null);
  const [historyBooking, setHistoryBooking] = useState(null);
  const [employeeProject, setEmployeeProject] = useState([]);

  useEffect(() => {
    // lấy ra nhân viên quản lý
    if (defaultValues?.id) {
      const fetchData = async () => {
        try {
          const dataManagerEmployee =
            await lockingParticipantCall.getInfoLockingId(defaultValues?.id);

          setEmployeeProject(dataManagerEmployee);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
    if (defaultValues?.id) {
      if (
        defaultValues?.status === "APPROVED" ||
        defaultValues?.status === "REJECTED"
      ) {
        const fetchDataBookingHistory = async () => {
          try {
            const data = await lockingHistoryCall.getHistoryLocking(
              defaultValues?.id
            );

            setHistoryBooking(null);
            if (data?.items) {
              setHistoryBooking(data?.items[0]);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        fetchDataBookingHistory();
      }
      const fetchData = async () => {
        try {
          if (defaultValues?.images) {
            if (Array.isArray(defaultValues?.images)) {
              for (const item of defaultValues?.images) {
                if (item?.type === "IDENTIFICATION_BEFORE") {
                  setInfident1(item?.path);
                }
                if (item?.type === "IDENTIFICATION_AFTER") {
                  setInfident2(item?.path);
                }
                if (item?.type === "TRANSACTION") {
                  setImagePayment(item?.path);
                }
              }
            }
          } else {
            setInfident1(null);
            setInfident2(null);
            setImagePayment(null);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [isOpen]);

  const submitStatus = async (status) => {
    const toastEdit = toast.loading("Đang cập nhập trạng thái ...");
    const dataFormat = {
      status: status,
    };

    try {
      await lockingCall.approve(defaultValues?.id, dataFormat);
      refetch();
      onClose();
      toast.success("Cập nhập trạng thái thành công");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Có lỗi xảy ra khi cập nhập trạng thái");
    }
    toast.dismiss(toastEdit);
  };

  const dataTable = {
    headers: [
      {
        label: "Tên nhân viên",
        key: "employee",
      },
      {
        label: "Chức danh",
        key: "employeeJobTitle",
      },
      {
        label: "Hệ số thưởng",
        key: "commissionRate",
      },
    ],
    data: employeeProject,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="var(--color-main)"
        borderRadius={"16px"}
        position={"relative"}
        w={"100%"}
        maxW={"625px"}
      >
        <Box
          paddingBottom={"80px"}
          h={"86.5vh"}
          w={"100%"}
          overflowY={"scroll"}
          sx={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              width: "4px",
              borderRadius: "50px",
              background: "var(--color-background)",
            },
            "&::-moz-scrollbar": {
              width: "4px",
            },
            "&::-moz-scrollbar-thumb": {
              width: "4px",
              borderRadius: "50px",
              background: "var(--color-background)",
            },
          }}
        >
          <Flex
            alignItems={"center"}
            p={"25px 24px 24px 24px"}
            position={"relative"}
            borderBottom={
              defaultValues?.status === "APPROVED" ||
              defaultValues?.status === "REJECTED"
                ? ""
                : "1px solid var(--border-input-employee)"
            }
          >
            <Text
              color={"var(--color-header-employee)"}
              fontSize={"16px"}
              fontWeight={"600"}
            >
              {defaultValues?.note || "Chi tiết đợt locking"}
            </Text>
            <Box
              position={"absolute"}
              top={"24px"}
              right={"24px"}
              cursor={"pointer"}
              onClick={onClose}
            >
              <Image src={IconX} />
            </Box>
          </Flex>
          {(defaultValues?.status === "APPROVED" ||
            defaultValues?.status === "REJECTED") && (
            <Flex
              p={"16px 24px"}
              justifyContent={"space-between"}
              bg={
                defaultValues?.status === "APPROVED"
                  ? "var(--bg-green-1)"
                  : "var(--bg-red-1)"
              }
            >
              {defaultValues?.status === "APPROVED" && (
                <Flex
                  p={"12px"}
                  justifyContent={"space-between"}
                  gap={"8px"}
                  bg={"var(--bg-status-success)"}
                  borderRadius={"8px"}
                  w={"max-content"}
                  minW={"151px"}
                  h={"max-content"}
                >
                  <Image
                    src={IconCheck}
                    w={"20px"}
                    h={"20px"}
                    objectFit={"cover"}
                  />
                  <Text
                    fontSize={"14px"}
                    lineHeight={"20px"}
                    fontWeight={500}
                    color={"var(--color-status-employ)"}
                    display={"block"}
                  >
                    Đã được duyệt
                  </Text>
                </Flex>
              )}
              {defaultValues?.status === "REJECTED" && (
                <Flex
                  p={"12px"}
                  justifyContent={"space-between"}
                  gap={"8px"}
                  bg={"var(--bg-status-error)"}
                  borderRadius={"8px"}
                  w={"max-content"}
                  minW={"176px"}
                  h={"max-content"}
                >
                  <Image
                    src={IconRed}
                    w={"20px"}
                    h={"20px"}
                    objectFit={"cover"}
                  />
                  <Text
                    fontSize={"14px"}
                    lineHeight={"20px"}
                    fontWeight={500}
                    color={"var(--text-red-employee)"}
                    display={"block"}
                  >
                    Không được duyệt
                  </Text>
                </Flex>
              )}

              <Flex gap={"40px"}>
                <Box textAlign={"end"}>
                  <Text color={"var(--color-label-employee)"} fontSize={"12px"}>
                    Người phê duyệt
                  </Text>
                  <Text
                    color={"var(--color-option-employee)"}
                    fontSize={"14px"}
                    fontWeight={"500"}
                    mt={"8px"}
                  >
                    {historyBooking?.approver?.fullName}
                  </Text>
                </Box>
                <Box textAlign={"end"}>
                  <Text color={"var(--color-label-employee)"} fontSize={"12px"}>
                    Thời gian duyệt
                  </Text>
                  <Text
                    color={"var(--color-option-employee)"}
                    fontSize={"14px"}
                    fontWeight={"500"}
                    mt={"8px"}
                  >
                    {historyBooking?.createdAt
                      ? formatDateDb(historyBooking?.createdAt)
                      : ""}
                  </Text>
                </Box>
              </Flex>
            </Flex>
          )}
          <Box margin={"0 auto"} p={"24px 40px"}>
            <Box>
              <Box flex={1}>
                <Box>
                  <Text
                    color={"var(--color-header-employee)"}
                    fontSize={"16px"}
                    fontWeight={"600"}
                  >
                    Thông tin chung
                  </Text>
                  <Box
                    background={"var(--fill-avatar)"}
                    p={"16px 24px 16px 16px"}
                    mt={"13px"}
                    borderRadius={"8px"}
                  >
                    <Flex gap={"27px"}>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Người tạo locking
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          {defaultValues?.nameBooker || ""}
                        </Text>
                      </Box>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Ngày tạo locking
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          {defaultValues?.createdAt || ""}
                        </Text>
                      </Box>
                    </Flex>
                    <Flex mt={"16px"} gap={"27px"}>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Dự án
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          {defaultValues?.project || ""}
                        </Text>
                      </Box>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Mã sản phẩm
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          {defaultValues?.apartment || ""}
                        </Text>
                      </Box>
                    </Flex>
                    <Flex mt={"16px"} gap={"27px"}>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Số tiền đặt cọc
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          {defaultValues?.price || ""} đ
                        </Text>
                      </Box>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Thời gian đặt cọc
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          {defaultValues?.bookingTime
                            ? `${defaultValues?.bookingTime} đ`
                            : ""}
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
                <Box mt={"20px"}>
                  <Text
                    color={"var(--color-header-employee)"}
                    fontSize={"16px"}
                    fontWeight={"600"}
                  >
                    Chi tiết sản phẩm
                  </Text>
                  <Box
                    background={"var(--fill-avatar)"}
                    p={"16px 24px 16px 16px"}
                    mt={"16px"}
                    borderRadius={"8px"}
                  >
                    <Flex gap={"27px"}>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Tên sản phẩm
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          {defaultValues?.apartments?.name || ""}
                        </Text>
                      </Box>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Loại hình
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          {defaultValues?.apartments?.apartmentType?.name || ""}
                        </Text>
                      </Box>
                    </Flex>
                    <Flex mt={"16px"} gap={"27px"}>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Hướng
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          {defaultValues?.apartments?.direction?.name || ""}
                        </Text>
                      </Box>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Tính chất
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          {defaultValues?.apartments?.property?.name || ""}
                        </Text>
                      </Box>
                    </Flex>
                    <Flex mt={"16px"} gap={"27px"}>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          DT đất
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          {defaultValues?.apartments?.area || ""}m2
                        </Text>
                      </Box>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          DT xây dựng
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          {defaultValues?.apartments?.builtUpArea || ""}m2
                        </Text>
                      </Box>
                    </Flex>
                    <Flex mt={"16px"} gap={"27px"}>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Phòng ngủ
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          {defaultValues?.apartments?.numberOfBedrooms || ""}
                        </Text>
                      </Box>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Phòng VS
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          {defaultValues?.apartments?.numberOfToilets || ""}
                        </Text>
                      </Box>
                    </Flex>
                    <Flex mt={"16px"} gap={"27px"}>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Phân khu
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          {defaultValues?.apartments?.division?.name || ""}
                        </Text>
                      </Box>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Giá trị
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          {defaultValues?.apartments?.totalValue || ""} đ
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              </Box>
              <Box flex={1} mt={"24px"}>
                <Box mt={"16px"}>
                  <Flex gap={"27px"}>
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Ảnh CCCD mặt trước
                      </Text>
                      <Box
                        mt={"8px"}
                        border={"0.5px solid var(--bg-line-employee)"}
                        w={"259px"}
                        height={"146px"}
                        borderRadius={"8px"}
                      >
                        {infident1 && (
                          <Image
                            borderRadius={"11px"}
                            w={"100%"}
                            h={"100%"}
                            objectFit={"cover"}
                            src={`${baseURL}/${infident1}`}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Ảnh CCCD mặt trước
                      </Text>
                      <Box
                        mt={"8px"}
                        border={"0.5px solid var(--bg-line-employee)"}
                        w={"259px"}
                        height={"146px"}
                        borderRadius={"8px"}
                      >
                        {infident2 && (
                          <Image
                            borderRadius={"11px"}
                            w={"100%"}
                            h={"100%"}
                            objectFit={"cover"}
                            src={`${baseURL}/${infident2}`}
                          />
                        )}
                      </Box>
                    </Box>
                  </Flex>
                </Box>
                <Box mt={"16px"}>
                  <Flex>
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Ảnh ủy nhiệm chi
                      </Text>
                      <Flex
                        mt={"8px"}
                        border={"0.5px solid var(--bg-line-employee)"}
                        borderRadius={"8px"}
                        justifyContent={"center"}
                      >
                        <Box borderRadius={"11px"} w={"202px"} h={"300px"}>
                          {imagePayment && (
                            <Image
                              borderRadius={"11px"}
                              w={"100%"}
                              h={"100%"}
                              objectFit={"cover"}
                              src={`${baseURL}/${imagePayment}`}
                            />
                          )}
                        </Box>
                      </Flex>
                    </Box>
                  </Flex>
                </Box>
              </Box>
              <Box mt={"24px"}>
                <Text
                  color={"var(--color-header-employee)"}
                  fontSize={"16px"}
                  fontWeight={"600"}
                >
                  Nhân viên tham gia
                </Text>
                <Box
                  background={"var(--fill-avatar)"}
                  mt={"16px"}
                  borderRadius={"8px"}
                >
                  <TableContainer w={"100%"}>
                    <Table w={"100%"} variant="unstyled">
                      <Thead
                        w={"100%"}
                        h={"41px"}
                        color="white"
                        fontWeight="400"
                        backgroundColor="#F9FAFD"
                        borderBottom={"1px solid var(--bg-line-employee)"}
                      >
                        <Tr h={{ base: "41px" }}>
                          {dataTable.headers.map((e, index) => {
                            let width = "auto";
                            let textAlign = "auto";
                            if (e.key === "employee") {
                              width = "2%";
                              textAlign = "start";
                            } else if (e.key === "employeeJobTitle") {
                              width = "5%";
                              textAlign = "start";
                            } else {
                              width = "10%";
                              textAlign = "end";
                            }
                            return (
                              <Td
                                p={"16px 16px 8px 16px"}
                                key={index}
                                border={"none"}
                                color={"#51607B"}
                                fontSize={{ base: "14px" }}
                                w={width}
                              >
                                <Box textAlign={textAlign}>{e.label}</Box>
                              </Td>
                            );
                          })}
                        </Tr>
                      </Thead>
                      <Tbody w={"100%"} h={"100%"}>
                        {dataTable.data?.length > 0 ? (
                          dataTable.data?.map((e, rowIndex) => {
                            const keyValues = Object.keys(e);
                            return (
                              <Tr
                                key={rowIndex}
                                h={"44px"}
                                backgroundColor={
                                  rowIndex % 2 === 0
                                    ? "var(--color-main)"
                                    : "var(--fill-avatar)"
                                }
                              >
                                {keyValues.map((keyvalue, index) => {
                                  let width = "auto";
                                  let widthText = "100%";
                                  let textAlign = "auto";
                                  let whiteSpace = "inherit";
                                  if (keyvalue === "employee") {
                                    width = "40%";
                                    textAlign = "start";
                                  } else if (keyvalue === "employeeJobTitle") {
                                    width = "40%";
                                    textAlign = "start";
                                  } else {
                                    width = "20%";
                                    textAlign = "center";
                                    whiteSpace = "inherit";
                                  }
                                  return (
                                    <Td
                                      p={"16px 16px 8px 16px"}
                                      w={width}
                                      key={index}
                                    >
                                      <Box
                                        fontSize={{ base: "14px" }}
                                        lineHeight={"19.6px"}
                                        textAlign={textAlign}
                                        fontWeight={500}
                                        color={"var(--color-info-employee)"}
                                        w={widthText}
                                        whiteSpace={whiteSpace}
                                        overflow="hidden"
                                        textOverflow="ellipsis"
                                        display="-webkit-box"
                                        sx={{
                                          WebkitLineClamp: "2",
                                          WebkitBoxOrient: "vertical",
                                        }}
                                      >
                                        {formatTableValue(
                                          e[keyvalue],
                                          keyvalue
                                        )}
                                      </Box>
                                    </Td>
                                  );
                                })}
                              </Tr>
                            );
                          })
                        ) : (
                          <Tr>
                            <Td
                              colSpan={dataTable.headers.length}
                              textAlign="center"
                              padding={"40px 0"}
                            >
                              Không có dữ liệu
                            </Td>
                          </Tr>
                        )}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            position={"absolute"}
            w={"100%"}
            bottom={"0"}
            bg={"var(--color-main)"}
            borderTop={"0.5px solid var(--bg-line-employee)"}
            borderRadius={"0px 0px 16px 16px"}
            zIndex={3}
          >
            {defaultValues?.status === "NEW" && (
              <Flex p={"24px"} gap={"16px"} justifyContent={"flex-end"}>
                <Button
                  p={"13px 20px"}
                  fontSize={"15px"}
                  fontWeight={600}
                  color={"var(--color-option-employee-hover)"}
                  borderRadius={"8px"}
                  border={"1px solid var(--color-option-employee-hover)"}
                  bg={"var(--color-main)"}
                  onClick={() => submitStatus("REJECTED")}
                  _hover={{
                    filter: "brightness(90%)",
                  }}
                >
                  Không duyệt
                </Button>
                <Button
                  w={"133px"}
                  p={"13px 20px"}
                  fontSize={"15px"}
                  fontWeight={600}
                  color={"var(--color-main)"}
                  borderRadius={"8px"}
                  bg={"var(--color-option-employee-hover)"}
                  onClick={() => submitStatus("APPROVED")}
                  _hover={{
                    filter: "brightness(90%)",
                  }}
                >
                  Duyệt
                </Button>
              </Flex>
            )}
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
});

export default FormInfo;
