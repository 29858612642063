import {
  Box,
  Button,
  Collapse,
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import iconApproved from "../../assets/images/receiveSalary/check-circle.svg";
import ChevronUpIcon from "../../assets/images/chevron-up.png";
import ChevronDownIcon from "../../assets/images/chevron-down.svg";
import { formatTableValue } from "../../pages/commissionConfirmation/formatTable";
import { formatDateDb } from "../../utils/tools";

const FormInfoCommission = ({
  id,
  rate,
  date,
  data,
  openItems,
  toggleContent,
}) => {
  const dataTable = {
    headers: [
      {
        label: "Diễn giải",
        key: "employee",
      },
      {
        label: "Nhân viên",
        key: "employee",
      },
      {
        label: "Ngày công",
        key: "commissionRate",
      },
      {
        label: "Hoa hồng",
        key: "commissionRate",
      },
    ],
    data: data,
  };

  return (
    <Box p={"16px 0"}>
      <Flex justify="space-between" align="center" mb={4}>
        <Text
          fontSize={"16px"}
          lineHeight={"19px"}
          fontWeight={"600"}
          color={"var(--color-header-employee)"}
        >
          Bảng tính hoa hồng nhân viên tham gia - Đợt {id}
        </Text>
        <Button
          bg={"none"}
          outline={"none"}
          border={"none"}
          p={"0px"}
          m={"0px"}
          onClick={() => toggleContent(id)}
          rightIcon={
            openItems ? (
              <Image src={ChevronDownIcon} />
            ) : (
              <Image src={ChevronUpIcon} />
            )
          }
          size="sm"
        ></Button>
      </Flex>
      <Collapse in={openItems} animateOpacity>
        <Flex
          w={"100%"}
          p={"14px 16px"}
          alignItems={"center"}
          gap={"27px"}
          justifyContent={"space-between"}
          bg={"var(--color-background-2)"}
          borderRadius={"8px"}
        >
          <Box>
            <Text fontSize={"12px"} color={"var(--color-black-4)"}>
              Tỷ lệ thanh toán tương ứng
            </Text>
            <Text
              mt={"8px"}
              fontSize={"14px"}
              color={"var(--color-info-employee)"}
              fontWeight={500}
            >
              {rate}%
            </Text>
          </Box>
          <Flex alignItems={"center"} gap={"8px"}>
            <Image src={iconApproved} />
            <Text
              color={"var(--color-status-employ)"}
              fontSize={"14px"}
              fontWeight={500}
            >
              Đã chốt ngày {date ? formatDateDb(date) : ''}
            </Text>
          </Flex>
        </Flex>
        <Box background={"var(--fill-avatar)"} mt={"16px"} borderRadius={"8px"}>
          <TableContainer w={"100%"}>
            <Table w={"100%"} variant="unstyled">
              <Thead
                w={"100%"}
                h={"41px"}
                color="white"
                fontWeight="400"
                backgroundColor="#F9FAFD"
                borderBottom={"1px solid var(--bg-line-employee)"}
              >
                <Tr h={{ base: "41px" }}>
                  {dataTable.headers.map((e, index) => {
                    let width = "auto";
                    let textAlign = "auto";
                    if (e.key === "employee") {
                      width = "2%";
                      textAlign = "start";
                    } else if (e.key === "employeeJobTitle") {
                      width = "5%";
                      textAlign = "start";
                    } else {
                      width = "10%";
                      textAlign = "end";
                    }
                    return (
                      <Td
                        p={"16px 16px 8px 16px"}
                        key={index}
                        border={"none"}
                        color={"#51607B"}
                        fontSize={{ base: "14px" }}
                        w={width}
                      >
                        <Box textAlign={textAlign}>{e.label}</Box>
                      </Td>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody w={"100%"} h={"100%"}>
                {dataTable.data?.map((e, rowIndex) => {
                  const keyValues = Object.keys(e);
                  return (
                    <Tr
                      key={rowIndex}
                      h={"44px"}
                      backgroundColor={
                        rowIndex % 2 === 0
                          ? "var(--color-main)"
                          : "var(--fill-avatar)"
                      }
                    >
                      {keyValues.map((keyvalue, index) => {
                        let width = "auto";
                        let widthText = "100%";
                        let textAlign = "auto";
                        let whiteSpace = "inherit";
                        if (keyvalue === "employee") {
                          width = "40%";
                          textAlign = "start";
                        } else if (keyvalue === "employeeJobTitle") {
                          width = "40%";
                          textAlign = "start";
                        } else {
                          width = "20%";
                          textAlign = "center";
                          whiteSpace = "inherit";
                        }
                        return (
                          <Td p={"16px 16px 8px 16px"} w={width} key={index}>
                            <Box
                              fontSize={{ base: "14px" }}
                              lineHeight={"19.6px"}
                              textAlign={textAlign}
                              fontWeight={500}
                              color={"var(--color-info-employee)"}
                              w={widthText}
                              whiteSpace={whiteSpace}
                              overflow="hidden"
                              textOverflow="ellipsis"
                              display="-webkit-box"
                              sx={{
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical",
                              }}
                            >
                              {formatTableValue(e[keyvalue], keyvalue)}
                            </Box>
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Collapse>
    </Box>
  );
};

export default FormInfoCommission;
