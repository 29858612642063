import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  HStack,
  Img,
  Button,
  Image,
  MenuItem,
  MenuList,
  MenuButton,
  Menu,
  useDisclosure,
  Skeleton,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { formatTableValue } from "./formatTable";
import chevronLeft from "../../assets/images/employee/chevron-left.svg";
import chevronRight from "../../assets/images/employee/chevron-right.svg";
import useDebounce from "../../hooks/useDebounce";
import ReactPaginate from "react-paginate";
import iconEdit from "../../assets/images/product/edit.svg";
import iconDetail from "../../assets/images/contract/Frame (1).svg";
import { AiOutlineMore } from "react-icons/ai";
import SearchableSelect from "../../components/selectForm";
import FormDetail from "./form/detail";
import FormInfo from "./form/info";
import { useCategories } from "../../hooks/useCategories";
import { useLocking } from "../../hooks/useLooking";
import toast from "react-hot-toast";
import { lockingCall } from "../../api/locking";
import { commonCall } from "../../api/common";
import { lockingImageCall } from "../../api/lockingImage";
import { lockingParticipantCall } from "../../api/lockingParticipant";

const TableLocking = ({ isRefetchingWithCreate }) => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  const [search, setSearch] = useState("");
  const [isAddBooking, setIsAddBooking] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);
  const [dataEdit, setDataEdit] = useState(null);

  const debouncedSearchTerm = useDebounce(search, 500);
  const onChangeSearch = useCallback((e) => {
    const { value } = e.target;
    setSearch(value);
  });

  const {
    lockingManagementData,
    refetch,
    isLoading,
    isRefetching,
    totalPages,
    setCurrentPage,
    currentPage,
    entries,
    startEntry,
    endEntry,
  } = useLocking(debouncedSearchTerm, projectId, employeeId);

  // refech create
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }
  const prevIsRefetchingWithCreate = usePrevious(isRefetchingWithCreate);

  useEffect(() => {
    if (
      isRefetchingWithCreate &&
      prevIsRefetchingWithCreate !== isRefetchingWithCreate
    ) {
      refetch();
    }
  }, [isRefetchingWithCreate, prevIsRefetchingWithCreate, refetch]);

  const { constantData } = useCategories();

  const formatEntries = (entries) =>
    Object.entries(entries || {}).map(([key, value]) => ({ key, value }));

  const projects = useMemo(
    () => formatEntries(constantData?.project),
    [constantData?.project]
  );
  const employees = useMemo(
    () => formatEntries(constantData?.employee),
    [constantData?.employee]
  );

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    refetch();
  };

  const tableData = {
    headers: [
      {
        label: "",
        key: "",
      },
      {
        label: "STT",
        key: "",
      },
      {
        label: "Trạng thái",
        key: "status",
      },
      {
        label: "Tên dự án",
        key: "nameProject",
      },
      {
        label: "Tên sản phẩm",
        key: "nameApartment",
      },
      {
        label: "Tên khách hàng",
        key: "nameCustomer",
      },
      {
        label: "Số tiền đặt cọc",
        key: "price",
      },
      {
        label: "TG đặt cọc",
        key: "bookingTime",
      },
      {
        label: "Người tạo looking",
        key: "nameLocking",
      },
      {
        label: "Ngày tạo looking",
        key: "createdAt",
      },
      {
        label: "Ghi chú",
        key: "saleOpeningEventName",
      },
    ],
    data: lockingManagementData,
  };

  // table
  const handlePopup = (type, e) => {
    if (type) {
      setDataEdit(e?.edit);
    } else {
      setDataEdit(e?.detail);
    }

    setIsAddBooking(type);
    onOpen();
  };

  const onSubmitEdit = async (data) => {
    const toastCreate = toast.loading("Đang Sửa locking ...");
    try {
      let filteredData = Object.fromEntries(
        Object.entries(data).filter(
          ([_, value]) => value !== "" && value !== undefined && value !== null
        )
      );

      const {
        id,
        infident1,
        infident2,
        imagePayment,
        employeeProject,
        ...dataFilter
      } = filteredData;


      if (id) {
        let createDataEmployeeManager = [];
        if (employeeProject) {
          createDataEmployeeManager = employeeProject.map((item) => {
            if (item?.id) {
              delete item?.id;
            }
            item.lockingId = id;
            return item;
          });
        }
        if (createDataEmployeeManager.length > 0) {
          await lockingParticipantCall.createAndUpdateMany({
            lockingId: id,
            list: createDataEmployeeManager,
          });
        }
        if (data?.infident1 && Object.keys(data?.infident1).length !== 0) {
          const { filePath } = await commonCall.uploadFile(
            infident1?.file,
            "lockingDocument"
          );
          if (filePath) {
            if (data?.infident1?.init) {
              await lockingImageCall.update(data?.infident1?.id, {
                lockingId: id,
                path: filePath,
                type: "IDENTIFICATION_BEFORE",
              });
            } else {
              await lockingImageCall.create({
                lockingId: id,
                path: filePath,
                type: "IDENTIFICATION_BEFORE",
              });
            }
          }
        }
        if (data?.infident2 && Object.keys(data?.infident2).length !== 0) {
          const { filePath } = await commonCall.uploadFile(
            infident2?.file,
            "bookingDocument"
          );
          if (filePath) {
            if (data?.infident2?.init) {
              await lockingImageCall.update(data?.infident2?.id, {
                lockingId: id,
                path: filePath,
                type: "IDENTIFICATION_AFTER",
              });
            } else {
              await lockingImageCall.create({
                lockingId: id,
                path: filePath,
                type: "IDENTIFICATION_AFTER",
              });
            }
          }
        }
        if (
          data?.imagePayment &&
          Object.keys(data?.imagePayment).length !== 0
        ) {
          const { filePath } = await commonCall.uploadFile(
            imagePayment?.file,
            "bookingDocument"
          );
          if (filePath) {
            if (data?.imagePayment?.init) {
              await lockingImageCall.update(data?.imagePayment?.id, {
                lockingId: id,
                path: filePath,
                type: "TRANSACTION",
              });
            } else {
              await lockingImageCall.create({
                lockingId: id,
                path: filePath,
                type: "TRANSACTION",
              });
            }
          }
        }
      }

      await lockingCall.update(id, {
        ...dataFilter,
      });

      await refetch();
      onToggle();
      onClose();
      toast.success("Sửa thành công");
    } catch (error) {
      console.log({ error });
      toast.dismiss(toastCreate);
    }
    toast.dismiss(toastCreate);
  };

  const submitStatus = async (status, id) => {
    const toastEdit = toast.loading("Đang cập nhập trạng thái ...");
    const dataFormat = {
      status: status,
    };

    try {
      await lockingCall.approve(id, dataFormat);
      await refetch();
      toast.success("Cập nhập trạng thái thành công");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Có lỗi xảy ra khi cập nhập trạng thái");
    }
    toast.dismiss(toastEdit);
  };

  return (
    <>
      <Flex flexDirection={"column"} gap={{ base: "16px" }}>
        <Flex
          backgroundColor="var(--color-main)"
          borderRadius={{ base: "12px" }}
          flexDirection={"column"}
        >
          <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={{ base: "32px" }}
            p={"16px 16px 12px 16px"}
          >
            <Flex
              alignItems={"center"}
              gap={{ base: "32px" }}
              flex={"1"}
              flexDirection={{ base: "column", xl: "row" }}
            >
              <InputGroup
                maxW="506px"
                borderRadius={{ base: "8px" }}
                w={{ base: "100%" }}
              >
                <Input
                  placeholder="Tên đợt mở bán"
                  type="text"
                  borderRadius={{ base: "8px" }}
                  border={{ base: "1px solid var(--color-boder)" }}
                  backgroundColor={"var(--fill-avatar)"}
                  onChange={onChangeSearch}
                />
                <InputRightElement
                  borderTopRightRadius={"8px"}
                  borderBottomRightRadius={"8px"}
                  pointerEvents="none"
                  cursor={"pointer"}
                  backgroundImage="var(--color-button)"
                >
                  <SearchIcon color="var(--color-main)" />
                </InputRightElement>
              </InputGroup>
              <Flex alignItems={"center"} gap={{ base: "32px", lg: "24px" }}>
                <SearchableSelect
                  isShowSearch={false}
                  options={projects}
                  initialSelectedOption={{ key: 0, value: "Chọn dự án" }}
                  setValue={setProjectId}
                />
                <SearchableSelect
                  isShowSearch={false}
                  options={employees}
                  initialSelectedOption={{
                    key: 0,
                    value: "Chọn nhân viên tạo",
                  }}
                  setValue={setEmployeeId}
                />
              </Flex>
            </Flex>
          </Flex>
          <Box width={"100%"} height={"100%"}>
            <Skeleton isLoaded={!isLoading && !isRefetching}>
              <TableContainer w={"100%"}>
                <Table w={"100%"} variant="unstyled">
                  <Thead
                    w={"100%"}
                    h={"41px"}
                    color="white"
                    fontWeight="400"
                    backgroundColor="#F9FAFD"
                  >
                    <Tr h={{ base: "41px" }}>
                      {tableData.headers.map((e, index) => {
                        let width = "auto";
                        let textAlign = "auto";
                        if (e.key === "") {
                          width = "2%";
                          textAlign = "center";
                        } else if (e.key === "code") {
                          width = "5%";
                          textAlign = "start";
                        } else if (e.key === "status") {
                          width = "10%";
                          textAlign = "start";
                        } else if (e.key === "nameProject") {
                          width = "10%";
                          textAlign = "start";
                        } else if (e.key === "saleOpeningEventName") {
                          width = "10%";
                          textAlign = "start";
                        } else if (e.key === "nameApartment") {
                          width = "10%";
                          textAlign = "start";
                        } else if (e.key === "codeProduct") {
                          width = "10%";
                          textAlign = "start";
                        } else if (
                          e.key === "timeDelivery" ||
                          e.key === "timeStart" ||
                          e.key === "timeEnd"
                        ) {
                          width = "5%";
                          textAlign = "end";
                        } else {
                          width = "10%";
                          textAlign = "end";
                        }
                        return (
                          <Td
                            p={"16px 16px 8px 16px"}
                            key={index}
                            border={"none"}
                            color={"#51607B"}
                            fontSize={{ base: "14px" }}
                            w={width}
                          >
                            <Box textAlign={textAlign}>{e.label}</Box>
                          </Td>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody w={"100%"} h={"100%"}>
                    {tableData.data?.length > 0 ? (
                      tableData.data?.map((e, rowIndex) => {
                        const items = { ...e };
                        delete items.id;
                        delete items.edit;
                        delete items.detail;
                        const keyValues = Object.keys(items);
                        return (
                          <Tr key={rowIndex} h={"72px"}>
                            <Td p={"16px 16px 8px 16px"} w={"2%"}>
                              <Menu>
                                <MenuButton
                                  _hover={{ backgroundColor: "none" }}
                                  _active={{ backgroundColor: "none" }}
                                  background="none"
                                  as={Button}
                                >
                                  <AiOutlineMore fontSize={"20px"} />
                                </MenuButton>
                                <MenuList
                                  fontSize={{ base: "14px" }}
                                  fontWeight={500}
                                  lineHeight={"140%"}
                                  color={"var(--color-info-employee)"}
                                >
                                  <MenuItem
                                    gap={"16px"}
                                    onClick={() => handlePopup(false, e)}
                                  >
                                    <Image src={iconDetail} /> Xem chi tiết
                                  </MenuItem>
                                  <MenuItem
                                    gap={"16px"}
                                    onClick={() => handlePopup(true, e)}
                                  >
                                    <Image src={iconEdit} /> Chỉnh sửa
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            </Td>
                            <Td p={"16px 16px 8px 16px"} w={"2%"}>
                              {rowIndex + 1}
                            </Td>
                            {keyValues.map((keyvalue, index) => {
                              let width = "auto";
                              let widthText = "100%";
                              let textAlign = "auto";
                              let whiteSpace = "inherit";
                              if (keyvalue === "") {
                                width = "2%";
                                textAlign = "center";
                              } else if (keyvalue === "code") {
                                width = "5%";
                                textAlign = "start";
                              } else if (keyvalue === "status") {
                                width = "15%";
                                textAlign = "start";
                              } else if (keyvalue === "saleOpeningEventName") {
                                width = "15%";
                                textAlign = "start";
                              } else if (keyvalue === "nameProject") {
                                width = "15%";
                                textAlign = "start";
                              } else if (keyvalue === "price") {
                                width = "10%";
                                textAlign = "end";
                              } else if (keyvalue === "bookingTime") {
                                width = "10%";
                                textAlign = "end";
                              } else if (keyvalue === "nameApartment") {
                                width = "10%";
                                textAlign = "start";
                              } else {
                                width = "10%";
                                textAlign = "end";
                                whiteSpace = "inherit";
                              }
                              return (
                                <Td
                                  p={"16px 16px 8px 16px"}
                                  w={width}
                                  key={index}
                                >
                                  <Box
                                    fontSize={{ base: "14px" }}
                                    lineHeight={"19.6px"}
                                    textAlign={textAlign}
                                    fontWeight={500}
                                    color={"var(--color-info-employee)"}
                                    w={widthText}
                                    whiteSpace={whiteSpace}
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    display="-webkit-box"
                                    sx={{
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                    }}
                                  >
                                    {keyvalue === "status"
                                      ? formatTableValue(
                                          e[keyvalue],
                                          keyvalue,
                                          submitStatus,
                                          e.id
                                        )
                                      : formatTableValue(e[keyvalue], keyvalue)}
                                  </Box>
                                </Td>
                              );
                            })}
                          </Tr>
                        );
                      })
                    ) : (
                      <Tr>
                        <Td
                          colSpan={tableData.headers.length}
                          textAlign="center"
                          padding={"40px 0"}
                        >
                          Không có dữ liệu
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </Skeleton>
            <Box mt={10} mr={5} mb={5} ml={5}>
              <Flex alignItems={"center"} justifyContent={"space-between"}>
                <Text>
                  Showing {startEntry} to {endEntry} of {entries} entries
                </Text>
                <HStack spacing={2} justify="flex-end">
                  <ReactPaginate
                    className="pagination-custom"
                    pageCount={totalPages}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    breakClassName={"ellipsis"}
                    breakLabel={"..."}
                    previousLabel={
                      <Img
                        w={"16px"}
                        minW={"16px"}
                        h={"16px"}
                        src={chevronLeft}
                      />
                    }
                    nextLabel={
                      <Img
                        w={"16px"}
                        minW={"16px"}
                        h={"16px"}
                        src={chevronRight}
                      />
                    }
                    renderOnZeroPageCount={null}
                    forcePage={currentPage - 1}
                  />
                </HStack>
              </Flex>
            </Box>
          </Box>
        </Flex>
      </Flex>
      {isAddBooking ? (
        <FormDetail
          isOpen={isOpen}
          onClose={onClose}
          isEdit={true}
          defaultValues={dataEdit}
          onSubmit={onSubmitEdit}
        />
      ) : (
        <FormInfo
          isOpen={isOpen}
          onClose={onClose}
          defaultValues={dataEdit}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default TableLocking;
