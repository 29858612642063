import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { clientAPI } from "../api/client";
import { NumberOfPerPage } from "../utils/globalVariable";
import { formatDateDb } from "../utils/tools";
import {
  customDateFormatType2,
  formatDateForInput,
  formatNumber,
} from "../utils/utils";

const NUMBER_NFT_PER_PAGE = NumberOfPerPage.saleOpeningEvent;
const queryKey = "saleOpeningEvent";

async function fetchSaleOpeningEvent(
  currentPage,
  search,
  startTime,
  endTime,
  projectId
) {
  try {
    const options = {
      limit: NUMBER_NFT_PER_PAGE,
      page: currentPage,
    };

    if (search !== undefined) options.search = search;
    if (startTime) options.startTime = customDateFormatType2(startTime);
    if (endTime) options.endTime = customDateFormatType2(endTime);
    if (projectId) options.projectId = projectId;

    const queryParams = new URLSearchParams(options).toString();
    let data = await clientAPI("get", `/saleOpeningEvent?${queryParams}`);
    const totalPages = Math.ceil(data?.pagination?.total / NUMBER_NFT_PER_PAGE);
    const entries = data?.pagination?.total;
    const startEntry =
      (data?.pagination?.page - 1) * data?.pagination?.limit + 1;
    const endEntry = Math.min(
      data?.pagination?.page * data?.pagination?.limit,
      entries
    );

    const newData = data?.items.map((item, index) => {
      return {
        id: item?._id || "",
        code: item?.code || "-",
        name: item?.name || "-",
        project: item?.project?.name || "-",
        product: item?.apartmentSaleOpeningEventLink?.length || "-",
        startTime: item?.startTime ? formatDateDb(item?.startTime) : "-",
        endTime: item?.endTime ? formatDateDb(item?.endTime) : "-",
        price: item?.price ? `${formatNumber(item?.price)} VNĐ` : "",
        creator: item?.creator?.fullName || "-",
        createdAt: item?.createdAt ? formatDateDb(item?.createdAt) : "-",
        edit: {
          id: item?._id || "",
          name: item?.name || "",
          projectId: item?.projectId || "",
          price: item?.price || "",
          startTime: item?.startTime ? formatDateForInput(item?.startTime) : "",
          endTime: item?.endTime ? formatDateForInput(item?.endTime) : "",
        },
        addProduct: {
          id: item?._id || "",
          name: item?.name || "",
          projectId: item?.projectId || "",
          projectName: item?.project?.name || "",
        },
        detail: {
          id: item?._id || "",
          note: item?.note || "",
          status: item?.status || "",
          creator: item?.creatorObject?.fullName || "",
          createdAt: item?.createdAt ? formatDateDb(item?.createdAt) : "-",
          project: item?.locking?.projectObject?.name || "",
          customer: item?.locking?.customerObject?.name || "",
          price: item?.locking?.price || "",
          bookingTime: item?.locking?.bookingTime
            ? formatDateForInput(item?.locking?.bookingTime)
            : "",
          apartment: item?.locking?.apartmentObject?.name || "",
          apartments: item?.locking?.apartment,
          approver: item?.approver?.fullName || "",
          time: item?.time || "",
          images: item?.locking?.images,
        },
      };
    });

    return { data: newData, totalPages, entries, startEntry, endEntry };
  } catch (error) {
    console.error("Error fetching checkIn data:", error);
    return { data: [], totalPages: 0 };
  }
}

export function useSaleOpeningEvent(search, startTime, endTime, projectId) {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: [queryKey, currentPage, search, startTime, endTime, projectId],
    queryFn: () =>
      fetchSaleOpeningEvent(currentPage, search, startTime, endTime, projectId),
    refetchOnWindowFocus: false,
    staleTime: 30000,
    keepPreviousData: true,
    onError: (error) => {
      console.error("Error fetching checkIn data:", error);
    },
  });

  const nextPage = () => {
    if (currentPage < data?.totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return {
    saleOpeningEventData: data?.data,
    totalPages: data?.totalPages,
    refetch,
    isLoading,
    isRefetching,
    prevPage,
    nextPage,
    setCurrentPage,
    currentPage,
    entries: data?.entries,
    startEntry: data?.startEntry,
    endEntry: data?.endEntry,
  };
}
