import {
  Box,
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import IconCheck from "../../../assets/images/booking/check-circle.svg";
import { formatTableValue } from "../formatTable";
import { stepCommissionCall } from "../../../api/stepCommission";

const InfoStep1 = ({ defaultValues }) => {
  const [totalPrice, setTotalPrice] = useState(0);

  let datatable = [];
  if (defaultValues?.lockingParticipant) {
    datatable = defaultValues?.lockingParticipant.map((item) => ({
      employee: item?.employee?.fullName || "",
      employeeJobTitle: item?.employeeJobTitle || "",
      commissionRate: item.commissionRate ? `${item.commissionRate}%` : "",
    }));
  }

  useEffect(() => {
    if (defaultValues?.id) {
      // tính Tỷ lệ trả
      const fetchEmployee = async () => {
        try {
          const getTotalPriceData = await stepCommissionCall.getTotalPrice(
            defaultValues.id
          );
          
          if (getTotalPriceData?.total) {
            setTotalPrice(getTotalPriceData.total);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchEmployee();
    }
  }, [defaultValues]);

  const dataTable = {
    headers: [
      {
        label: "Tên nhân viên",
        key: "employee",
      },
      {
        label: "Chức danh",
        key: "employeeJobTitle",
      },
      {
        label: "Hệ số thưởng",
        key: "commissionRate",
      },
    ],
    data: datatable,
  };

  return (
    <>
      {defaultValues?.confirmCommissionStatus === "APPROVED" ? (
        <Flex
          p={"16px 24px"}
          justifyContent={"space-between"}
          bg={"var(--bg-green-1)"}
        >
          <Flex
            p={"12px"}
            justifyContent={"space-between"}
            gap={"8px"}
            bg={"var(--bg-status-success)"}
            borderRadius={"8px"}
            w={"max-content"}
            minW={"151px"}
            h={"max-content"}
          >
            <Image src={IconCheck} w={"20px"} h={"20px"} objectFit={"cover"} />
            <Text
              fontSize={"14px"}
              lineHeight={"20px"}
              fontWeight={500}
              color={"var(--color-status-employ)"}
              display={"block"}
            >
              Đã được duyệt
            </Text>
          </Flex>
          <Flex gap={"40px"}>
            <Box textAlign={"end"}>
              <Text color={"var(--color-label-employee)"} fontSize={"12px"}>
                Người phê duyệt
              </Text>
              <Text
                color={"var(--color-option-employee)"}
                fontSize={"14px"}
                fontWeight={"500"}
                mt={"8px"}
              >
                {defaultValues?.approver || ""}
              </Text>
            </Box>
            <Box textAlign={"end"}>
              <Text color={"var(--color-label-employee)"} fontSize={"12px"}>
                Thời gian duyệt
              </Text>
              <Text
                color={"var(--color-option-employee)"}
                fontSize={"14px"}
                fontWeight={"500"}
                mt={"8px"}
              >
                {defaultValues?.approverConfirmDate || ""}
              </Text>
            </Box>
          </Flex>
        </Flex>
      ) : (
        ""
      )}

      <Flex
        flexDirection={"column"}
        maxW={"545px"}
        margin={"0 auto"}
        alignItems={"center"}
        gap={"0px"}
      >
        <Box w={"100%"} mt={"24px"}>
          <Text
            fontSize={"16px"}
            lineHeight={"19px"}
            fontWeight={"600"}
            color={"var(--color-header-employee)"}
            mb={"16px"}
          >
            Thông tin chung
          </Text>
          {/* 1 */}
          <Flex
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            gap={"27px"}
            mb={"12px"}
            w={"100%"}
          >
            <Box flex={"1"}>
              <Text color={"var(--color-label-employee)"} fontSize={"12px"}>
                Mã phiếu locking
              </Text>
              <Text
                color={"var(--color-option-employee)"}
                fontSize={"14px"}
                fontWeight={"500"}
                mt={"8px"}
              >
                {defaultValues?.code || ""}
              </Text>
            </Box>
            <Box flex={"1"}>
              <Text color={"var(--color-label-employee)"} fontSize={"12px"}>
                Thời điểm xác nhận
              </Text>
              <Text
                color={"var(--color-option-employee)"}
                fontSize={"14px"}
                fontWeight={"500"}
                mt={"8px"}
              >
                {defaultValues?.approverConfirmDate || ""}
              </Text>
            </Box>
          </Flex>
          {/* 2 */}
          <Flex
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            gap={"27px"}
            mb={"12px"}
            w={"100%"}
          >
            <Box flex={"1"}>
              <Text color={"var(--color-label-employee)"} fontSize={"12px"}>
                Dự án
              </Text>
              <Text
                color={"var(--color-option-employee)"}
                fontSize={"14px"}
                fontWeight={"500"}
                mt={"8px"}
              >
                {defaultValues?.project || ""}
              </Text>
            </Box>
            <Box flex={"1"}>
              <Text color={"var(--color-label-employee)"} fontSize={"12px"}>
                Sản phẩm
              </Text>
              <Text
                color={"var(--color-option-employee)"}
                fontSize={"14px"}
                fontWeight={"500"}
                mt={"8px"}
              >
                {defaultValues?.apartment || ""}
              </Text>
            </Box>
          </Flex>
          {/* 3 */}
          <Flex
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            gap={"27px"}
            mb={"12px"}
            w={"100%"}
          >
            <Box flex={"1"}>
              <Text color={"var(--color-label-employee)"} fontSize={"12px"}>
                Phân loại PVD
              </Text>
              <Text
                color={"var(--color-option-employee)"}
                fontSize={"14px"}
                fontWeight={"500"}
                mt={"8px"}
              >
                {defaultValues?.serviceFeeType || ""}
              </Text>
            </Box>
            <Box flex={"1"}>
              <Text color={"var(--color-label-employee)"} fontSize={"12px"}>
                Hệ số K
              </Text>
              <Text
                color={"var(--color-option-employee)"}
                fontSize={"14px"}
                fontWeight={"500"}
                mt={"8px"}
              >
                {defaultValues?.kda || ""}
              </Text>
            </Box>
          </Flex>
          {/* 4 */}
          <Flex
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            gap={"27px"}
            mb={"12px"}
            w={"100%"}
          >
            <Box flex={"1"}>
              <Text color={"var(--color-label-employee)"} fontSize={"12px"}>
                Sô tiền
              </Text>
              <Text
                color={"var(--color-option-employee)"}
                fontSize={"14px"}
                fontWeight={"500"}
                mt={"8px"}
              >
                {defaultValues?.priceForCommission || ""}
              </Text>
            </Box>
            <Box flex={"1"}>
              <Text color={"var(--color-label-employee)"} fontSize={"12px"}>
                Tỷ lệ trả
              </Text>
              <Text
                color={"var(--color-option-employee)"}
                fontSize={"14px"}
                fontWeight={"500"}
                mt={"8px"}
              >
                {totalPrice ? totalPrice?.toString() : "0"}%
              </Text>
            </Box>
          </Flex>
          {/* 5 */}
          <Flex
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            gap={"27px"}
            mb={"12px"}
            w={"100%"}
          >
            <Box flex={"1"}>
              <Text color={"var(--color-label-employee)"} fontSize={"12px"}>
                Người tạo
              </Text>
              <Text
                color={"var(--color-option-employee)"}
                fontSize={"14px"}
                fontWeight={"500"}
                mt={"8px"}
              >
                {defaultValues?.booker || ""}
              </Text>
            </Box>
            <Box flex={"1"}>
              <Text color={"var(--color-label-employee)"} fontSize={"12px"}>
                Ngày tạo
              </Text>
              <Text
                color={"var(--color-option-employee)"}
                fontSize={"14px"}
                fontWeight={"500"}
                mt={"8px"}
              >
                {defaultValues?.createdAt || ""}
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box m={"24px 0"} w={"100%"}>
          <Text
            color={"var(--color-header-employee)"}
            fontSize={"16px"}
            fontWeight={"600"}
          >
            Nhân viên tham gia
          </Text>
          <Box
            background={"var(--fill-avatar)"}
            mt={"16px"}
            borderRadius={"8px"}
          >
            <TableContainer w={"100%"}>
              <Table w={"100%"} variant="unstyled">
                <Thead
                  w={"100%"}
                  h={"41px"}
                  color="white"
                  fontWeight="400"
                  backgroundColor="#F9FAFD"
                  borderBottom={"1px solid var(--bg-line-employee)"}
                >
                  <Tr h={{ base: "41px" }}>
                    {dataTable.headers.map((e, index) => {
                      let width = "auto";
                      let textAlign = "auto";
                      if (e.key === "employee") {
                        width = "2%";
                        textAlign = "start";
                      } else if (e.key === "employeeJobTitle") {
                        width = "5%";
                        textAlign = "start";
                      } else {
                        width = "10%";
                        textAlign = "end";
                      }
                      return (
                        <Td
                          p={"16px 16px 8px 16px"}
                          key={index}
                          border={"none"}
                          color={"#51607B"}
                          fontSize={{ base: "14px" }}
                          w={width}
                        >
                          <Box textAlign={textAlign}>{e.label}</Box>
                        </Td>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody w={"100%"} h={"100%"}>
                  {dataTable.data?.length > 0 ? (
                    dataTable.data?.map((e, rowIndex) => {
                      const keyValues = Object.keys(e);
                      return (
                        <Tr
                          key={rowIndex}
                          h={"44px"}
                          backgroundColor={
                            rowIndex % 2 === 0
                              ? "var(--color-main)"
                              : "var(--fill-avatar)"
                          }
                        >
                          {keyValues.map((keyvalue, index) => {
                            let width = "auto";
                            let widthText = "100%";
                            let textAlign = "auto";
                            let whiteSpace = "inherit";
                            if (keyvalue === "employee") {
                              width = "40%";
                              textAlign = "start";
                            } else if (keyvalue === "employeeJobTitle") {
                              width = "40%";
                              textAlign = "start";
                            } else {
                              width = "20%";
                              textAlign = "center";
                              whiteSpace = "inherit";
                            }
                            return (
                              <Td
                                p={"16px 16px 8px 16px"}
                                w={width}
                                key={index}
                              >
                                <Box
                                  fontSize={{ base: "14px" }}
                                  lineHeight={"19.6px"}
                                  textAlign={textAlign}
                                  fontWeight={500}
                                  color={"var(--color-info-employee)"}
                                  w={widthText}
                                  whiteSpace={whiteSpace}
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                  display="-webkit-box"
                                  sx={{
                                    WebkitLineClamp: "2",
                                    WebkitBoxOrient: "vertical",
                                  }}
                                >
                                  {formatTableValue(e[keyvalue], keyvalue)}
                                </Box>
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    })
                  ) : (
                    <Tr>
                      <Td
                        colSpan={dataTable.headers.length}
                        textAlign="center"
                        padding={"40px 0"}
                      >
                        Không có dữ liệu
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default InfoStep1;
