import toast from "react-hot-toast";

const { clientAPI } = require("../client");
export const checkinPlaceCall = {
  create: async (data) => { 
    try {
        const response = await clientAPI("post", "/checkin/place/", data);
        return response;
    } catch (error) {
      console.error("Create error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  update: async (id, data) => {
    try {
      const response = await clientAPI("put", `/checkin/place/${id}`, data);
      return response;
    } catch (error) {
      console.error("Update error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  delete: async (id) => {
    try {
      const response = await clientAPI("delete", `/checkin/place/${id}`);
      return response;
    } catch (error) {
      console.error("Delete error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  get: async (id) => {
    try {
      const response = await clientAPI(
        "get",
        `/checkin/place/${id}/`
      );
      return response;
    } catch (error) {
      console.error("update error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  getEmployeesByCheckinPlaceId: async (id) => {
    try {
      const response = await clientAPI(
        "get",
        `/checkinPlaceParticipant?checkinPlaceId=${id}`
      );
      return response;
    } catch (error) {
      console.error("Delete error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  bulkCreate: async (data) => {
    try {
      const response = await clientAPI(
        "post",
        "/checkinPlaceParticipant/bulk",
        data
      );
      return response;
    } catch (error) {
      console.error("Create error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  bulkImport: async (id, data) => {
    try {
      const dataIp = {
        checkinPlaceId: id,
        list: data,
      };
      const response = await clientAPI(
        "post",
        "/checkinPlaceParticipant/bulkImport",
        dataIp
      );
      return response;
    } catch (error) {
      console.error("Create error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
};