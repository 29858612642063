import { createSlice } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import { TIMEZONE } from "../../utils/contants";

const status = {
  READ: "read",
  UNREAD: "unread",
};

const initialState = {
  token: null,
  messages: [], // Ensure this is initialized as an array
};

const fcmSlice = createSlice({
  name: "fcm",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setMessage: (state, action) => {
      // console.log({ state, action });

      // Check if employeeId is different before adding the message
      const existingMessage = state.messages.find(
        (msg) => msg.messageId === action.payload.messageId
      );

      if (!existingMessage) {
        state.messages = [
          ...(state.messages || []),
          {
            ...action.payload,
            status: status.UNREAD,
            createAt: moment().tz(TIMEZONE).format("HH:mm:ss DD/MM/YYYY"),
            employeeId: action.payload.employeeId,
          },
        ];
      }
    },
    clearAllMessages: (state) => {
      state.messages = []; // Clear all messages
    },
    markMessageAsRead: (state, action) => {
      state.messages = state.messages.map((msg) =>
        msg.messageId === action.payload.messageId
          ? { ...msg, status: status.READ } // Update `read` status
          : msg
      );
    },
    deleteMessage: (state, action) => {
      state.messages = state.messages?.filter(
        (msg) => msg.messageId !== action.payload.messageId
      );
    },
  },
});

export const {
  setToken,
  setMessage,
  clearAllMessages,
  markMessageAsRead,
  deleteMessage,
} = fcmSlice.actions;

export default fcmSlice.reducer;
