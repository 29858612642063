import {
  Box,
  Flex,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
} from "@chakra-ui/react";
import React, { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DetailStep1 from "./detailStep1";
import IconX from "../../../assets/images/booking/x-circle.svg";
import DetailStep2 from "./detailStep2";
import toast from "react-hot-toast";
import { lockingCall } from "../../../api/locking";

const FormDetail = memo(
  ({ isOpen, onClose, defaultValues, refetch, onSetEdit }) => {
    const {
      reset,
    } = useForm({
      defaultValues: defaultValues || {},
    });

    const steps = [
      { title: "Xác nhận tính hoa hồng" },
      { title: "Thanh toán hoa hồng" },
    ];

    const [activeStep, setActiveStep] = useState(defaultValues?.confirmCommissionStatus==='APPROVED' ? 2 : 1);

    const handleNext = () => {
      reset(defaultValues);
      onSetEdit();
      if (activeStep < steps.length) {
        setActiveStep((prevStep) => prevStep + 1);
      }
    };

    const handlePrevious = () => { 
      reset(defaultValues);
      onSetEdit();
      if (activeStep > 1) {
        setActiveStep((prevStep) => prevStep - 1);
      }
    };

    useEffect(() => {
      setActiveStep(defaultValues?.confirmCommissionStatus==='APPROVED' ? 2 : 1);
      reset(defaultValues);
    }, [isOpen, setActiveStep, reset, defaultValues]);

    const updateConfirm = async (id, data) => {
      const toastCreate = toast.loading("Đang sửa phiếu xác nhận ...");
      try {
        await lockingCall.updateConfirm(id, data);
        refetch();
        handleNext();
        toast.success("Sửa thành công");
      } catch (error) {
        console.log({ error });
        toast.dismiss(toastCreate);
      }
      toast.dismiss(toastCreate);
    };

    const dataSteps = [
      {
        step: 1,
        data: (
          <DetailStep1
            defaultValues={defaultValues}
            updateConfirm={updateConfirm}
          />
        ),
      },
      { step: 2, data: <DetailStep2 defaultValues={defaultValues} /> },
    ];

    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          backgroundColor="var(--color-main)"
          borderRadius={"16px"}
          position={"relative"}
          w={"100%"}
          maxW={"625px"}
        >
          <Box
            h={"86.5vh"}
            w={"100%"}
            overflowY={"scroll"}
            sx={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                width: "4px",
                borderRadius: "50px",
                background: "var(--color-background)",
              },
              "&::-moz-scrollbar": {
                width: "4px",
              },
              "&::-moz-scrollbar-thumb": {
                width: "4px",
                borderRadius: "50px",
                background: "var(--color-background)",
              },
            }}
          >
            <Box>
              <Box
                paddingTop={"40px"}
                paddingBottom={"24px"}
                position={"relative"}
                borderBottom={"1px solid var(--border-input-employee)"}
              >
                <Box padding={"0px 24px"}>
                  <Flex
                    alignItems={"center"}
                    position={"relative"}
                    mb={"30px"}
                    alignContent={"center"}
                  >
                    <Text
                      color={"var(--color-header-employee)"}
                      fontSize={"16px"}
                      fontWeight={"600"}
                    >
                      Tính hoa hồng
                    </Text>
                    <Box
                      position={"absolute"}
                      right={0}
                      cursor={"pointer"}
                      onClick={onClose}
                    >
                      <Image src={IconX} />
                    </Box>
                  </Flex>
                  <Stepper
                    w={"545px"}
                    index={activeStep - 1}
                    count={steps.length}
                  >
                    {steps.map((step, index) => {
                      return (
                        <Step key={index}>
                          <StepIndicator
                            fontSize={"16px"}
                            fontWeight={600}
                            color={"red"}
                            sx={{
                              bg: `${
                                activeStep >= index + 1
                                  ? activeStep > index + 1
                                    ? "var(--color-status-employ)"
                                    : "var(--color-option-employee-hover)"
                                  : "var(--color-black-3)"
                              } !important`,
                            }}
                            border={"none"}
                            outline={"none"}
                            w={"28px"}
                            h={"28px"}
                          >
                            <StepStatus
                              complete={<StepIcon />}
                              incomplete={
                                <StepNumber color={"var(--color-main)"} />
                              }
                              active={
                                <StepNumber color={"var(--color-main)"} />
                              }
                            />
                          </StepIndicator>

                          <Box flexShrink="0">
                            <StepTitle
                              sx={{
                                color: `${
                                  activeStep >= index + 1
                                    ? activeStep > index + 1
                                      ? "var(--color-status-employ)"
                                      : "var(--color-info-employee)"
                                    : "var(--color-info-employee)"
                                } !important`,
                              }}
                              onClick={() => {
                                if (index === 0) handlePrevious();
                                else if(index === 1 && defaultValues?.confirmCommissionStatus==='APPROVED') handleNext();
                              }}
                              cursor={
                                index === 0 || (index === 1 || defaultValues?.confirmCommissionStatus==='APPROVED')
                                  ? "pointer"
                                  : "inherit"
                              }
                            >
                              {step.title}
                            </StepTitle>
                          </Box>

                          <StepSeparator
                            sx={{
                              bg: `var(--color-black-2) !important`,
                            }}
                          />
                        </Step>
                      );
                    })}
                  </Stepper>
                </Box>
              </Box>
              <Box padding={"25px 24px"}>
                {dataSteps.map((data) => {
                  if (activeStep === data.step) {
                    return (
                      <Box w={"100%"} key={data.step}>
                        {data.data}
                      </Box>
                    );
                  }
                })}
              </Box>
            </Box>
          </Box>
        </ModalContent>
      </Modal>
    );
  }
);

export default FormDetail;
