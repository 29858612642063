import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  Button,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  MenuButton,
  Image,
  HStack,
  Img,
  useDisclosure,
  Skeleton,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { formatTableValue } from "./formatTable";
import { AiOutlineMore } from "react-icons/ai";
import iconEdit from "../../assets/images/employee/edit.svg";
import detail from "../../assets/images/employee/detail.svg";
import chevronLeft from "../../assets/images/employee/chevron-left.svg";
import chevronRight from "../../assets/images/employee/chevron-right.svg";
import ReactPaginate from "react-paginate";
import calendarIcon from "../../assets/images/onleave/calendar.svg";
import FormJobTitleDetail from "./popupForm/formJobTitleDetail";
import FormJobTitleEdit from "./popupForm/formJobTitleEdit";
import { usePopupFormJobTitle } from "../../contexts/usePopupJobTitle";
import { useCategories } from "../../hooks/useCategories";
import SearchableSelect from "../../components/selectForm";
import { useAttendance } from "../../hooks/useAttendance";
import useDebounce from "../../hooks/useDebounce";
import DatePicker from "react-datepicker";
import toast from "react-hot-toast";
import { attendanceCall } from "../../api/attendance";

const TableJobTitle = ({paramData}) => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const { popupFormContextVisible, setPopupFormContextVisible } =
    usePopupFormJobTitle();

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    refetch();
  };

  const [dataEdit, setDataEdit] = useState({});
  const [dataDetail, setDataDetail] = useState({});
  const [saleBlock, setSaleBlock] = useState(null);
  const [saleArea, setSaleArea] = useState(null);
  const [department, setDepartment] = useState(null);
  const [supportDepartmentsId, setSupportDepartmentsId] = useState(null);
  const [branchesId, setBranchesId] = useState(null);
  const [date, setDate] = useState(null);
  // table
  const [search, setSearch] = useState("");
  // const [inputColors, setInputColors] = useState({
  //   date: "var(--color-text-unclick)",
  // });
  const onChangeSearch = useCallback((e) => {
    const { value } = e.target;
    setSearch(value);
  });
  const debouncedSearchTerm = useDebounce(search, 500);

  //
  const {
    attendanceData,
    refetch,
    isLoading,
    isRefetching,
    totalPages,
    setCurrentPage,
    currentPage,
    entries,
    startEntry,
    endEntry,
  } = useAttendance(
    saleBlock, 
    saleArea, 
    department,
    supportDepartmentsId,
    branchesId,
    debouncedSearchTerm, 
    date
  );

  useEffect(() => {
    paramData({
      search: debouncedSearchTerm,
      saleAreaId: saleArea,
      saleBlockId: saleBlock,
      department,
      supportDepartmentsId,
      branchesId,
      date,
    });
  }, [
    saleArea,
    saleBlock,
    department,
    supportDepartmentsId,
    branchesId,
    debouncedSearchTerm,
    date
  ]);

  const tableData = {
    headers: [
      {
        label: "",
        key: "",
      },
      {
        label: "Thời gian",
        key: "date",
      },
      {
        label: "Mã nhân viên",
        key: "codeEmployee",
      },
      {
        label: "Tên nhân viên",
        key: "fullName",
      },
      {
        label: "Khối",
        key: "saleBlock",
      },
      {
        label: "Vùng",
        key: "saleArea",
      },
      {
        label: "Phòng ban",
        key: "supportDepartment",
      },
      {
        label: "Chức vụ",
        key: "jobPosition",
      },
      {
        label: "Tỉ lệ hoa hồng",
        key: "commissionRate",
      },
      {
        label: "Ngày công",
        key: "workingDays",
      },
      {
        label: "Phân loại",
        key: "businessCategory",
      },
      {
        label: "CCCD/CMND",
        key: "identificationNumber",
      },
      {
        label: "Stk&Ngân hàng",
        key: "bankInfo",
      },
      {
        label: "Số ngày chấm công",
        key: "totalStandardWorking",
      },
      {
        label: "Mã người giới thiệu",
        key: "referralCode",
      },
      {
        label: "Tên người giới thiệu",
        key: "referralName",
      },
      {
        label: "Khối người giới thiệu",
        key: "referralBlock",
      },
      {
        label: "Vùng người giới thiệu",
        key: "referralArea",
      },
    ],
    data: attendanceData,
  };
  //
  const handleChange = (update) => {
    setDate(update);
  };

  const handleDetail = (data) => {
    setPopupFormContextVisible(false);
    onOpen();
    setDataDetail(data);
  };

  const handleEdit = (data) => {
    setPopupFormContextVisible(true);
    onOpen();
    setDataEdit(data);
  };

  //
  // const handleDateChange = (e, inputName) => {
  //   const selectedDate = e.target.value;
  //   setDate(e.target.value);
  //   setInputColors((prevColors) => ({
  //     ...prevColors,
  //     [inputName]: selectedDate
  //       ? "var(--color-info-employee)"
  //       : "var(--color-text-unclick)",
  //   }));
  // };

  const { constantData } = useCategories();

  const formatEntries = (entries) =>
    Object.entries(entries || {}).map(([key, value]) => ({ key, value }));

  const saleAreas = useMemo(
    () => formatEntries(constantData?.saleAreas),
    [constantData?.saleAreas]
  );
  const saleBlocks = useMemo(
    () => formatEntries(constantData?.saleBlocks),
    [constantData?.saleBlocks]
  );
  const departments = useMemo(
    () => formatEntries(constantData?.departmentByNumbers),
    [constantData?.departmentByNumbers]
  );
  const supportDepartments = useMemo(
    () => formatEntries(constantData?.supportDepartments),
    [constantData?.supportDepartments]
  );
  const branches = useMemo(
    () => formatEntries(constantData?.branches),
    [constantData?.branches]
  );

  const handleUpdate = async (data) => {
    const toastEdit = toast.loading("Đang sửa dữ liệu ...");
    delete data?.workingDays;
    delete data?.department;
    data = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== "")
    );
    
    const { id, ...dataEdit } = data;
    try {
      await attendanceCall.update(id, dataEdit);
      await refetch();
      onToggle();
      toast.success("Sửa thành công");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Có lỗi xảy ra khi sửa");
    }
    toast.dismiss(toastEdit);
  };

  return (
    <Flex flexDirection={"column"} gap={{ base: "16px" }}>
      <Flex
        backgroundColor="var(--color-main)"
        borderRadius={{ base: "12px" }}
        flexDirection={"column"}
      >
        <Flex
          w={{ base: "90%" }}
          alignItems={"center"}
          gap={{ base: "32px" }}
          p={"16px 0px 12px 16px"}
        >
          <InputGroup
            maxW="506px"
            borderRadius={{ base: "8px" }}
            w={{ base: "40%" }}
          >
            <Input
              placeholder="Nhập MVN, họ tên, sđt..."
              type="text"
              borderRadius={{ base: "8px" }}
              border={{ base: "1px solid var(--color-secondary)" }}
              onChange={onChangeSearch}
            />
            <InputRightElement
              borderTopRightRadius={"8px"}
              borderBottomRightRadius={"8px"}
              pointerEvents="none"
              backgroundImage="var(--color-button)"
            >
              <SearchIcon color="var(--color-main)" />
            </InputRightElement>
          </InputGroup>

          <Flex alignItems={"center"} gap={{ base: "12px" }} flexWrap={{ base: "wrap" }} flex={1}>
            <InputGroup maxW={"255px"}>
              <DatePicker
                selected={date}
                onChange={handleChange}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                isClearable={true}
                placeholderText="Tháng năm"
                className="datePickerStyle"
                locale="vi"
              />
              {!date && (
                <InputRightElement pointerEvents="none" h={"44px"}>
                  <Image src={calendarIcon} />
                </InputRightElement>
              )}
            </InputGroup>
            <SearchableSelect
              isShowSearch={true}
              options={saleAreas}
              initialSelectedOption={{ key: 0, value: "Chọn vùng" }}
              setValue={setSaleArea}
            />
            <SearchableSelect
              isShowSearch={true}
              options={saleBlocks}
              initialSelectedOption={{ key: 0, value: "Chọn khối" }}
              setValue={setSaleBlock}
            />
            <SearchableSelect
              isShowSearch={false}
              options={departments}
              initialSelectedOption={{ key: 0, value: "Loại nhân viên" }}
              setValue={setDepartment}
            />
            <SearchableSelect
              isShowSearch={false}
              options={supportDepartments}
              initialSelectedOption={{ key: 0, value: "Chọn phòng ban" }}
              setValue={setSupportDepartmentsId}
            />
            <SearchableSelect
              isShowSearch={false}
              options={branches}
              initialSelectedOption={{ key: 0, value: "Chọn chi nhánh" }}
              setValue={setBranchesId}
            />
          </Flex>
        </Flex>
        <Box width={"100%"} height={"100%"}>
          <Skeleton isLoaded={!isLoading && !isRefetching}>
            <TableContainer w={"100%"}>
              <Table w={"100%"} variant="unstyled">
                <Thead
                  w={"100%"}
                  h={"41px"}
                  color="white"
                  fontWeight="400"
                  backgroundColor="#F9FAFD"
                >
                  <Tr h={{ base: "41px" }}>
                    {tableData.headers.map((e, index) => {
                      let width = "auto";
                      let textAlign = "auto";
                      if (e.key === "") {
                        width = "2%";
                        textAlign = "center";
                      } else if (e.key === "date") {
                        width = "5%";
                        textAlign = "start";
                      } else if (
                        e.key === "codeEmployee" ||
                        e.key === "fullName" ||
                        e.key === "saleBlock" ||
                        e.key === "saleArea" ||
                        e.key === "supportDepartment"
                      ) {
                        width = "10%";
                        textAlign = "start";
                      } else if (e.key === "jobPosition") {
                        width = "10%";
                        textAlign = "center";
                      } else {
                        width = "10%";
                        textAlign = "end";
                      }
                      return (
                        <Td
                          p={"16px 16px 8px 16px"}
                          key={index}
                          border={"none"}
                          color={"#51607B"}
                          fontSize={{ base: "14px" }}
                          w={width}
                        >
                          <Box textAlign={textAlign}>{e.label}</Box>
                        </Td>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody w={"100%"} h={"100%"}>
                  {tableData.data?.length > 0 ? (
                    tableData.data?.map((e, rowIndex) => {
                      const items = { ...e };
                      delete items.idEmployee;
                      delete items.month;
                      delete items.year;
                      delete items.dataEdit;
                      delete items.department;
                      delete items.export;
                      const keyValues = Object.keys(items);
                      return (
                        <Tr key={rowIndex} h={"72px"}>
                          <Td
                            p={"16px 16px 8px 16px"}
                            w={rowIndex === 0 ? "20px" : "auto"}
                          >
                            <Menu>
                              <MenuButton
                                _hover={{ backgroundColor: "none" }}
                                _active={{ backgroundColor: "none" }}
                                background="none"
                                as={Button}
                              >
                                <AiOutlineMore fontSize={"20px"} />
                              </MenuButton>
                              <MenuList
                                fontSize={{ base: "14px" }}
                                fontWeight={500}
                                lineHeight={"140%"}
                                color={"#293755"}
                              >
                                <MenuItem
                                  gap={"16px"}
                                  fontWeight={500}
                                  fontSize={{ base: "14px" }}
                                  onClick={() => handleDetail(e)}
                                >
                                  <Image src={detail} /> Xem chi tiết
                                </MenuItem>
                                <MenuItem
                                  gap={"16px"}
                                  fontWeight={500}
                                  fontSize={{ base: "14px" }}
                                  onClick={() => handleEdit(e)}
                                >
                                  <Image src={iconEdit} /> Chỉnh sửa
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </Td>
                          {keyValues.map((keyvalue, index) => {
                            let width = "auto";
                            let textAlign = "auto";
                            if (keyValues === "") {
                              width = "2%";
                              textAlign = "center";
                            } else if (keyvalue === "date") {
                              width = "5%";
                              textAlign = "start";
                            } else if (
                              keyvalue === "codeEmployee" ||
                              keyvalue === "fullName" ||
                              keyvalue === "saleBlock" ||
                              keyvalue === "saleArea" ||
                              keyvalue === "supportDepartment"
                            ) {
                              width = "10%";
                              textAlign = "start";
                            } else if (keyvalue === "jobPosition") {
                              width = "10%";
                              textAlign = "center";
                            } else {
                              width = "10%";
                              textAlign = "end";
                            }
                            return (
                              <Td
                                p={"16px 16px 8px 16px"}
                                w={width}
                                key={index}
                              >
                                <Box
                                  fontSize={{ base: "14px" }}
                                  textAlign={textAlign}
                                  fontWeight={500}
                                  color={"#293755"}
                                  h={"140%"}
                                >
                                  {formatTableValue(e[keyvalue], keyvalue)}
                                </Box>
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    })
                  ) : (
                    <Tr>
                      <Td
                        colSpan={tableData.headers.length}
                        textAlign="center"
                        padding={"40px 0"}
                      >
                        Không có dữ liệu
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Skeleton>
          <Box mt={10} mr={5} mb={5} ml={5}>
            <Flex alignItems={"center"} justifyContent={"space-between"}>
              <Text>
                Showing {startEntry} to {endEntry} of {entries} entries
              </Text>
              <HStack spacing={2} justify="flex-end">
                <ReactPaginate
                  className="pagination-custom"
                  pageCount={totalPages}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageChange}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  breakClassName={"ellipsis"}
                  breakLabel={"..."}
                  previousLabel={
                    <Img
                      w={"16px"}
                      minW={"16px"}
                      h={"16px"}
                      src={chevronLeft}
                    />
                  }
                  nextLabel={
                    <Img
                      w={"16px"}
                      minW={"16px"}
                      h={"16px"}
                      src={chevronRight}
                    />
                  }
                  renderOnZeroPageCount={null}
                  forcePage={currentPage - 1}
                />
              </HStack>
            </Flex>
          </Box>
        </Box>
      </Flex>
      {!popupFormContextVisible && (
        <FormJobTitleDetail
          edit={false}
          isOpen={isOpen}
          onClose={onClose}
          dataDetail={dataDetail}
        />
      )}
      {popupFormContextVisible && (
        <FormJobTitleEdit
          edit={true}
          isOpen={isOpen}
          onClose={onClose}
          dataEdit={dataEdit?.dataEdit}
          defaultValues={dataEdit?.dataEdit || {}}
          onSubmit={handleUpdate}
        />
      )}
    </Flex>
  );
};

export default TableJobTitle;
