import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import FormInfoCommission from "../../../components/form/formInfoCommission";
import { commissionCall } from "../../../api/commission";

const InfoStep2 = ({ defaultValues }) => {
  const [data, setData] = useState([]);

  const fetchAdvance = async () => {
    try {
      const getTotalPriceData = await commissionCall.getByLocking(
        defaultValues.id
      );
      if (Array.isArray(getTotalPriceData) && getTotalPriceData.length > 0) {
        const data = getTotalPriceData.map((item) => {
          let commission = [];

          if (
            item?.commission &&
            Array.isArray(item?.commission) &&
            item?.commission.length > 0
          ) {
            commission = item?.commission.map((item) => ({
              interpretation: item?.interpretation,
              employee: item?.employeeObject?.fullName,
              workingDay: item?.workingDay,
              serviceFee: item?.serviceFee,
            }));
          }

          return {
            period: item?.period,
            calculateCommissionRate: item?.calculateCommissionRate,
            success: true,
            commission: commission,
            date: item?.dateStep,
          };
        });

        setData(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  useEffect(() => {
    if (defaultValues?.id) {
      fetchAdvance();
    }
  }, []);

  const [openItems, setOpenItems] = useState({ 1: true });

  const toggleContent = (id) => {
    setOpenItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const onToggleContent = (id) => {
    toggleContent(id);
  };
  return (
    <Box maxW={"545px"} margin={"8px auto 16px"}>
      {data.map((item) => (
        <Box key={item.period}>
          <FormInfoCommission
            openItems={openItems[item.period]}
            id={item.period}
            rate={item.calculateCommissionRate}
            date={item.date}
            data={item.commission}
            toggleContent={onToggleContent}
          />
        </Box>
      ))}
    </Box>
  );
};

export default InfoStep2;
