import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { clientAPI } from "../api/client";
import { NumberOfPerPage } from "../utils/globalVariable";
import { formatNumber } from "../utils/utils";

const NUMBER_NFT_PER_PAGE = NumberOfPerPage.apartment;
const queryKey = "apartment";

async function fetchApartment(currentPage, search, projectId, apartmentTypeId) {
  try {
    const options = {
      limit: NUMBER_NFT_PER_PAGE,
      page: currentPage,
    };

    if (search !== undefined) options.search = search;
    if (projectId) options.projectId = projectId;
    if (apartmentTypeId) options.apartmentTypeId = apartmentTypeId;

    const queryParams = new URLSearchParams(options).toString();
    let data = await clientAPI("get", `/apartment?${queryParams}`);
    const totalPages = Math.ceil(data?.pagination?.total / NUMBER_NFT_PER_PAGE);
    const entries = data?.pagination?.total;
    const startEntry =
      (data?.pagination?.page - 1) * data?.pagination?.limit + 1;
    const endEntry = Math.min(
      data?.pagination?.page * data?.pagination?.limit,
      entries
    );

    const newData = data?.items.map((item, index) => {
      return {
        id: item?._id || "",
        code: item?.code || "-",
        name: item?.name || "-",
        area: item?.area ? `${item?.area}m2` : "-",
        builtUpArea: item?.builtUpArea ? `${item?.builtUpArea}m2` : "-",
        width: item?.width ? `${item?.width}m` : "-",
        length: item?.length ? `${item?.length}m` : "-",
        numberOfBedrooms: item?.numberOfBedrooms || "-",
        numberOfToilets: item?.numberOfToilets || "-",
        direction: item?.direction?.name || "-",
        apartmentType: item?.apartmentType?.name || "-",
        property: item?.property?.name || "-",
        project: item?.project?.name || "-",
        division: item?.division?.name || "-",
        totalValue: item?.totalValue
          ? `${formatNumber(item?.totalValue)} VNĐ`
          : "-",
        priceForCommission: item?.priceForCommission
          ? `${formatNumber(item?.priceForCommission)} VNĐ`
          : "-",
        edit: {
          id: item?._id || "",
          code: item?.code || "",
          name: item?.name || "",
          area: item?.area || "",
          builtUpArea: item?.builtUpArea || "",
          width: item?.width || "",
          length: item?.length || "",
          numberOfBedrooms: item?.numberOfBedrooms || "",
          numberOfToilets: item?.numberOfToilets || "",
          directionId: item?.directionId || "",
          apartmentTypeId: item?.apartmentTypeId || "",
          propertyId: item?.propertyId || "",
          projectId: item?.projectId || "",
          divisionId: item?.divisionId || "",
          totalValue: item?.totalValue || "-",
          priceForCommission: item?.priceForCommission || "-",
        },
        detail: {
          id: item?._id || "",
        },
      };
    });

    return { data: newData, totalPages, entries, startEntry, endEntry };
  } catch (error) {
    console.error("Error fetching checkIn data:", error);
    return { data: [], totalPages: 0 };
  }
}

export function useApartment(search, projectId, apartmentTypeId) {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: [queryKey, currentPage, search, projectId, apartmentTypeId],
    queryFn: () =>
      fetchApartment(currentPage, search, projectId, apartmentTypeId),
    refetchOnWindowFocus: false,
    staleTime: 30000,
    keepPreviousData: true,
    onError: (error) => {
      console.error("Error fetching checkIn data:", error);
    },
  });

  const nextPage = () => {
    if (currentPage < data?.totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return {
    apartmentData: data?.data,
    totalPages: data?.totalPages,
    refetch,
    isLoading,
    isRefetching,
    prevPage,
    nextPage,
    setCurrentPage,
    currentPage,
    entries: data?.entries,
    startEntry: data?.startEntry,
    endEntry: data?.endEntry,
  };
}
