import { Avatar, Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import iconApproved from "../../assets/images/receiveSalary/check-circle.svg";
import iconRejected from "../../assets/images/contract/x-circle.svg";

export const formatTableValue = (value, key, submit, id) => {
  switch (key) {
    case "startTime":
      const timestamp = value;

      const date = new Date(timestamp);

      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
      return <Text>{formattedDate}</Text>;

    case "contact":
      const infos = value?.split("-") || [];
      return (
        <Flex direction={"column"} justifyContent={"left"}>
          {infos?.length &&
            infos?.map((item, index) => <Text key={index}>{item}</Text>)}
        </Flex>
      );
    case "bankInfo":
      const bankInfo = value?.split("-") || [];
      return (
        <Flex direction={"column"} justifyContent={"left"}>
          {bankInfo?.length &&
            bankInfo?.map((item, index) => <Text key={index}>{item}</Text>)}
        </Flex>
      );
    case "identificationNumberInfo":
      const identificationNumberInfo = value?.split("-") || [];
      return (
        <Flex direction={"column"} justifyContent={"left"}>
          {identificationNumberInfo?.length &&
            identificationNumberInfo?.map((item, index) => (
              <Text key={index}>{item}</Text>
            ))}
        </Flex>
      );
    case "avatar":
      return <Avatar src={value} />;
    case "status":
      let content;
      if (value === "NEW") {
        content = (
          <>
            <Button
              bg={"var(--linear-gradient-employee)"}
              color={"var(--color-main)"}
              fontSize={"12px"}
              lineHeight={"17px"}
              // p={"6px 27px 5px 28px"}
              fontWeight={"600"}
              _hover={{
                filter: "brightness(90%)",
              }}
              onClick={() => submit("APPROVED", id)}
              h={"28px"}
              minH={"28px"}
              w={"90px"}
            >
              Duyệt
            </Button>
            <Button
              bg={"var(--color-main)"}
              color={"var(--color-option-employee-hover)"}
              border={"1px solid var(--color-option-employee-hover)"}
              fontSize={"12px"}
              lineHeight={"17px"}
              // p={"6px 19px 5px 19px"}
              fontWeight={"600"}
              _hover={{
                filter: "brightness(90%)",
              }}
              onClick={() => submit("REJECTED", id)}
              h={"28px"}
              minH={"28px"}
              w={"90px"}
            >
              Ko duyệt
            </Button>
          </>
        );
      } else if (value === "APPROVED") {
        content = (
          <>
            <Image src={iconApproved} />
            <Text color={"var(--color-status-employ)"}>Đã được duyệt</Text>
          </>
        );
      } else if (value === "REFUND") {
        content = (
          <>
            <Image src={iconApproved} />
            <Text color={"var(--color-status-employ)"}>Đã hoàn trả</Text>
          </>
        );
      } else {
        content = (
          <>
            <Image src={iconRejected} />
            <Text color={"var(--text-red-employee)"}>Không được phê duyệt</Text>
          </>
        );
      }

      return <Flex gap={"8px"} w={"max-content"}>{content}</Flex>;
    case "reviewer":
      return (
        <Box textAlign={"end"}>
          <Text
            fontSize={"14px"}
            lineHeight={"19.6px"}
            fontWeight={"500"}
            color={"var(--color-info-employee)"}
          >
            {value.name}
          </Text>
          <Text
            fontSize={"12px"}
            lineHeight={"16.8px"}
            fontWeight={"400"}
            color={"var(--color-label-employee)"}
          >
            {value.jobTitle}
          </Text>
        </Box>
      );
    default:
      return <Text>{value}</Text>;
  }
};
