import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import iconButton from "../../assets/images/employee/icon-button.svg";
import TableApartments from "./tableApartments";
import FormNew from "./formApartments/fromNew";
import iconExport from "../../assets/images/product/iconExport.svg";
import iconPlus from "../../assets/images/apartment/plus.svg";
import iconFile from "../../assets/images/apartment/Frame.svg";
import PopupImport from "../../components/popup/PopupImport";
import { useApartment } from "../../hooks/useApartments";
import toast from "react-hot-toast";
import { apartmentCall } from "../../api/apartment";
import { exportFile } from "../../utils/tools";

const columnMappingReverse = {
  "Mã căn hộ": "code",
  "Tên căn hộ": "name",
  "DT đất": "area",
  "DT xây dựng": "builtUpArea",
  "Chiều dài": "length",
  "Chiều rộng": "width",
  "Phòng ngủ": "numberOfBedrooms",
  "Phòng VS": "numberOfToilets",
  Hướng: "directionName",
  "Loại hình": "apartmentTypeName",
  "Tính chất": "propertyName",
  "Dự án": "projectName",
  "Phân khu": "divisionName",
  "Giá trị": "totalValue",
  "Giá tính HH": "priceForCommission",
};

export default function Apartment() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [paramExport, setParamExport] = useState(null);

  const [popupVisible, setPopupVisible] = useState(false);

  const { refetch, isRefetching, entries, apartmentData } = useApartment();

  const handleExport = async () => {
    const checkInColumnMapping = {
      code: "Mã căn hộ",
      name: "Tên căn hộ",
      area: "DT đất",
      builtUpArea: "DT xây dựng",
      length: "Chiều dài",
      width: "Chiều rộng",
      numberOfBedrooms: "Phòng ngủ",
      numberOfToilets: "Phòng VS",
      direction: "Hướng",
      apartmentType: "Loại hình",
      property: "Tính chất",
      project: "Dự án",
      division: "Phân khu",
      totalValue: "Giá trị",
      priceForCommission: "Giá tính HH",
    };
    if (apartmentData?.length) {
      const data = await apartmentCall.export(entries, paramExport);
      if (data) {
        exportFile(data, checkInColumnMapping, "bang_quan_ly_can_ho.xlsx");
        toast.success("Download file thành công");
      } else {
        toast.error("Không có dữ liệu");
      }
    } else toast.error("Không có dữ liệu");
  };

  const handleExportSample = () => {
    const columnMapping = {
      code: "Mã căn hộ",
      name: "Tên căn hộ",
      area: "DT đất",
      builtUpArea: "DT xây dựng",
      length: "Chiều dài",
      width: "Chiều rộng",
      numberOfBedrooms: "Phòng ngủ",
      numberOfToilets: "Phòng VS",
      totalValue: "Giá trị",
      priceForCommission: "Giá tính HH",
      directionName: "Hướng",
      apartmentTypeName: "Loại hình",
      propertyName: "Tính chất",
      divisionName: "Phân khu",
      projectName: "Dự án",
    };
    const data = [
      {
        code: "AP001",
        name: "Căn hộ APT",
        area: 120,
        builtUpArea: 100,
        length: 10,
        width: 12,
        numberOfBedrooms: 3,
        numberOfToilets: 2,
        totalValue: 2000000000,
        priceForCommission: 50000000,
        directionName: "Hướng nam",
        apartmentTypeName: "Hạng sang",
        propertyName: "Loại tính chất 1",
        divisionName: "Phân khu 2",
        projectName: "Dự án B",
      },
      {
        code: "CH2",
        name: "Căn hộ 22",
        area: 12,
        builtUpArea: 23,
        length: 10,
        width: 5,
        numberOfBedrooms: 2,
        numberOfToilets: 1,
        totalValue: 1000000000,
        priceForCommission: 10000000,
        directionName: "Hướng nam",
        apartmentTypeName: "Bình dân",
        propertyName: "Loại tính chất 2",
        divisionName: "Phân khu 2",
        projectName: "Dự án C",
      },
    ];
    try {
      exportFile(data, columnMapping, "bang_quan_ly_san_pham.xlsx");
      toast.success("Download file thành công");
    } catch (error) {
      toast.error("Export lỗi");
      console.log(error);
    }
  };

  const onSubmitAdd = async (data) => {
    const toastCreate = toast.loading("Đang thêm căn hộ ...");
    try {
      let filteredData = Object.fromEntries(
        Object.entries(data).filter(
          ([_, value]) => value !== "" && value !== undefined && value !== null
        )
      );
      await apartmentCall.create(filteredData);

      await refetch();
      onClose();
      toast.success("Thêm thành công");
    } catch (error) {
      console.log({ error });
      toast.dismiss(toastCreate);
    }
    toast.dismiss(toastCreate);
  };

  return (
    <Box
      w={{ base: "100%" }}
      backgroundColor="var(--color-backgroundmain)"
      p={{ base: "24px 16px 16px" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={{ base: "74px" }}>
          <Text fontSize={{ base: "20px" }} fontWeight={600}>
            Quản lý sản phẩm
          </Text>
        </Flex>
        <Flex alignItems={"center"} gap={"16px"}>
          <Button
            w={"176px"}
            h={"44px"}
            display={"flex"}
            alignItems={"center"}
            p={"10px 16px 10px 12px"}
            gap={"8px"}
            backgroundImage="var(--linear-gradient-excel)"
            _hover={{
              filter: "brightness(90%)",
            }}
            onClick={handleExport}
          >
            <Image src={iconExport} />
            <Text
              fontSize={{ base: "15px" }}
              fontWeight={600}
              color="var(--color-main)"
            >
              Xuất exel
            </Text>
          </Button>
          <Menu>
            <MenuButton
              w={"180px"}
              h={"44px"}
              as={Button}
              backgroundImage="var(--color-button)"
              _hover={{
                filter: "brightness(90%)",
              }}
              _active={{
                filter: "brightness(90%)",
              }}
            >
              <Flex alignItems={"center"} p={"10px 0px 10px 0px"} gap={"8px"}>
                <Image src={iconButton} />
                <Text
                  fontSize={{ base: "15px" }}
                  fontWeight={600}
                  color="var(--color-main)"
                >
                  Sản phẩm mới
                </Text>
              </Flex>
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  onOpen();
                }}
                gap={"16px"}
                p={"10px 16px"}
                borderBottom={"0.5px solid var(--border-input-employee)"}
                _hover={{
                  filter: "brightness(96%)",
                }}
              >
                <Image src={iconPlus} /> Tạo sản phẩm mới
              </MenuItem>
              <MenuItem
                onClick={() => setPopupVisible(true)}
                gap={"16px"}
                p={"10px 16px"}
                _hover={{
                  filter: "brightness(96%)",
                }}
              >
                <Image src={iconFile} /> Tải file lên
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
      <Box mt={{ base: "23px" }}>
        <TableApartments
          isRefetchingWithCreate={isRefetching}
          paramData={setParamExport}
        />
      </Box>
      <FormNew
        isOpen={isOpen}
        onClose={onClose}
        isEdit={false}
        onSubmit={onSubmitAdd}
      />
      {popupVisible && (
        <PopupImport
          isOpen={popupVisible}
          onClose={() => setPopupVisible(false)}
          refetchCheckInData={refetch}
          columnMappingReverse={columnMappingReverse}
          handleExport={handleExportSample}
          titleAdd="Thêm danh sách căn hộ"
          inputAdd="Nhập danh sách căn hộ"
          type={"apartment"}
        />
      )}
    </Box>
  );
}
