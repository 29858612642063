import toast from "react-hot-toast";
import { formatNumber } from "../../utils/utils";
import { formatDateDb } from "../../utils/tools";

const { clientAPI } = require("../client");
export const saleOpeningEventCall = {
  create: async (data) => {
    try {
      const response = await clientAPI("post", "/saleOpeningEvent", data);
      return response;
    } catch (error) {
      console.error("Create error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  update: async (id, data) => {
    try {
      const response = await clientAPI("put", `/saleOpeningEvent/${id}`, data);
      return response;
    } catch (error) {
      console.error("Update error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  approve: async (id, data) => {
    try {
      const response = await clientAPI(
        "put",
        `/saleOpeningEvent/approve/${id}`,
        data
      );
      return response;
    } catch (error) {
      console.error("Update error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  delete: async (id) => {
    try {
      const response = await clientAPI("delete", `/saleOpeningEvent/${id}`);
      return response;
    } catch (error) {
      console.error("Delete error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  export: async (entries, param) => {
    try {
      const options = {
        limit: entries,
        page: 1,
      };

      if (param.search) options.search = param.search;
      if (param.startTime) options.startTime = param.startTime;
      if (param.endTime) options.endTime = param.endTime;
      if (param.projectId) options.projectId = param.projectId;

      const queryParams = new URLSearchParams(options).toString();
      const response = await clientAPI(
        "get",
        `/saleOpeningEvent?${queryParams}`
      );

      const dataExport = response?.items.map((item, index) => {
        return {
          code: item?.code || "",
          name: item?.name || "",
          project: item?.project?.name || "",
          product: item?.apartmentSaleOpeningEventLink?.length || "",
          startTime: item?.startTime ? formatDateDb(item?.startTime) : "",
          endTime: item?.endTime ? formatDateDb(item?.endTime) : "",
          price: item?.price ? `${formatNumber(item?.price)} VNĐ` : "",
          creator: item?.creator?.fullName || "-",
          createdAt: item?.createdAt ? formatDateDb(item?.createdAt) : "",
        };
      });

      return dataExport;
    } catch (error) {
      console.error("get list error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  bulkAdd: async (data) => {
    try {
      const response = await clientAPI("post", "/saleOpeningEvent/bulk/", data);
      return response;
    } catch (error) {
      console.error("Create error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
};
