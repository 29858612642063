import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { clientAPI } from "../api/client";
import { NumberOfPerPage } from "../utils/globalVariable";
import { formatDateDb } from "../utils/tools";
import { formatDateForInput } from "../utils/utils";

const NUMBER_NFT_PER_PAGE = NumberOfPerPage.contract;
const queryKey = "contract";

async function fetchContract(currentPage, search, projectId, creatorId) {
  try {
    const options = {
      limit: NUMBER_NFT_PER_PAGE,
      page: currentPage,
    };

    if (search !== undefined) options.search = search;
    if (projectId) options.projectId = projectId;
    if (creatorId) options.creatorId = creatorId;

    const queryParams = new URLSearchParams(options).toString();
    let data = await clientAPI("get", `/salesContract?${queryParams}`);
    const totalPages = Math.ceil(data?.pagination?.total / NUMBER_NFT_PER_PAGE);
    const entries = data?.pagination?.total;
    const startEntry =
      (data?.pagination?.page - 1) * data?.pagination?.limit + 1;
    const endEntry = Math.min(
      data?.pagination?.page * data?.pagination?.limit,
      entries
    );

    const newData = data?.items.map((item, index) => { console.log(item?.approverDate)
      return {
        id: item?._id || "",
        status: item?.status || "-",
        code: item?.code || "-",
        name: item?.locking?.projectObject?.name || "-",
        apartment: item?.locking?.apartment?.code || "-",
        customer: item?.locking?.customerObject?.name || "-",
        bookingTime: item?.locking?.bookingTime
          ? formatDateDb(item?.locking?.bookingTime)
          : "-",
        invoice: item?.invoice || "-",
        creator: item?.creatorObject?.fullName || "-",
        createdAt: item?.createdAt ? formatDateDb(item?.createdAt) : "-",
        edit: {
          id: item?._id || "",
          name: item?.name || "",
          code: item?.code || "",
          invoice: item?.invoice || "",
          creator: item?.creatorObject?.fullName || "",
          project: item?.locking?.projectObject?.name || "",
          customer: item?.locking?.customer?.name || "",
          time: item?.time
          ? formatDateForInput(item?.time)
          : "",
          createdAt: item?.createdAt
            ? formatDateDb(item?.createdAt)
            : "",
          apartment: item?.locking?.apartment?.name || "",
          images: item?.locking?.images || "",
          note: item?.locking?.note || "",
        },
        detail: {
          id: item?._id || "",
          note: item?.locking?.note || "",
          status: item?.status || "",
          creator: item?.creatorObject?.fullName || "",
          createdAt: item?.createdAt ? formatDateDb(item?.createdAt) : "-",
          project: item?.locking?.projectObject?.name || "",
          customer: item?.locking?.customerObject?.name || "",
          price: item?.locking?.price || "",
          bookingTime: item?.locking?.bookingTime
            ? formatDateDb(item?.locking?.bookingTime)
            : "",
          approverDate: item?.approverDate
            ? formatDateDb(item?.approverDate)
            : "",
          apartment: item?.locking?.apartmentObject?.name || "",
          apartments: item?.locking?.apartment,
          approver: item?.approver?.fullName || "",
          time: item?.time || "",
          invoice: item?.invoice || "",
          images: item?.locking?.images,
        },
      };
    });

    return { data: newData, totalPages, entries, startEntry, endEntry };
  } catch (error) {
    console.error("Error fetching checkIn data:", error);
    return { data: [], totalPages: 0 };
  }
}

export function useContract(search, projectId, creatorId) {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: [queryKey, currentPage, search, projectId, creatorId],
    queryFn: () => fetchContract(currentPage, search, projectId, creatorId),
    refetchOnWindowFocus: false,
    staleTime: 30000,
    keepPreviousData: true,
    onError: (error) => {
      console.error("Error fetching checkIn data:", error);
    },
  });

  const nextPage = () => {
    if (currentPage < data?.totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return {
    contractData: data?.data,
    totalPages: data?.totalPages,
    refetch,
    isLoading,
    isRefetching,
    prevPage,
    nextPage,
    setCurrentPage,
    currentPage,
    entries: data?.entries,
    startEntry: data?.startEntry,
    endEntry: data?.endEntry,
  };
}
