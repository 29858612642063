import { Avatar, Flex, Text } from "@chakra-ui/react";

export const formatTableValue = (value, key) => {
  switch (key) {
    case "startTime":
      const timestamp = value;

      const date = new Date(timestamp);

      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
      return <Text>{formattedDate}</Text>;

    case "contact":
      const infos = value?.split("-") || [];
      return (
        <Flex direction={"column"} justifyContent={"left"}>
          {infos?.length &&
            infos?.map((item, index) => <Text key={index}>{item}</Text>)}
        </Flex>
      );
    // case "bankInfo":
    //   const bankInfo = value?.split("-") || [];
    //   return (
    //     <Flex direction={"column"} justifyContent={"left"}>
    //       {bankInfo?.length &&
    //         bankInfo?.map((item, index) => <Text key={index}>{item}</Text>)}
    //     </Flex>
    //   );
    // case "identificationNumberInfo":
    //   const identificationNumberInfo = value?.split("-") || [];
    //   return (
    //     <Flex direction={"column"} justifyContent={"left"}>
    //       {identificationNumberInfo?.length &&
    //         identificationNumberInfo?.map((item, index) => (
    //           <Text key={index}>{item}</Text>
    //         ))}
    //     </Flex>
    //   );
    case "avatar":
      return <Avatar src={value} />;
    // case "department":
    //   let departmentName = "-";
    //   if (value == 0) {
    //     departmentName = "Khối hỗ trợ kinh doanh";
    //   } else if (value == 1) {
    //     departmentName = "Khối kinh doanh";
    //   } else if (value == 2) {
    //     departmentName = "Khối hỗ trợ giám đốc";
    //   }
    //   return <Text>{departmentName}</Text>;
    case "status":
      const status = !value ? "Đang làm việc" : "Dừng làm việc";
      return (
        <Text
          color={
            value ? "var(--text-red-employee)" : "var(--color-status-employ)"
          }
        >
          {status}
        </Text>
      );
    case "bankInfo":
      return (
        <Flex direction={"column"} justifyContent={"left"}>
          {value &&
            value?.map((item, index) => (
              <Text key={index}>
                {typeof item === "undefined" ? "-" : item}
              </Text>
            ))}
        </Flex>
      );
    default:
      return <Text>{value}</Text>;
  }
};
