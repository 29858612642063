import toast from "react-hot-toast";
import { formatNumber } from "../../utils/utils";

const { clientAPI } = require("../client");
export const apartmentCall = {
  create: async (data) => {
    try {
      const response = await clientAPI("post", "/apartment", data);
      return response;
    } catch (error) {
      console.error("Create error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  update: async (id, data) => {
    try {
      const response = await clientAPI("put", `/apartment/${id}`, data);
      return response;
    } catch (error) {
      console.error("Update error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  delete: async (id) => {
    try {
      const response = await clientAPI("delete", `/apartment/${id}`);
      return response;
    } catch (error) {
      console.error("Delete error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  export: async (entries, param) => {
    try {
      const options = {
        limit: entries,
        page: 1,
      };

      if (param.search) options.search = param.search;
      if (param.apartmentTypeId)
        options.apartmentTypeId = param.apartmentTypeId;
      if (param.projectId) options.projectId = param.projectId;

      const queryParams = new URLSearchParams(options).toString();
      const response = await clientAPI("get", `/apartment?${queryParams}`);

      const dataExport = response?.items.map((item, index) => {
        return {
          code: item?.code || "-",
          name: item?.name || "",
          area: item?.area || "",
          builtUpArea: item?.builtUpArea || "",
          width: item?.width || "",
          length: item?.length || "",
          numberOfBedrooms: item?.numberOfBedrooms || "",
          numberOfToilets: item?.numberOfToilets || "",
          direction: item?.direction?.name || "",
          apartmentType: item?.apartmentType?.name || "",
          property: item?.property?.name || "",
          project: item?.project?.name || "",
          division: item?.division?.name || "",
          totalValue: item?.totalValue || "",
          priceForCommission: item?.priceForCommission || "",
        };
      });

      return dataExport;
    } catch (error) {
      console.error("get list error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  bulkAdd: async (data) => {
    try {
      const response = await clientAPI("post", "/apartment/bulkCreate/", data);
      return response;
    } catch (error) {
      console.error("Create error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
};
