import { Route, Routes, useNavigate } from "react-router-dom";
import DefaultLayout from "./layouts";
import { routes } from "./routes";
import { Fragment, useCallback, useEffect, useState } from "react";
import SignIn from "./pages/signIn";
import { useDispatch, useSelector } from "react-redux";
import { messaging, requestForToken } from "./services/firebase";
import { setMessage, setToken } from "./redux/slices/fcmSlice";
import { onMessage } from "firebase/messaging";
import toast from "react-hot-toast";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.login?.currentUser);
  const roles = user?.roles || [];

  useEffect(() => {
    if (!user?.accessToken) {
      navigate("/login");
    }
  }, [dispatch, navigate, user]);

  const hasPermission = useCallback(
    (routeRoles) => {
      if (!routeRoles || routeRoles.length === 0 || roles?.includes("ALL")) {
        return true;
      }
      return routeRoles.some((role) => roles?.includes(role));
    },
    [roles]
  );

  const filteredRoutes = routes.filter((route) => hasPermission(route.role));

  useEffect(() => {
    const getTokenAndListenForMessages = async () => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === "denied") {
          console.log("Cần cấp quyền nhận thông báo.");
          return;
        }

        if (permission === "granted") {
          const token = await requestForToken();
          if (token) {
            dispatch(setToken(token)); // Lưu token vào Redux
          }
        }
      } catch (err) {
        console.log("Lỗi trong getTokenAndListenForMessages:", err);
      }
    };

    // Save token
    getTokenAndListenForMessages();

    // listen for messages
    const employeeId = user?.user?._id;
    const handleForegroundMessage = (payload) => {
      if (!employeeId) {
        return;
      }
      console.log("Thông báo foreground nhận được:", payload);
      if (payload) {
        toast("Bạn có thông báo mới", { icon: "👏" });
        const data = { ...payload, employeeId };
        dispatch(setMessage(data));
      }
    };

    onMessage(messaging, handleForegroundMessage);
  }, [user]);

  return (
    <Routes>
      {user?.accessToken ? (
        filteredRoutes.map((route, index) => {
          const Layout = route.isShowHeader ? DefaultLayout : Fragment;
          const Page = route.page;

          return (
            <Route
              key={index}
              path={route.path}
              element={
                <Layout>
                  <Page />
                </Layout>
              }
            />
          );
        })
      ) : (
        <Route path="*" element={<SignIn />} />
      )}
    </Routes>
  );
}

export default App;
