import { Button, Flex, Image, Text } from "@chakra-ui/react";
import React, { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FormAdvanceRate from "../../../components/form/formAdvanceRate";
import iconPlus from "../../../assets/images/personnelCategories/plus.svg";
import { commissionCall } from "../../../api/commission";
import toast from "react-hot-toast";

const DetailStep2 = memo(({ isOpen, defaultValues }) => {
  const { reset } = useForm({
    defaultValues: defaultValues || {},
  });

  const [openItems, setOpenItems] = useState({ 1: true });
  const [advanceRate, setAdvanceRate] = useState([]);

  const fetchAdvance = async () => {
    try {
      const getTotalPriceData = await commissionCall.getByLocking(
        defaultValues.id
      );
      if (
        Array.isArray(getTotalPriceData) &&
        getTotalPriceData.length > 0
      ) {
        const data = getTotalPriceData.map((item) => {
          let commission = [];

          if (
            item?.commission &&
            Array.isArray(item?.commission) &&
            item?.commission.length > 0
          ) {
            commission = item?.commission.map((item) => ({
              interpretation: item?.interpretation,
              employee: item?.employeeObject?.fullName,
              workingDay: item?.workingDay,
              serviceFee: item?.serviceFee,
            }));
          }

          return {
            period: item?.period,
            calculateCommissionRate: item?.calculateCommissionRate,
            success: true,
            commission: commission,
            date: item?.dateStep,
          };
        });

        setAdvanceRate(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (defaultValues?.id) {
      fetchAdvance();
    }
  }, []);

  const handleChange = (period, field, value) => {
    setAdvanceRate(
      advanceRate.map((emp) =>
        emp.period === period ? { ...emp, [field]: value } : emp
      )
    );
  };

  const checkRate = () => {
    const totalRate = advanceRate.reduce(
      (total, item) => total + Number(item.calculateCommissionRate),
      0
    );

    if (totalRate > 100) {
      return false;
    }

    return true;
  };

  const onToggleContent = (period) => {
    setOpenItems((prev) => ({
      ...prev,
      [period]: !prev[period],
    }));
  };

  const onHandlePay = async (rate) => {
    if (defaultValues?.id) {
      const toastCreate = toast.loading("Đang tính toán ...");
      try {
        const data = {
          lockingId: defaultValues?.id,
          calculateCommissionRate: rate,
        }
        await commissionCall.create(data);
        fetchAdvance();
        toast.success("Tính toán thành công");
      } catch (error) {
        console.log({ error });
        toast.dismiss(toastCreate);
      }
      toast.dismiss(toastCreate);
    }
  };

  useEffect(() => {
    reset(defaultValues);
  }, [isOpen, reset, defaultValues]);

  const addAdvenceRate = () => {
    const foundlast = advanceRate.findLast((element) => element);

    setAdvanceRate([
      ...advanceRate,
      {
        period: foundlast?.period ? foundlast?.period + 1 : "",
        calculateCommissionRate: 0,
        success: false,
        commission: [],
        date: null,
      },
    ]);
  };

  return (
    <Flex flexDirection={"column"} gap={"0px"}>
      {advanceRate.map((adv, index) => (
        <FormAdvanceRate
          period={adv.period}
          index={index}
          key={index}
          calculateCommissionRate={adv?.calculateCommissionRate}
          success={adv?.success}
          commission={adv?.commission}
          date={adv?.date}
          checkRate={checkRate}
          onHandlePay={onHandlePay}
          onChangeBonus={(e) => {
            handleChange(adv.period, "calculateCommissionRate", e);
          }}
          openItems={openItems[adv.period]}
          toggleContent={onToggleContent}
        />
      ))}

      {advanceRate.filter((adv) => adv.success === false).length <= 0 && (
        <Button
          w={"max-content"}
          mt={"16px"}
          display={"flex"}
          alignItems={"center"}
          p={"0px 12px"}
          gap={"10px"}
          borderRadius={"8px"}
          border={"1px solid var(--color-option-employee-hover)"}
          backgroundImage="var(--color-main)"
          bg={"var(--color-main)"}
          padding={"8px 12px"}
          h={"32px"}
          _hover={{
            filter: "brightness(95%)",
          }}
          onClick={() => addAdvenceRate()}
        >
          <Image src={iconPlus} />
          <Text
            fontSize={{ base: "13px" }}
            lineHeight={"16px"}
            fontWeight={600}
            color="var(--color-option-employee-hover)"
          >
            Thêm đợt thanh toán mới
          </Text>
        </Button>
      )}
    </Flex>
  );
});

export default DetailStep2;
