import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Img,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import iconAdd from "../../assets/images/projectManagement/icon-add-project.svg";
import chevronLeft from "../../assets/images/employee/chevron-left.svg";
import chevronRight from "../../assets/images/employee/chevron-right.svg";
import { AiOutlineMore } from "react-icons/ai";
import iconDetail from "../../assets/images/projectManagement/icon-detail.svg";
import iconEdit from "../../assets/images/projectManagement/icon-edit.svg";
import iconUnlock from "../../assets/images/projectManagement/icon-unlock.svg";
import iconClock from "../../assets/images/projectManagement/lock.svg";
import { formatTableValue } from "./formatTable";
import PopupProjectManagementAdd from "../../components/popup/popupProjectManagementAdd";
import PopupProjectManagementTitle from "../../components/popup/popupProjectManagementTitle";
import FileDownload from "../../components/popup/popupFileProject";
import { useProjectManagement } from "../../hooks/projectManagement";
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import { commonCall } from "../../api/common";
import { projectCall } from "../../api/project";
import { projectManagerCall } from "../../api/projectManager";
import { projectDocumentCall } from "../../api/projectDocument";
import { projectImageCall } from "../../api/projectImage";

const ProjectManagement = () => {
  const {
    isOpen: isParentOpen,
    onOpen: onOpenParent,
    onClose: onCloseParent,
    onToggle: onToggleParent,
  } = useDisclosure();
  const {
    isOpen: isOpenTitle,
    onOpen: onOpenTitle,
    onClose: onCloseTitle,
    onToggle: onToggleTitle,
  } = useDisclosure();
  const {
    isOpen: isOpenDownloadFile,
    onOpen: onOpenDownloadFile,
    onClose: onCloseDownloadFile,
    onToggle: onToggleDownloadFile,
  } = useDisclosure();

  const {
    projectManagementData,
    totalPages,
    refetch,
    isLoading,
    isRefetching,
    setCurrentPage,
    currentPage,
    entries,
    startEntry,
    endEntry,
  } = useProjectManagement();

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    refetch();
  };

  const [dataEdit, setDataEdit] = useState(null);
  const [fileDownload, setFileDownload] = useState([]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const historyTableData = {
    headers: [
      {
        label: "",
        key: "",
      },
      {
        label: "STT",
        key: "",
      },
      {
        label: "Ảnh",
        key: "image",
      },
      {
        label: "Dự án",
        key: "nameproject",
      },
      {
        label: "Chủ đầu tư",
        key: "investor",
      },
      {
        label: "Phân loại",
        key: "projectType",
      },
      {
        label: "Địa chỉ",
        key: "address",
      },
      {
        label: "Thành phố",
        key: "city",
      },
      {
        label: "Tài liệu",
        key: "file",
      },
    ],
    data: projectManagementData,
  };

  const onSetFile = (file, event) => {
    onOpenDownloadFile();
    setFileDownload({
      file: file,
      // name: event?.nameproject?.name,
      name: event?.detail?.file?.name,
    });
  };

  const onDownLoad = () => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = fileDownload?.file;
    link.click();
    if (document.body.contains(link)) {
      document.body.removeChild(link);
    }
  };

  const onAdd = (e) => {
    onOpenParent();
    setDataEdit(null);
  };

  const onDetail = (e) => {
    setDataEdit(e?.detail);
    onOpenTitle();
  };

  const onEdit = (e) => {
    setDataEdit(e?.edit);
    onOpenParent();
  };

  const onCloseEdit = () => {
    setDataEdit(null);
    onCloseParent();
  };

  const updateStatus = async (e) => {
    const toastCreate = toast.loading("Đang cập nhập ...");
    try {
      await projectCall.updateStatus({
        id: e?.id,
      });

      await refetch();
      onToggleParent();
      onCloseTitle();
      onCloseEdit();
      toast.success("Thêm thành công");
    } catch (error) {
      console.log({ error });
      toast.dismiss(toastCreate);
    }
    toast.dismiss(toastCreate);
  };

  const onSubmitAdd = async (data) => {
    const toastCreate = toast.loading("Đang thêm dự án ...");

    try {
      let filteredData = Object.fromEntries(
        Object.entries(data).filter(
          ([_, value]) => value !== "" && value !== undefined && value !== null
        )
      );

      const {
        employeeProject,
        fileProject,
        honor,
        imageHonor,
        project,
        imageProject,
        ...filteredDatas
      } = filteredData;

      const dataCreateProject = await projectCall.create({
        ...filteredDatas,
        status: "OPEN",
      });

      if (dataCreateProject?._id) {
        // if(true){

        const id = dataCreateProject._id;

        // filter add projectManager
        let createDataEmployeeManager = [];
        if (employeeProject) {
          createDataEmployeeManager = employeeProject.map((item) => {
            if (item?.id) {
              delete item?.id;
            }
            item.projectId = id;
            return item;
          });
        }

        if (createDataEmployeeManager.length > 0) {
          await projectManagerCall.createMany(createDataEmployeeManager);
        }

        // Filter add projectDocument
        if (fileProject) {
          const { filePath } = await commonCall.uploadFile(
            fileProject,
            "fileProject"
          );
          if (filePath) {
            await projectDocumentCall.create({
              projectId: id,
              path: filePath,
              name: fileProject?.name || "",
            });
          }
        }

        // Filter add image project
        if (honor) {
          const { filePath } = await commonCall.uploadAvatar(
            honor,
            "imageProject"
          );
          if (filePath) {
            await projectImageCall.create({
              projectId: id,
              path: filePath,
              type: "HONOR",
            });
          }
        }

        if (project) {
          const { filePath } = await commonCall.uploadAvatar(
            project,
            "imageProject"
          );
          if (filePath) {
            await projectImageCall.create({
              projectId: id,
              path: filePath,
              type: "AVATAR",
            });
          }
        }
      }

      await refetch();
      onToggleParent();
      onCloseTitle();
      onCloseEdit();
      toast.success("Thêm thành công");
    } catch (error) {
      console.log({ error });
      toast.dismiss(toastCreate);
    }
    toast.dismiss(toastCreate);
  };

  const onSubmitEdit = async (data) => {
    const toastCreate = toast.loading("Đang sửa dự án ...");

    try {
      let filteredData = Object.fromEntries(
        Object.entries(data).filter(
          ([_, value]) => value !== "" && value !== undefined && value !== null
        )
      );

      delete filteredData?.nameProject;

      delete filteredData?.dataProjectManagers;

      delete filteredData?.file;

      const {
        id,
        employeeProject,
        fileProject,
        honor,
        imageHonor,
        project,
        imageProject,
        ...filteredDatas
      } = filteredData;

      const dataCreateProject = await projectCall.update(id, {
        ...filteredDatas,
      });

      if (dataCreateProject?._id) {
        // if(true){

        const id = dataCreateProject._id;

        // filter add projectManager
        let createDataEmployeeManager = [];
        if (employeeProject) {
          createDataEmployeeManager = employeeProject.map((item) => {
            item.projectId = id;
            item.id = item.id.toString();
            return item;
          });
        }

        if (createDataEmployeeManager.length > 0) {
          await projectManagerCall.updateMany(createDataEmployeeManager);
        }

        // Filter add projectDocument
        if (fileProject) {
          const { filePath } = await commonCall.uploadFile(
            fileProject,
            "fileProject"
          );
          if (filePath) {
            console.log(filePath);
            await projectDocumentCall.updateByProjectId({
              projectId: id,
              path: filePath,
              name: fileProject?.name || "",
            });
          }
        }

        // Filter add image project
        if (honor) {
          const { filePath } = await commonCall.uploadAvatar(
            honor,
            "imageProject"
          );
          if (filePath) {
            await projectImageCall.updateByProjectId({
              projectId: id,
              path: filePath,
              type: "HONOR",
            });
          }
        }

        if (project) {
          const { filePath } = await commonCall.uploadAvatar(
            project,
            "imageProject"
          );
          if (filePath) {
            await projectImageCall.updateByProjectId({
              projectId: id,
              path: filePath,
              type: "AVATAR",
            });
          }
        }
      }

      await refetch();
      onToggleTitle();
      onCloseTitle();
      onCloseEdit();
      toast.success("Sửa thành công");
    } catch (error) {
      console.log({ error });
      toast.dismiss(toastCreate);
    }
    toast.dismiss(toastCreate);
  };

  return (
    <Box
      minH="calc(100vh - 80px)"
      w={{ base: "100%" }}
      backgroundColor="var(--color-backgroundmain)"
      p={{ base: "24px 16px 16px 15px" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={{ base: "74px" }}>
          <Text fontSize={{ base: "20px" }} fontWeight={600}>
            Quản lý dự án
          </Text>
        </Flex>

        <Flex alignItems={"center"} gap={"16px"}>
          <Button
            display={"flex"}
            alignItems={"center"}
            p={"10px 16px 10px 12px"}
            gap={"8px"}
            backgroundImage="var(--color-button)"
            _hover={{
              backgroundImage: "var(--color-button)",
            }}
            onClick={() => onAdd()}
          >
            <Image src={iconAdd} />
            <Text
              fontSize={{ base: "15px" }}
              fontWeight={600}
              color="var(--color-main)"
            >
              Thêm dự án
            </Text>
          </Button>
        </Flex>
      </Flex>
      <Box mt={{ base: "23px" }}>
        <Flex flexDirection={"column"} gap={{ base: "16px" }}>
          <Flex
            minHeight="calc(100vh - 167px)"
            backgroundColor="var(--color-main)"
            borderRadius={{ base: "12px" }}
            flexDirection={"column"}
          >
            <Flex
              alignItems={"center"}
              gap={{ base: "32px" }}
              p={"29px 0px 40px 16px"}
            >
              <Text
                fontSize={{ base: "16px" }}
                fontWeight={600}
                lineHeight={"normal"}
                color="var(--color-black)"
              >
                Danh sách dự án
              </Text>
            </Flex>
            <Flex
              flexDirection={"column"}
              justifyContent={"space-between"}
              width={"100%"}
              minH="calc(100vh - 235px)"
            >
              <Skeleton isLoaded={!isLoading && !isRefetching}>
                <TableContainer w={"100%"} pb={"32px"}>
                  <Table w={"100%"} variant="unstyled">
                    <Thead
                      w={"100%"}
                      h={"41px"}
                      color="white"
                      fontWeight="400"
                      borderBottom={"1px solid var(--color-boder)"}
                    >
                      <Tr h={{ base: "41px" }}>
                        {historyTableData.headers.map((e, index) => {
                          let width = "auto";
                          let textAlign = "auto";
                          if (e.key === "") {
                            width = "3%";
                            textAlign = "center";
                          } else if (e.key === "id") {
                            width = "5%";
                            textAlign = "center";
                          } else if (e.key === "image") {
                            width = "15%";
                            textAlign = "start";
                          } else if (e.key === "nameproject") {
                            width = "15%";
                            textAlign = "start";
                          } else if (e.key === "investor") {
                            width = "10%";
                            textAlign = "start";
                          } else if (e.key === "projectType") {
                            width = "15%";
                            textAlign = "start";
                          } else if (e.key === "address") {
                            width = "20%";
                            textAlign = "end";
                          } else {
                            width = "10%";
                            textAlign = "end";
                          }
                          return (
                            <Td
                              p={"16px 16px 12px 16px"}
                              key={index}
                              border={"none"}
                              color={"#51607B"}
                              fontSize={{ base: "14px" }}
                              w={width}
                            >
                              <Box textAlign={textAlign}>{e.label}</Box>
                            </Td>
                          );
                        })}
                      </Tr>
                    </Thead>
                    <Tbody w={"100%"} h={"100%"}>
                      {historyTableData.data?.length > 0 ? (
                        historyTableData.data?.map((e, rowIndex) => {
                          const items = { ...e };
                          delete items.id;
                          delete items.status;
                          delete items.edit;
                          delete items.detail;
                          const keyValues = Object.keys(items);
                          return (
                            <Tr
                              w={"100%"}
                              key={rowIndex}
                              h={"86px"}
                              backgroundColor={
                                rowIndex % 2 === 0
                                  ? "#transparent"
                                  : "var(--fill-avatar)"
                              }
                            >
                              <Td
                                p={"16px 16px 8px 16px"}
                                verticalAlign={"top"}
                              >
                                <Menu>
                                  <MenuButton
                                    _hover={{ backgroundColor: "none" }}
                                    _active={{ backgroundColor: "none" }}
                                    background="none"
                                    as={Button}
                                    alignItems={"unset"}
                                  >
                                    <AiOutlineMore fontSize={"20px"} />
                                  </MenuButton>
                                  <MenuList>
                                    <MenuItem
                                      alignItems={"center"}
                                      display={"flex"}
                                      gap={"16px"}
                                      onClick={() => onDetail(e)}
                                    >
                                      <Image src={iconDetail} />
                                      <Text
                                        fontSize={{ base: "14px" }}
                                        fontWeight={500}
                                        lineHeight={"140%"}
                                        color="var(--color-option-employee)"
                                      >
                                        Xem chi tiết
                                      </Text>
                                    </MenuItem>
                                    <MenuItem
                                      alignItems={"center"}
                                      display={"flex"}
                                      gap={"16px"}
                                      onClick={() => onEdit(e)}
                                    >
                                      <Image src={iconEdit} />
                                      <Text
                                        fontSize={{ base: "14px" }}
                                        fontWeight={500}
                                        lineHeight={"140%"}
                                        color="var(--color-option-employee)"
                                      >
                                        Chỉnh sửa
                                      </Text>
                                    </MenuItem>
                                    {e?.status === "OPEN" && (
                                      <MenuItem
                                        alignItems={"center"}
                                        display={"flex"}
                                        gap={"16px"}
                                        onClick={() => updateStatus(e)}
                                      >
                                        <Image src={iconUnlock} />
                                        <Text
                                          fontSize={{ base: "14px" }}
                                          fontWeight={500}
                                          lineHeight={"140%"}
                                          color="var(--color-option-employee)"
                                        >
                                          Dự án đang mở
                                        </Text>
                                      </MenuItem>
                                    )}
                                    {e?.status === "CLOSE" && (
                                      <MenuItem
                                        alignItems={"center"}
                                        display={"flex"}
                                        gap={"16px"}
                                        onClick={() => updateStatus(e)}
                                      >
                                        <Image src={iconClock} />
                                        <Text
                                          fontSize={{ base: "14px" }}
                                          fontWeight={500}
                                          lineHeight={"140%"}
                                          color="var(--color-option-employee)"
                                        >
                                          Dự án đã đóng
                                        </Text>
                                      </MenuItem>
                                    )}
                                  </MenuList>
                                </Menu>
                              </Td>
                              <Td
                                p={"16px 16px 8px 16px"}
                                verticalAlign={"top"}
                              >
                                {rowIndex + 1}
                              </Td>
                              {keyValues.map((keyvalue, index) => {
                                let width = "auto";
                                let widthText = "100%";
                                let textAlign = "auto";
                                let whiteSpace = "inherit";
                                if (keyvalue === "") {
                                  width = "3%";
                                  textAlign = "center";
                                } else if (keyvalue === "id") {
                                  width = "5%";
                                  textAlign = "center";
                                } else if (keyvalue === "image") {
                                  width = "15%";
                                  textAlign = "start";
                                } else if (keyvalue === "nameproject") {
                                  width = "15%";
                                  textAlign = "start";
                                  whiteSpace = "normal";
                                  widthText = "227px";
                                } else if (keyvalue === "investor") {
                                  width = "10%";
                                  textAlign = "start";
                                  whiteSpace = "normal";
                                  widthText = "261px";
                                } else if (keyvalue === "projectType") {
                                  width = "15%";
                                  textAlign = "start";
                                } else if (keyvalue === "address") {
                                  width = "20%";
                                  textAlign = "end";
                                } else {
                                  width = "10%";
                                  textAlign = "end";
                                }
                                return (
                                  <Td
                                    p={"16px 16px 8px 16px"}
                                    w={width}
                                    key={index}
                                    bg={
                                      rowIndex % 2 === 0
                                        ? "var(--color-main)"
                                        : "var(--fill-avatar)"
                                    }
                                    verticalAlign={"top"}
                                  >
                                    <Box
                                      fontSize={{ base: "14px" }}
                                      lineHeight={"19.6px"}
                                      textAlign={textAlign}
                                      fontWeight={500}
                                      color={"#293755"}
                                      w={widthText}
                                      whiteSpace={whiteSpace}
                                      overflow="hidden"
                                      textOverflow="ellipsis"
                                      display="-webkit-box"
                                      sx={{
                                        WebkitLineClamp: "3",
                                        WebkitBoxOrient: "vertical",
                                      }}
                                    >
                                      {keyvalue === "file"
                                        ? formatTableValue(
                                            e[keyvalue],
                                            keyvalue,
                                            onSetFile,
                                            e
                                          )
                                        : formatTableValue(
                                            e[keyvalue],
                                            keyvalue
                                          )}
                                    </Box>
                                  </Td>
                                );
                              })}
                            </Tr>
                          );
                        })
                      ) : (
                        <Tr>
                          <Td
                            colSpan={historyTableData.headers.length}
                            textAlign="center"
                            padding={"40px 0"}
                          >
                            Không có dữ liệu
                          </Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Skeleton>
              <Flex
                m={"50px 16px 16px 16px"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Text
                  fontSize={"14px"}
                  fontWeight={500}
                  lineHeight={"20px"}
                  color={"var(--text-color-Subtittle)"}
                >
                  Showing {startEntry} to {endEntry} of {entries} entries
                </Text>
                <HStack spacing={2} justify="flex-end">
                  <ReactPaginate
                    className="pagination-custom"
                    pageCount={totalPages}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    breakClassName={"ellipsis"}
                    breakLabel={"..."}
                    previousLabel={
                      <Img
                        w={"16px"}
                        minW={"16px"}
                        h={"16px"}
                        src={chevronLeft}
                      />
                    }
                    nextLabel={
                      <Img
                        w={"16px"}
                        minW={"16px"}
                        h={"16px"}
                        src={chevronRight}
                      />
                    }
                    renderOnZeroPageCount={null}
                    forcePage={currentPage - 1}
                  />
                </HStack>
              </Flex>
            </Flex>
          </Flex>
          <PopupProjectManagementAdd
            isOpen={isParentOpen}
            onClose={dataEdit ? onCloseEdit : onCloseParent}
            onSubmit={dataEdit ? onSubmitEdit : onSubmitAdd}
            isEdit={dataEdit ? true : false}
            defaultValues={dataEdit || {}}
          />
          <PopupProjectManagementTitle
            isOpen={isOpenTitle}
            onClose={onCloseTitle}
            data={dataEdit || []}
          />
          <FileDownload
            isOpen={isOpenDownloadFile}
            onClose={onCloseDownloadFile}
            fileName={fileDownload?.fileName}
            file={fileDownload?.file}
            name={fileDownload?.name}
            onDownLoad={onDownLoad}
          />
        </Flex>
      </Box>
    </Box>
  );
};

export default ProjectManagement;
