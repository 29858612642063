import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  InputGroup,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { memo, useMemo, useState } from "react";
import {
  InputControl,
  SelectControl,
} from "../../../components/form/formControls";
import calendarIcon from "../../../assets/images/onleave/calendar.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { formatTableValue } from "../formatTable";
import { useCategories } from "../../../hooks/useCategories";

const schemaEdit = Yup.object().shape({});

const DetailStep1 = memo(({ defaultValues, updateConfirm }) => {
  
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaEdit),
    defaultValues: { ...defaultValues } || {},
  });

  let datatable = [];
  if (defaultValues?.lockingParticipant) {
    datatable = defaultValues?.lockingParticipant.map((item) => ({
      employee: item?.employee?.fullName || "",
      employeeJobTitle: item?.employeeJobTitle || "",
      commissionRate: item.commissionRate ? `${item.commissionRate}%` : "",
    }));
  }

  const dataTable = {
    headers: [
      {
        label: "Tên nhân viên",
        key: "employee",
      },
      {
        label: "Chức danh",
        key: "employeeJobTitle",
      },
      {
        label: "Hệ số thưởng",
        key: "commissionRate",
      },
    ],
    data: datatable,
  };

  const [zIndex, setZIndex] = useState("");
  const [focusStates, setFocusStates] = useState({});

  const { constantData } = useCategories();

  const formatEntries = (entries) =>
    Object.entries(entries || {}).map(([key, value]) => ({
      value: key,
      label: value,
    }));

  const serviceFees = useMemo(
    () => formatEntries(constantData?.serviceFee),
    [constantData?.serviceFee]
  );

  const handleFocus = (field, data) => {
    if (data) {
      setZIndex(data);
    }
    setFocusStates((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field, data) => {
    setZIndex("");
    setFocusStates((prev) => ({ ...prev, [field]: false }));
  };

  const handleDateChange = (selectedDate, inputName) => {
    setValue(inputName, selectedDate, { shouldValidate: true });
  };

  const onchangeType = (option) => {
    setValue("serviceFeeId", option);
  };

  const handleData = () =>{
    const dataEdit = {
      confirmCommissionStatus: 'APPROVED',
    };
    if(defaultValues?.serviceFeeId !== watch("serviceFeeId")){
      dataEdit.serviceFeeId = watch("serviceFeeId");
    }

    if(defaultValues?.kRate !== watch("kRate")){
      dataEdit.kRate = watch("kRate");
    }

    if(defaultValues?.priceForCommission !== watch("priceForCommission")){
      dataEdit.priceForCommission = watch("priceForCommission");
    }

    if(defaultValues?.bookingTime !== watch("bookingTime")){
      dataEdit.bookingTime = watch("bookingTime");
    }

    updateConfirm(defaultValues?.id,dataEdit);
  }

  return (
    <>
    <Flex
      flexDirection={"column"}
      maxW={"545px"}
      margin={"0 auto"}
      alignItems={"center"}
      gap={"0px"}
    >
      <Box w={"100%"} mt={"20px"}>
        <Text
          fontSize={"16px"}
          lineHeight={"19px"}
          fontWeight={"600"}
          color={"var(--color-header-employee)"}
          mb={"16px"}
        >
          Thông tin chung
        </Text>
        {/* 1 */}
        <Flex
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          gap={"27px"}
          mb={"12px"}
          w={"100%"}
        >
          <Box flex={"1"}>
            <Text color={"var(--color-label-employee)"} fontSize={"12px"}>
              Mã phiếu locking
            </Text>
            <Text
              color={"var(--color-option-employee)"}
              fontSize={"14px"}
              fontWeight={"500"}
              mt={"8px"}
            >
              {defaultValues?.code || ""}
            </Text>
          </Box>
          <Box flex={"1"}>
            <Text color={"var(--color-label-employee)"} fontSize={"12px"}>
              Thời điểm xác nhận
            </Text>
            <Text
              color={"var(--color-option-employee)"}
              fontSize={"14px"}
              fontWeight={"500"}
              mt={"8px"}
            >
              {defaultValues?.approverConfirmDate || ""}
            </Text>
          </Box>
        </Flex>
        {/* 2 */}
        <Flex
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          gap={"27px"}
          mb={"12px"}
          w={"100%"}
        >
          <Box flex={"1"}>
            <Text color={"var(--color-label-employee)"} fontSize={"12px"}>
              Dự án
            </Text>
            <Text
              color={"var(--color-option-employee)"}
              fontSize={"14px"}
              fontWeight={"500"}
              mt={"8px"}
            >
              {defaultValues?.project || ""}
            </Text>
          </Box>
          <Box flex={"1"}>
            <Text color={"var(--color-label-employee)"} fontSize={"12px"}>
              Sản phẩm
            </Text>
            <Text
              color={"var(--color-option-employee)"}
              fontSize={"14px"}
              fontWeight={"500"}
              mt={"8px"}
            >
              {defaultValues?.apartment || ""}
            </Text>
          </Box>
        </Flex>
        {/* 3 */}
        <Flex
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          gap={"27px"}
          mb={"12px"}
          w={"100%"}
        >
          <Box flex={"1"}>
            <Text color={"var(--color-label-employee)"} fontSize={"12px"}>
              Người tạo
            </Text>
            <Text
              color={"var(--color-option-employee)"}
              fontSize={"14px"}
              fontWeight={"500"}
              mt={"8px"}
            >
              {defaultValues?.booker || ""}
            </Text>
          </Box>
          <Box flex={"1"}>
            <Text color={"var(--color-label-employee)"} fontSize={"12px"}>
              Ngày tạo
            </Text>
            <Text
              color={"var(--color-option-employee)"}
              fontSize={"14px"}
              fontWeight={"500"}
              mt={"8px"}
            >
              {defaultValues?.createdAt || ""}
            </Text>
          </Box>
        </Flex>
        {/* 4 */}
        <Flex
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          gap={"27px"}
          mb={"12px"}
          w={"100%"}
        >
          <SelectControl
            label="Phân loại PDV"
            dataOption={serviceFees}
            selectedMain={watch("serviceFeeId")}
            placeholder="Chọn phân loại PDV"
            onChange={onchangeType}
            onFocus={() => handleFocus("serviceFeeId")}
            onBlur={() => handleBlur("serviceFeeId")}
            state={focusStates["serviceFeeId"]}
            registerProps={register("serviceFeeId")}
          />
          <InputControl
            type="number"
            label="Hệ số K"
            placeholder="Nhập hệ số K"
            errors={errors.kRate}
            register={register("kRate")}
            onFocus={() => handleFocus("kRate")}
            onBlur={() => handleBlur("kRate")}
            state={focusStates["kRate"]}
            mwidth={"100%"}
          />
        </Flex>
        {/* 5 */}
        <Flex
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          gap={"27px"}
          mb={"12px"}
          w={"100%"}
        >
          <Box flex={1}>
            <InputControl
              type="number"
              label="Số tính hoa hồng"
              placeholder="Nhập số tính hoa hồng"
              errors={errors.priceForCommission}
              register={register("priceForCommission")}
              onFocus={() => handleFocus("priceForCommission")}
              onBlur={() => handleBlur("priceForCommission")}
              state={focusStates["priceForCommission"]}
            />
          </Box>

          <FormControl flex={1} zIndex={zIndex === "bookingTime" ? 3 : 0}>
            <FormLabel
              mb={"6px"}
              fontSize={{ base: "12px" }}
              fontWeight={400}
              color={
                focusStates["bookingTime"]
                  ? "var(--color-option-employee-hover)"
                  : "var(--color-label-employee)"
              }
            >
              Thời điểm tính
            </FormLabel>
            <InputGroup>
              <DatePicker
                width="100%"
                selected={watch("bookingTime")}
                onChange={(date) => handleDateChange(date, "bookingTime")}
                dateFormat="dd/MM/yyyy"
                locale="vi"
                placeholderText="dd/mm/yy"
                className="custom-datepicker datePickerStyle"
                onFocus={() => handleFocus("bookingTime", "bookingTime")}
                onBlur={() => handleBlur("bookingTime")}
              />
              <InputRightElement pointerEvents="none" h={"44px"}>
                <Image src={calendarIcon} />
              </InputRightElement>
            </InputGroup>
            {errors.startTime && (
              <Text fontSize={{ base: "12px" }} color={"red"}>
                {errors.startTime.message}
              </Text>
            )}
          </FormControl>
        </Flex>
      </Box>
      <Box m={"24px 0"} w={"100%"}>
        <Text
          color={"var(--color-header-employee)"}
          fontSize={"16px"}
          fontWeight={"600"}
        >
          Nhân viên tham gia
        </Text>
        <Box background={"var(--fill-avatar)"} mt={"16px"} borderRadius={"8px"}>
          <TableContainer w={"100%"}>
            <Table w={"100%"} variant="unstyled">
              <Thead
                w={"100%"}
                h={"41px"}
                color="white"
                fontWeight="400"
                backgroundColor="#F9FAFD"
                borderBottom={"1px solid var(--bg-line-employee)"}
              >
                <Tr h={{ base: "41px" }}>
                  {dataTable.headers.map((e, index) => {
                    let width = "auto";
                    let textAlign = "auto";
                    if (e.key === "employee") {
                      width = "2%";
                      textAlign = "start";
                    } else if (e.key === "employeeJobTitle") {
                      width = "5%";
                      textAlign = "start";
                    } else {
                      width = "10%";
                      textAlign = "end";
                    }
                    return (
                      <Td
                        p={"16px 16px 8px 16px"}
                        key={index}
                        border={"none"}
                        color={"#51607B"}
                        fontSize={{ base: "14px" }}
                        w={width}
                      >
                        <Box textAlign={textAlign}>{e.label}</Box>
                      </Td>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody w={"100%"} h={"100%"}>
                {dataTable.data?.length > 0 ? (
                  dataTable.data?.map((e, rowIndex) => {
                    const keyValues = Object.keys(e);
                    return (
                      <Tr
                        key={rowIndex}
                        h={"44px"}
                        backgroundColor={
                          rowIndex % 2 === 0
                            ? "var(--color-main)"
                            : "var(--fill-avatar)"
                        }
                      >
                        {keyValues.map((keyvalue, index) => {
                          let width = "auto";
                          let widthText = "100%";
                          let textAlign = "auto";
                          let whiteSpace = "inherit";
                          if (keyvalue === "employee") {
                            width = "40%";
                            textAlign = "start";
                          } else if (keyvalue === "employeeJobTitle") {
                            width = "40%";
                            textAlign = "start";
                          } else {
                            width = "20%";
                            textAlign = "center";
                            whiteSpace = "inherit";
                          }
                          return (
                            <Td p={"16px 16px 8px 16px"} w={width} key={index}>
                              <Box
                                fontSize={{ base: "14px" }}
                                lineHeight={"19.6px"}
                                textAlign={textAlign}
                                fontWeight={500}
                                color={"var(--color-info-employee)"}
                                w={widthText}
                                whiteSpace={whiteSpace}
                                overflow="hidden"
                                textOverflow="ellipsis"
                                display="-webkit-box"
                                sx={{
                                  WebkitLineClamp: "2",
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                {formatTableValue(e[keyvalue], keyvalue)}
                              </Box>
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })
                ) : (
                  <Tr>
                    <Td
                      colSpan={dataTable.headers.length}
                      textAlign="center"
                      padding={"40px 0"}
                    >
                      Không có dữ liệu
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Flex>
      <Box display="flex" justifyContent="flex-end" p={"24px 0"}>
        <Button
          onClick={handleData}
          p={"13px 45px"}
          lineHeight={"18px"}
          fontSize={"15px"}
          fontWeight={600}
          color={"var(--color-main)"}
          borderRadius={"8px"}
          bg={"var(--color-option-employee-hover)"}
          _hover={{
            filter: "brightness(90%)",
          }}
        >
          Duyệt
        </Button>
      </Box>
      </>
  );
});

export default DetailStep1;
