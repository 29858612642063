import React from "react";
import {
  Button,
  Text,
  Flex,
  Image,
  ModalContent,
  ModalOverlay,
  Modal,
  Box,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import iconClose from "../../assets/images/projectManagement/x-circle.svg";
import iconFile from "../../assets/images/projectManagement/file-text.svg";

const FileDownload = ({
  isOpen,
  onClose,
  onDownLoad,
  file,
  fileName,
  name,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        borderRadius={"16px"}
        maxW={"625px"}
        maxH={"800px"}
        overflow="auto"
      >
        <Flex
          borderBottom={"1px solid var(--color-boder)"}
          alignItems={"center"}
          justifyContent={"space-between"}
          p={"24px 20px 24px 24px"}
        >
          <Text fontSize={"18px"} fontWeight={600} lineHeight={"normal"}>
            Tài liệu
          </Text>
          <Image src={iconClose} onClick={onClose} cursor={"pointer"} />
        </Flex>
        <Box p={"24px"}>
          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={"10px"}
          >
            <Text
              color="var(--color-header-employee)"
              fontSize={"16px"}
              fontWeight={"600"}
            >
              {name || ""}
            </Text>
            {/* <Flex alignItems={"center"} gap={"12px"}>
              <Text
                color={"var(--color-label-employee)"}
                fontSize={"14px"}
                lineHeight={"17px"}
              >
                Mã dự án:
              </Text>
              <Text
                color={"var(-color-info-employee)"}
                fontSize={"14px"}
                fontWeight={500}
                lineHeight={"17px"}
              >
                ABCXYZ
              </Text>
            </Flex> */}
          </Flex>
          <Box flex={1} w={"545px"} m={"24px auto"}>
            <Flex
              borderRadius="8px"
              width="100%"
              flexDirection={"column"}
              gap={"36px"}
              p={"16px"}
              bg={"var(--fill-avatar)"}
            >
              <Flex alignItems="center" gap={"12px"}>
                <Image src={iconFile} />
                <Text
                  fontSize="14px"
                  fontWeight="500"
                  color={"var(--color-option-employee-hover)"}
                >
                  {name ? name : file || "Đây là tên tài liệu của dự án"}
                </Text>
              </Flex>
              <Flex justifyContent="end" w={"100%"}>
                <Button
                  bg={"var(--linear-gradient-employee)"}
                  leftIcon={<DownloadIcon />}
                  colorScheme="teal"
                  onClick={onDownLoad}
                  _hover={{
                    filter: "brightness(90%)",
                  }}
                  p={"10px 16px"}
                  w={"max-content"}
                  position={"relative"}
                >
                  {/* <Link 
                    position={"absolute"} 
                    top={0} 
                    right={0} 
                    bottom={0} 
                    left={0} 
                    opacity={0} 
                    to={file} 
                    target="_blank" 
                    download
                    ></Link> */}
                  Tải xuống
                </Button>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default FileDownload;
