import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import FormFeature from "./form/formFeature";
import { useProjectCategoriesProject } from "../../../hooks/useProjectCategoriesProject";
import { ROLES_CONFIG } from "../../../utils";
import { useSelector } from "react-redux";

const Project = () => {
  const {
    division,
    projectType,
    apartmentType,
    projectSelect,
    refetch,
    isLoading,
    isRefetching,
  } = useProjectCategoriesProject();

  const user = useSelector((state) => state.auth.login?.currentUser);
  const roles = user?.roles || [];

  const hasPermission = (requiredRoles) => {
    return (
      requiredRoles.some((role) => roles.includes(role)) ||
      roles.includes("ALL")
    );
  };

  const gridItemsData = [
    {
      title: "Danh sách phân khu",
      button: "Thêm phân khu",
      data: {
        headers: [
          { label: "", key: "" },
          { label: "STT", key: "" },
          { label: "Tên phân khu", key: "name" },
          { label: "Tên dự án", key: "project" },
          { label: "Người thêm", key: "creator" },
        ],
        data: division,
        role: ROLES_CONFIG.MENU.MENU_QLDM.CHILDREN.CHILDREN_DA.TAB.TAB_DA
          .CHILDREN_TAB.CHILDREN_TAB_DSPK.role,
      },
      type: 0,
      dataSelect: projectSelect,
      textDelete: "phân khu",
      textAdd: "Tạo phân khu mới",
      textButtonAdd: "Tạo phân khu mới",
      textTitleEdit: "Chỉnh sửa phân khu",
    },
    {
      title: "Danh sách LHDA",
      button: "Thêm LHDA",
      data: {
        headers: [
          { label: "", key: "" },
          { label: "STT", key: "" },
          { label: "Tên loại hình dự án", key: "name" },
        ],
        data: projectType,
        role: ROLES_CONFIG.MENU.MENU_QLDM.CHILDREN.CHILDREN_DA.TAB.TAB_DA
          .CHILDREN_TAB.CHILDREN_TAB_LHDA.role,
      },
      type: 1,
      textDelete: "loại hình dự án",
      textAdd: "Tạo LHDA mới",
      textButtonAdd: "Tạo LHDA mới",
      textTitleEdit: "Chỉnh sửa LHDA",
    },
    {
      title: "Danh sách LHSP",
      button: "Thêm LHSP",
      data: {
        headers: [
          { label: "", key: "" },
          { label: "STT", key: "" },
          { label: "Tên loại hình sản phẩm", key: "name" },
        ],
        data: apartmentType,
        role: ROLES_CONFIG.MENU.MENU_QLDM.CHILDREN.CHILDREN_DA.TAB.TAB_DA
          .CHILDREN_TAB.CHILDREN_TAB_DSCH.role,
      },
      type: 2,
      textDelete: "căn hộ",
      textAdd: "Tạo căn hộ mới",
      textButtonAdd: "Tạo căn hộ mới",
      textTitleEdit: "Chỉnh sửa căn hộ",
    },
  ];

  return (
    <Grid minH={"80vh"} templateColumns="repeat(2, 1fr)" gap={4}>
      {gridItemsData.map(
        (item, index) =>
          hasPermission(item.data.role) && (
            <GridItem
              key={index}
              p={"16px"}
              bg={"var(--color-main)"}
              borderRadius={"16px"}
            >
              <FormFeature
                title={item.title}
                button={item.button}
                data={item.data}
                isLoading={isLoading}
                isRefetching={isRefetching}
                refetch={refetch}
                dataSelect={item?.dataSelect || null}
                type={item.type}
                textDelete={item?.textDelete || null}
                textAdd={item?.textAdd || null}
                textButtonAdd={item?.textButtonAdd || null}
                textTitleEdit={item?.textTitleEdit || null}
                formTable={false}
              />
            </GridItem>
          )
      )}
    </Grid>
  );
};

export default Project;
