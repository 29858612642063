export const NumberOfPerPage = {
    onLeave: 5,
    checkIn: 5,
    attendance: 5,
    receiveSalary: 5,
    projectManagement: 5,
    bookingManagement: 5,
    lockingManagement: 5,
    apartment: 5,
    contract: 5,
    bookingEvent: 5,
    saleOpeningEvent: 5,
} 

export const DataPlaceOfIdentification = [
    "Cục Cảnh sát quản lý hành chính về trật tự xã hội", 
    "Cục Cảnh sát đăng ký quản lý cư trú và dữ liệu Quốc gia về dân cư", 
]