import {
  Box,
  Flex,
  Text,
  Modal,
  ModalContent,
  ModalOverlay,
  Image,
  Button,
  Input,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import React, { memo, useMemo, useEffect, useState, useCallback } from "react";
import IconX from "../../../assets/images/booking/x-circle.svg";
import imageIcon from "../../../assets/images/booking/image.svg";
import iconTrash from "../../../assets/images/projectManagement/trash-2.svg";
import iconPlus from "../../../assets/images/projectManagement/plus.svg";
import calendarIcon from "../../../assets/images/onleave/calendar.svg";
import {
  InputControl,
  SelectControl,
} from "../../../components/form/formControls";
import { useForm } from "react-hook-form";
import { FormBonus } from "../../../components/form/formBonus";
import { useCategories } from "../../../hooks/useCategories";
import { lockingImageCall } from "../../../api/lockingImage";
import { lockingParticipantCall } from "../../../api/lockingParticipant";
import DatePicker from "react-datepicker";
import { formatPrice } from "../../../utils/utils";

const baseURL = process.env.REACT_APP_BACKEND_API || "http://localhost:3001";

const FormDetail = memo(
  ({ isOpen, onClose, onSubmit, defaultValues, isEdit }) => {
    const {
      reset,
      register,
      handleSubmit,
      watch,
      setValue,
      formState: { errors },
    } = useForm({
      defaultValues: defaultValues || {},
    });

    const [errorData, setErrorData] = useState(false);
    const [focusedIndex, setFocusedIndex] = useState(null);
    const [focusStates, setFocusStates] = useState({});
    const [infident1, setInfident1] = useState(null);
    const [infident2, setInfident2] = useState(null);
    const [imagePayment, setImagePayment] = useState(null);
    const [employeeProject, setEmployeeProject] = useState([
      {
        id: Date.now(),
        employeeId: "",
        employeeJobTitle: "",
        commissionRate: "",
      },
    ]);
    const [displayPrice, setDisplayPrice] = useState(0);
    const onChangePrice = useCallback(
      (event) => {
        const { value } = event.target;
        const numericValue = value.replace(/[^0-9]/g, "");
        setDisplayPrice(formatPrice(numericValue));
        setValue("price", Number(numericValue), {
          shouldValidate: true,
        });
      },
      [setValue, watch, formatPrice]
    );

    const { constantData } = useCategories();

    const formatEntries = (entries) =>
      Object.entries(entries || {}).map(([key, value]) => ({
        value: key,
        label: value,
      }));

    const projects = useMemo(
      () => formatEntries(constantData?.project),
      [constantData?.project]
    );
    const apartments = useMemo(
      () => formatEntries(constantData?.apartment),
      [constantData?.apartment]
    );
    const customers = useMemo(
      () => formatEntries(constantData?.customer),
      [constantData?.customer]
    );
    const employees = useMemo(
      () => formatEntries(constantData?.employee),
      [constantData?.employee]
    );

    useEffect(() => {
      reset(defaultValues);
      setErrorData(false);
      if (defaultValues) {
        setDisplayPrice(formatPrice(defaultValues?.price || 0));
      }
      // lấy ra nhân viên quản lý
      if (defaultValues?.id) {
        const fetchData = async () => {
          try {
            const dataManagerEmployee =
              await lockingParticipantCall.getByLockingId(defaultValues?.id);

            setEmployeeProject(dataManagerEmployee);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        fetchData();
      } else {
        setEmployeeProject([
          {
            id: Date.now(),
            employeeId: "",
            employeeJobTitle: "",
            commissionRate: "",
          },
        ]);
      }

      // lấy ra ảnh

      if (defaultValues?.id) {
        const fetchData = async () => {
          try {
            const dataFileEmployee = await lockingImageCall.getByLockingId(
              defaultValues?.id
            );

            if (defaultValues?.id) {
              if (Array.isArray(dataFileEmployee?.items)) {
                for (const item of dataFileEmployee?.items) {
                  if (item?.type === "IDENTIFICATION_BEFORE") {
                    setInfident1((prev) => ({
                      ...prev,
                      path: item?.path,
                      id: item?._id,
                      status: true,
                      init: true,
                    }));
                  }
                  if (item?.type === "IDENTIFICATION_AFTER") {
                    setInfident2((prev) => ({
                      ...prev,
                      path: item?.path,
                      id: item?._id,
                      status: true,
                      init: true,
                    }));
                  }
                  if (item?.type === "TRANSACTION") {
                    setImagePayment((prev) => ({
                      ...prev,
                      path: item?.path,
                      id: item?._id,
                      status: true,
                      init: true,
                    }));
                  }
                }
              }
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        fetchData();
      } else {
        setInfident1({});
        setInfident2({});
        setImagePayment({});
      }
    }, [isOpen]);

    //Thêm nhân viên
    const addEmployee = () => {
      setEmployeeProject([
        ...employeeProject,
        {
          id: Date.now(),
          employeeId: "",
          employeeJobTitle: "",
          commissionRate: "",
        },
      ]);
    };

    // Hàm xóa nhân viên
    const deleteEmployee = (id) => {
      setEmployeeProject(employeeProject.filter((emp) => emp.id !== id));
    };

    // Hàm thay đổi dữ liệu
    const handleChange = (id, field, value) => {
      setEmployeeProject(
        employeeProject.map((emp) =>
          emp.id === id ? { ...emp, [field]: value } : emp
        )
      );
    };

    const handleFocus = (field, index) => {
      if (index !== null) {
        setFocusedIndex(index);
      } else {
        setFocusStates((prev) => ({ ...prev, [field]: true }));
      }
    };

    const handleBlur = (field, index) => {
      if (index !== null) {
        setFocusedIndex(index);
      } else {
        setFocusedIndex(null);
      }
      setFocusStates((prev) => ({ ...prev, [field]: false }));
    };

    const handleDateChange = (e, inputName) => {
      setValue(inputName, e, { shouldValidate: true });
      // const selectedDate = e.target.value;

      // setInputColors((prevColors) => ({
      //   ...prevColors,
      //   [inputName]: selectedDate
      //     ? "var(--color-info-employee)"
      //     : "var(--color-text-unclick)",
      // }));
    };

    const onchangeProject = (option) => {
      setValue("projectId", option);
    };

    const onchangeApartment = (option) => {
      setValue("apartmentId", option);
    };

    const onchangeCustomer = (option) => {
      setValue("customerId", option);
    };

    const changeImage = (event, type, id) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        const imageUrl = URL.createObjectURL(file);

        reader.onload = (e) => {
          if (type === 1) {
            setInfident1((prev) => ({
              ...prev,
              id: id || Date.now(),
              imageUrl: imageUrl,
              file: file,
              status: false,
            }));
          } else if (type === 2) {
            setInfident2((prev) => ({
              ...prev,
              id: id || Date.now(),
              imageUrl: imageUrl,
              file: file,
              status: false,
            }));
          } else {
            setImagePayment((prev) => ({
              ...prev,
              id: id || Date.now(),
              imageUrl: imageUrl,
              file: file,
              status: false,
            }));
          }
        };

        reader.readAsDataURL(file);
      }
    };

    const submitHandler = (data) => {
      const allFieldsEmpty = employeeProject.every(
        (employee) =>
          !employee.employeeId &&
          !employee.commissionRate &&
          !employee.employeeJobTitle
      );

      const hasAnyFieldEmpty = employeeProject.some(
        (employee) =>
          !employee.employeeId ||
          !employee.commissionRate ||
          !employee.employeeJobTitle
      );

      if (hasAnyFieldEmpty && !allFieldsEmpty) {
        setErrorData(true);
      } else {
        setErrorData(false);
        if (!allFieldsEmpty) data.employeeProject = employeeProject;
        if (!infident1?.status) {
          data.infident1 = infident1;
        }
        if (!infident2?.status) {
          data.infident2 = infident2;
        }
        if (!imagePayment?.status) {
          data.imagePayment = imagePayment;
        }

        onSubmit(data);
      }
    };

    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          backgroundColor="var(--color-main)"
          borderRadius={"16px"}
          position={"relative"}
          w={"100%"}
          maxW={"625px"}
        >
          <form onSubmit={handleSubmit(submitHandler)}>
            <Box
              paddingBottom={"80px"}
              h={"86.5vh"}
              w={"100%"}
              overflowY={"scroll"}
              sx={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                  width: "4px",
                  borderRadius: "50px",
                  background: "var(--color-background)",
                },
                "&::-moz-scrollbar": {
                  width: "4px",
                },
                "&::-moz-scrollbar-thumb": {
                  width: "4px",
                  borderRadius: "50px",
                  background: "var(--color-background)",
                },
              }}
            >
              <Flex
                alignItems={"center"}
                p={"25px 24px 24px 24px"}
                position={"relative"}
                borderBottom={"1px solid var(--border-input-employee)"}
              >
                <Text
                  color={"var(--color-header-employee)"}
                  fontSize={"16px"}
                  fontWeight={"600"}
                >
                  {isEdit ? "Chỉnh sửa locking" : "Thêm locking"}
                </Text>
                <Box
                  position={"absolute"}
                  top={"24px"}
                  right={"24px"}
                  cursor={"pointer"}
                  onClick={onClose}
                >
                  <Image src={IconX} />
                </Box>
              </Flex>
              <Box margin={"0 auto"}>
                <Box p={"24px 40px"}>
                  <Box flex={1}>
                    <Box>
                      <Text
                        color={"var(--color-header-employee)"}
                        fontSize={"16px"}
                        fontWeight={"600"}
                      >
                        Thông tin chung
                      </Text>
                      <Box mt={"16px"} borderRadius={"8px"}>
                        <Flex>
                          <InputControl
                            label="Tên đợt locking"
                            placeholder="Nhập tên đợt locking"
                            errors={errors.note}
                            register={register("note")}
                            onFocus={() => handleFocus("note")}
                            onBlur={() => handleBlur("note")}
                            state={focusStates["note"]}
                            mwidth="100%"
                          />
                        </Flex>
                        {isEdit && (
                          <Flex mt={"12px"} gap={"27px"}>
                            <Box flex={"1"}>
                              <Text
                                color={"var(--color-label-employee)"}
                                fontSize={"12px"}
                              >
                                Người tạo locking
                              </Text>
                              <Text
                                color={"var(--color-option-employee)"}
                                fontSize={"14px"}
                                fontWeight={"500"}
                                mt={"8px"}
                              >
                                Nguyễn Thục Quyên
                              </Text>
                            </Box>
                            <Box flex={"1"}>
                              <Text
                                color={"var(--color-label-employee)"}
                                fontSize={"12px"}
                              >
                                Ngày tạo locking
                              </Text>
                              <Text
                                color={"var(--color-option-employee)"}
                                fontSize={"14px"}
                                fontWeight={"500"}
                                mt={"8px"}
                              >
                                05/09/2024
                              </Text>
                            </Box>
                          </Flex>
                        )}

                        <Flex mt={"16px"} gap={"27px"}>
                          <SelectControl
                            label="Dự án"
                            dataOption={projects}
                            placeholder="Chọn dự án"
                            selectedMain={watch("projectId")}
                            onChange={onchangeProject}
                            onFocus={() => handleFocus("projectId")}
                            onBlur={() => handleBlur("projectId")}
                            state={focusStates["projectId"]}
                            registerProps={register("projectId")}
                            errorMessage={errors.projectId}
                            mwidth="100%"
                          />
                          <SelectControl
                            label="Sản phẩm"
                            dataOption={apartments}
                            placeholder="Chọn sản phẩm"
                            selectedMain={watch("apartmentId")}
                            onChange={onchangeApartment}
                            onFocus={() => handleFocus("apartmentId")}
                            onBlur={() => handleBlur("apartmentId")}
                            state={focusStates["apartmentId"]}
                            registerProps={register("apartmentId")}
                            errorMessage={errors.apartmentId}
                            mwidth="100%"
                          />
                        </Flex>
                        <Flex mt={"16px"} gap={"27px"}>
                          <InputControl
                            // type="number"
                            label="Số tiền cọc"
                            placeholder="Ví dụ: 1 tỷ 950 triệu "
                            errors={errors.price}
                            register={register("price")}
                            onFocus={() => handleFocus("price")}
                            onBlur={() => handleBlur("price")}
                            state={focusStates["price"]}
                            onChange={onChangePrice}
                            value={displayPrice}
                            mwidth={"100%"}
                          />
                          <FormControl flex={1} zIndex={3}>
                            <FormLabel
                              mb={"6px"}
                              fontSize={{ base: "12px" }}
                              fontWeight={400}
                              color={
                                focusStates["startTime"]
                                  ? "var(--color-option-employee-hover)"
                                  : "var(--color-label-employee)"
                              }
                            >
                              TG bắt đầu
                            </FormLabel>
                            <InputGroup>
                              <DatePicker
                                width="100%"
                                selected={watch("bookingTime")}
                                onChange={(date) =>
                                  handleDateChange(date, "bookingTime")
                                }
                                dateFormat="dd/MM/yyyy"
                                locale="vi"
                                placeholderText="dd/mm/yy"
                                className="custom-datepicker datePickerStyle"
                                onFocus={() =>
                                  handleFocus("bookingTime", "bookingTime")
                                }
                                onBlur={() => handleBlur("bookingTime")}
                              />
                              <InputRightElement
                                pointerEvents="none"
                                h={"44px"}
                              >
                                <Image src={calendarIcon} />
                              </InputRightElement>
                            </InputGroup>
                            {errors.bookingTime && (
                              <Text fontSize={{ base: "12px" }} color={"red"}>
                                {errors.bookingTime.message}
                              </Text>
                            )}
                          </FormControl>
                          {/* <InputControl
                            label={"TG bắt đầu"}
                            type="date"
                            colorIp={inputColors.bookingTime}
                            state={focusStates["bookingTime"]}
                            onFocus={() => handleFocus("bookingTime")}
                            onBlur={() => handleBlur("bookingTime")}
                            onChange={(e) => handleDateChange(e, "bookingTime")}
                            valueMain={watch("bookingTime")}
                            errors={errors?.bookingTime}
                            register={register("bookingTime")}
                          /> */}
                        </Flex>
                      </Box>
                    </Box>
                  </Box>
                  <Box flex={1} mt={"24px"}>
                    <Box>
                      <Text
                        color={"var(--color-header-employee)"}
                        fontSize={"16px"}
                        fontWeight={"600"}
                      >
                        Thông tin khách hàng
                      </Text>
                      <Box
                        background={"var(--fill-avatar)"}
                        p={"16px 24px 16px 16px"}
                        mt={"16px"}
                        borderRadius={"8px"}
                      >
                        <Flex gap={"27px"}>
                          <Box flex={"1"}>
                            <SelectControl
                              label="Tên khách hàng"
                              dataOption={customers}
                              placeholder="Chọn khách hàng"
                              selectedMain={watch("customerId")}
                              onChange={onchangeCustomer}
                              onFocus={() => handleFocus("customerId")}
                              onBlur={() => handleBlur("customerId")}
                              state={focusStates["customerId"]}
                              registerProps={register("customerId")}
                              errorMessage={errors.customerId}
                              mwidth="259px"
                            />
                          </Box>
                        </Flex>
                      </Box>
                    </Box>
                    <Box mt={"16px"}>
                      <Flex gap={"27px"}>
                        <Box flex={"1"}>
                          <Text
                            color={"var(--color-label-employee)"}
                            fontSize={"12px"}
                          >
                            Ảnh CCCD mặt trước
                          </Text>
                          <Box mt={"8px"}>
                            <Box
                              position={"relative"}
                              overflow={"hidden"}
                              w={"259px"}
                              height={"146px"}
                              display={"block"}
                              border={"0.5px solid var(--bg-line-employee)"}
                              borderRadius={"8px"}
                            >
                              {(infident1?.path || infident1?.imageUrl) && (
                                <Image
                                  src={
                                    infident1?.imageUrl
                                      ? infident1?.imageUrl
                                      : `${baseURL}/${infident1?.path}`
                                  }
                                  position={"absolute"}
                                  top={0}
                                  left={0}
                                  right={0}
                                  bottom={0}
                                  objectFit={"cover"}
                                  w={"100%"}
                                  h={"100%"}
                                  borderRadius={"8px"}
                                />
                              )}
                              <Flex
                                position={"absolute"}
                                right={"8px"}
                                bottom={"8px"}
                              >
                                <Box
                                  padding={"8px"}
                                  bg={"var(--color-main)"}
                                  borderRadius={"50%"}
                                  cursor={"pointer"}
                                  mr={"8px"}
                                  position={"relative"}
                                >
                                  <Input
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    opacity={0}
                                    position="absolute"
                                    top={0}
                                    left={0}
                                    width="100%"
                                    height="100%"
                                    cursor="pointer"
                                    onChange={(e) =>
                                      changeImage(e, 1, infident1?.id)
                                    }
                                  />
                                  <Image
                                    src={imageIcon}
                                    _hover={{
                                      filter: "var(--filter-red)",
                                    }}
                                  />
                                </Box>
                              </Flex>
                            </Box>
                          </Box>
                        </Box>
                        <Box flex={"1"}>
                          <Text
                            color={"var(--color-label-employee)"}
                            fontSize={"12px"}
                          >
                            Ảnh CCCD mặt trước
                          </Text>
                          <Box mt={"8px"}>
                            <Box
                              position={"relative"}
                              overflow={"hidden"}
                              w={"259px"}
                              height={"146px"}
                              display={"block"}
                              border={"0.5px solid var(--bg-line-employee)"}
                              borderRadius={"8px"}
                            >
                              {(infident2?.path || infident2?.imageUrl) && (
                                <Image
                                  src={
                                    infident2?.imageUrl
                                      ? infident2?.imageUrl
                                      : `${baseURL}/${infident2?.path}`
                                  }
                                  position={"absolute"}
                                  top={0}
                                  left={0}
                                  right={0}
                                  bottom={0}
                                  objectFit={"cover"}
                                  w={"100%"}
                                  h={"100%"}
                                  borderRadius={"8px"}
                                />
                              )}
                              <Flex
                                position={"absolute"}
                                right={"8px"}
                                bottom={"8px"}
                                cursor={"pointer"}
                              >
                                <Box
                                  padding={"8px"}
                                  bg={"var(--color-main)"}
                                  borderRadius={"50%"}
                                  mr={"8px"}
                                  position={"relative"}
                                >
                                  <Input
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    opacity={0}
                                    position="absolute"
                                    top={0}
                                    left={0}
                                    width="100%"
                                    height="100%"
                                    cursor="pointer"
                                    onChange={(e) =>
                                      changeImage(e, 2, infident2?.id)
                                    }
                                  />
                                  <Image
                                    src={imageIcon}
                                    _hover={{
                                      filter: "var(--filter-red)",
                                    }}
                                  />
                                </Box>
                              </Flex>
                            </Box>
                          </Box>
                        </Box>
                      </Flex>
                    </Box>
                    <Box mt={"16px"}>
                      <Flex>
                        <Box flex={"1"}>
                          <Text
                            color={"var(--color-label-employee)"}
                            fontSize={"12px"}
                          >
                            Ảnh ủy nhiệm chi
                          </Text>
                          <Flex
                            mt={"8px"}
                            border={"0.5px solid var(--bg-line-employee)"}
                            borderRadius={"8px"}
                            justifyContent={"center"}
                            position={"relative"}
                          >
                            <Box
                              overflow={"hidden"}
                              w={"202px"}
                              h={"300px"}
                              display={"block"}
                              borderRadius={"11px"}
                              position={"relative"}
                            >
                              {(imagePayment?.path ||
                                imagePayment?.imageUrl) && (
                                <Image
                                  src={
                                    imagePayment?.imageUrl
                                      ? imagePayment?.imageUrl
                                      : `${baseURL}/${imagePayment?.path}`
                                  }
                                  position={"absolute"}
                                  top={0}
                                  left={0}
                                  right={0}
                                  bottom={0}
                                  objectFit={"cover"}
                                  w={"100%"}
                                  h={"100%"}
                                  borderRadius={"8px"}
                                />
                              )}
                            </Box>
                            <Flex
                              position={"absolute"}
                              right={"8px"}
                              bottom={"8px"}
                              cursor={"pointer"}
                            >
                              <Box
                                padding={"8px"}
                                bg={"var(--fill-avatar)"}
                                borderRadius={"50%"}
                                mr={"8px"}
                                position={"relative"}
                              >
                                <Input
                                  type="file"
                                  accept="image/*"
                                  multiple
                                  opacity={0}
                                  position="absolute"
                                  top={0}
                                  left={0}
                                  width="100%"
                                  height="100%"
                                  cursor="pointer"
                                  onChange={(e) =>
                                    changeImage(e, 3, imagePayment?.id)
                                  }
                                />
                                <Image
                                  src={imageIcon}
                                  _hover={{
                                    filter: "var(--filter-red)",
                                  }}
                                />
                              </Box>
                            </Flex>
                          </Flex>
                        </Box>
                      </Flex>
                    </Box>
                  </Box>
                  <Box margin={"24px auto"}>
                    <Flex flexDirection={"column"} gap={"16px"}>
                      <Flex
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Text
                          fontSize={"16px"}
                          fontWeight={600}
                          lineHeight={"normal"}
                          color="var(--color-black)"
                        >
                          Nhân viên tham gia
                        </Text>
                      </Flex>
                      <Flex
                        mt={"12px"}
                        flexDirection={"column"}
                        gap={"12px"}
                        alignItems={"flex-end"}
                      >
                        <Flex
                          justifyContent={"space-between"}
                          alignItems={"flex-start"}
                          gap={"12px"}
                          w={"100%"}
                          flexDirection={"column"}
                        >
                          {employeeProject.map((emp, index) => (
                            <FormBonus
                              key={index}
                              zIndex={index}
                              isIconPlace={true}
                              isIconTime={false}
                              length={"100%"}
                              onChangeName={(e) => {
                                handleChange(emp.id, "employeeId", e);
                              }}
                              onChangeBonus={(e) => {
                                handleChange(emp.id, "commissionRate", e);
                              }}
                              onChangeJobTitle={(e) => {
                                handleChange(emp.id, "employeeJobTitle", e);
                              }}
                              deleteData={() => deleteEmployee(emp.id)}
                              icon={iconTrash}
                              nameMain={emp?.commissionRate || null}
                              jobTitleMain={emp?.employeeJobTitle || null}
                              selectedMain={emp.employeeId}
                              filteredOptions={employees}
                              isFocused={focusedIndex === index}
                              onFocus={() =>
                                handleFocus("managerProject", index)
                              }
                              onBlur={() => handleBlur("managerProject", index)}
                            />
                          ))}
                        </Flex>
                        {errorData && (
                          <Text
                            w={"100%"}
                            color={"var(--text-red-employee)"}
                            fontSize={"12px"}
                          >
                            Vui lòng nhập đầy đủ thông tin
                          </Text>
                        )}
                        <Flex
                          gap={"4px"}
                          alignItems={"end"}
                          p="8px 16px"
                          border={
                            "1px solid var(--color-option-employee-hover)"
                          }
                          borderRadius={"8px"}
                          w={"max-content"}
                          mr={"60px"}
                          cursor={"pointer"}
                          bg={"var(--color-main)"}
                          onClick={addEmployee}
                          _hover={{
                            filter: "brightness(90%)",
                          }}
                        >
                          <Image src={iconPlus} />
                          <Text
                            fontSize={"12px"}
                            lineHeight={"15px"}
                            color={"var(--color-option-employee-hover)"}
                            fontWeight={600}
                          >
                            Thêm NV tham gia
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Box>
                </Box>
              </Box>
              <Box
                position={"absolute"}
                w={"100%"}
                bottom={"0"}
                bg={"var(--color-main)"}
                borderTop={"0.5px solid var(--bg-line-employee)"}
                borderRadius={"0px 0px 16px 16px"}
                zIndex={3}
              >
                <Flex p={"24px"} gap={"16px"} justifyContent={"flex-end"}>
                  <Button
                    w={"112px"}
                    p={"13px 20px"}
                    fontSize={"15px"}
                    fontWeight={600}
                    color={"var(--color-option-employee-hover)"}
                    borderRadius={"8px"}
                    border={"1px solid var(--color-option-employee-hover)"}
                    bg={"var(--color-main)"}
                    _hover={{
                      filter: "brightness(90%)",
                    }}
                    onClick={onClose}
                  >
                    Hủy
                  </Button>
                  <Button
                    type="submit"
                    w={"112px"}
                    p={"13px 20px"}
                    fontSize={"15px"}
                    fontWeight={600}
                    color={"var(--color-main)"}
                    borderRadius={"8px"}
                    bg={"var(--color-option-employee-hover)"}
                    _hover={{
                      filter: "brightness(90%)",
                    }}
                  >
                    Lưu
                  </Button>
                </Flex>
              </Box>
            </Box>
          </form>
        </ModalContent>
      </Modal>
    );
  }
);

export default FormDetail;
