import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useDisclosure,
  // useDisclosure,
} from "@chakra-ui/react";
import TableLocking from "./tableLocking";
import IconFile from "../../assets/images/booking/upfile.svg";
import iconPlus from "../../assets/images/booking/plus.svg";
import PopupImport from "../../components/popup/PopupImport";
// import FormDetail from "./form/detail";
import { commonCall } from "../../api/common";
import toast from "react-hot-toast";
import { lockingCall } from "../../api/locking";
import { lockingImageCall } from "../../api/lockingImage";
import { lockingParticipantCall } from "../../api/lockingParticipant";
import { exportFile } from "../../utils/tools";
import { formatDateToLocal } from "../../utils/utils";
import { useLocking } from "../../hooks/useLooking";
import FormAdd from "./form/add";

const columnMappingReverse = {
  "Mã phiếu booking": "bookingCode",
  "Mã dự án": "projectCode",
  "Mã khách hàng": "customerCode",
  "Mã căn hộ": "apartmentCode",
  "Ngày locking": "bookingTime",
  "Số tiền locking": "price",
  "Ghi chú locking": "note",
};

export default function Locking() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { refetch, isRefetching } = useLocking();

  const [popupVisible, setPopupVisible] = useState(false);

  const onSubmitAdd = async (data) => {
    const toastCreate = toast.loading("Đang thêm booking ...");
    try {
      let filteredData = Object.fromEntries(
        Object.entries(data).filter(
          ([_, value]) => value !== "" && value !== undefined && value !== null
        )
      );

      if (filteredData?.bookingTime) {
        filteredData.bookingTime = formatDateToLocal(filteredData.bookingTime);
      }

      const {
        infident1,
        infident2,
        imagePayment,
        employeeProject,
        ...dataFilter
      } = filteredData;
      const dataCreateLocking = await lockingCall.create({
        ...dataFilter,
      });

      if (dataCreateLocking?._id) {
        // filter add projectManager
        let createDataEmployeeManager = [];
        if (employeeProject) {
          createDataEmployeeManager = employeeProject.map((item) => {
            if (item?.id) {
              delete item?.id;
            }
            item.lockingId = dataCreateLocking?._id;
            return item;
          });
        }

        if (createDataEmployeeManager.length > 0) {
          await lockingParticipantCall.createAndUpdateMany({
            lockingId: dataCreateLocking?._id,
            list: createDataEmployeeManager,
          });
        }

        if (data?.infident1 && Object.keys(data?.infident1).length !== 0) {
          const { filePath } = await commonCall.uploadFile(
            infident1?.file,
            "lockingDocument"
          );
          if (filePath) {
            await lockingImageCall.create({
              lockingId: dataCreateLocking?._id,
              path: filePath,
              type: "IDENTIFICATION_BEFORE",
            });
          }
        }
        if (data?.infident2 && Object.keys(data?.infident2).length !== 0) {
          const { filePath } = await commonCall.uploadFile(
            infident2?.file,
            "lockingDocument"
          );
          if (filePath) {
            await lockingImageCall.create({
              lockingId: dataCreateLocking?._id,
              path: filePath,
              type: "IDENTIFICATION_AFTER",
            });
          }
        }
        if (
          data?.imagePayment &&
          Object.keys(data?.imagePayment).length !== 0
        ) {
          const { filePath } = await commonCall.uploadFile(
            imagePayment?.file,
            "lockingDocument"
          );
          if (filePath) {
            await lockingImageCall.create({
              lockingId: dataCreateLocking?._id,
              path: filePath,
              type: "TRANSACTION",
            });
          }
        }
      }

      await refetch();
      onClose();
      toast.success("Thêm thành công");
    } catch (error) {
      console.log({ error });
      toast.dismiss(toastCreate);
    }
    toast.dismiss(toastCreate);
  };

  const handleExportSample = () => {
    const columnMapping = {
      bookingCode: "Mã phiếu booking",
      projectCode: "Mã dự án",
      customerCode: "Mã khách hàng",
      apartmentCode: "Mã căn hộ",
      bookingTime: "Ngày locking",
      price: "Số tiền locking",
      note: "Ghi chú locking",
    };
    const data = [
      {
        bookingCode: "MSH_BOOKING_1",
        projectCode: "MSHDA_1",
        customerCode: "MSH_KH_1",
        apartmentCode: "AP001",
        bookingTime: "2024-11-17",
        price: 1000000000,
        note: "abc",
      },
      {
        bookingCode: "MSH_BOOKING_2",
        projectCode: "MSHDA_2",
        customerCode: "MSH_KH_2",
        apartmentCode: "AP002",
        bookingTime: "2024-11-17",
        price: 1000000000,
        note: "abc",
      },
      {
        bookingCode: "MSH_BOOKING_3",
        projectCode: "MSHDA_3",
        customerCode: "MSH_KH_3",
        apartmentCode: "AP003",
        bookingTime: "2024-11-17",
        price: 1000000000,
        note: "abc",
      },
    ];
    try {
      exportFile(data, columnMapping, "bang_locking.xlsx");
      toast.success("Download file thành công");
    } catch (error) {
      toast.error("Export lỗi");
      console.log(error);
    }
  };

  return (
    <Box
      w={{ base: "100%" }}
      backgroundColor="var(--color-backgroundmain)"
      p={{ base: "24px 16px 16px" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={{ base: "74px" }}>
          <Text fontSize={{ base: "20px" }} fontWeight={600}>
            Quản lý locking
          </Text>
        </Flex>
        <Flex alignItems={"center"} gap={"16px"}>
          <Button
            display="flex"
            alignItems="center"
            p="10px 16px"
            gap="8px"
            backgroundImage="var(--color-button)"
            onClick={() => setPopupVisible(true)}
            _hover={{
              filter: "brightness(90%)",
            }}
          >
            <Image src={IconFile} />
            <Text fontSize="15px" fontWeight={600} color="var(--color-main)">
              Tải file lên
            </Text>
          </Button>
          <Button
            w={"176px"}
            h={"44px"}
            display={"flex"}
            alignItems={"center"}
            p={"10px 16px 10px 12px"}
            gap={"8px"}
            background="var(--linear-gradient-employee)"
            _hover={{
              filter: "brightness(90%)",
            }}
            onClick={onOpen}
          >
            <Image src={iconPlus} />
            <Text
              fontSize={{ base: "15px" }}
              fontWeight={600}
              color="var(--color-main)"
            >
              Thêm locking
            </Text>
          </Button>
        </Flex>
      </Flex>
      <Box mt={{ base: "23px" }}>
        <TableLocking isRefetchingWithCreate={isRefetching} />
      </Box>
      {popupVisible && (
        <PopupImport
          isOpen={popupVisible}
          onClose={() => setPopupVisible(false)}
          refetchCheckInData={refetch}
          columnMappingReverse={columnMappingReverse}
          handleExport={handleExportSample}
          titleAdd="Thêm danh sách locking"
          inputAdd="Nhập danh sách locking"
          type={"locking"}
        />
      )}
      <FormAdd
        isOpen={isOpen}
        onClose={onClose}
        isEdit={false}
        onSubmit={onSubmitAdd}
      />
    </Box>
  );
}
