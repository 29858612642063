export const ROLES_CONFIG = {
  ROUTES: {
    SignIn: [],
    NotFoundPage: [],
    Employee: [
      // Level 1
      "MSH_HUMAN_RESOURCE_MANAGEMENT",
      // Level 2
      "MSH_EMPLOYEE_MANAGEMENT",
    ],
    TimeKeeping: [
      // Level 1
      "MSH_HUMAN_RESOURCE_MANAGEMENT",
      // Level 2
      "MSH_TIMEKEEPING_MANAGEMENT",
      // Level 3
      "MSH_LIST_TIMEKEEPING_MANAGEMENT",
      "MSH_PLACE_TIMEKEEPING_MANAGEMENT",
      "MSH_REPORT_TIMEKEEPING_MANAGEMENT",
    ],
    OnLeave: [
      // Level 1
      "MSH_HUMAN_RESOURCE_MANAGEMENT",
      // Level 2
      "MSH_LEAVE_MANAGEMENT",
    ],
    JobTitle: [
      // Level 1
      "MSH_HUMAN_RESOURCE_MANAGEMENT",
      // Level 2
      "MSH_MONTHLY_CONFIRMATION_OF_WORK_AND_TITLE",
    ],
    ReceiveSalary: [
      // Level 1
      "MSH_HUMAN_RESOURCE_MANAGEMENT",
      // Level 2
      "MSH_MONTHLY_SALARY_REQUEST",
    ],
    ProjectManagement: [
      // Level 1
      "MSH_SALES_MANAGEMENT",
      // Level 2
      "MSH_PROJECT_MANAGEMENT",
    ],
    Apartments: [
      // Level 1
      "MSH_SALES_MANAGEMENT",
      // Level 2
      "MSH_PRODUCT_AND_APARTMENT_MANAGEMENT",
    ],
    Booking: [
      // Level 1
      "MSH_SALES_MANAGEMENT",
      // Level 2
      "MSH_BOOKING_BATCH_MANAGEMENT",
    ],
    Contract: [
      // Level 1
      "MSH_SALES_MANAGEMENT",
      // Level 2
      "MSH_CONTRACT_MANAGEMENT",
    ],
    BookingManagement: [
      // Level 1
      "MSH_SALES_MANAGEMENT",
      // Level 2
      "MSH_BOOKINGMANAGEMENT_MANAGEMENT",
    ],
    Locking: [
      // Level 1
      "MSH_SALES_MANAGEMENT",
      // Level 2
      "MSH_LOCKING_MANAGEMENT",
    ],
    OpenForSale: [
      // Level 1
      "MSH_INVENTORY_MANAGEMENT",
      // Level 2
      "MSH_OPENING_SALE_MANAGEMENT",
    ],
    Customers: [
      // Level 1
      "MSH_INVENTORY_MANAGEMENT",
      // Level 2
      "MSH_CUSTOMER_MANAGEMENT",
    ],
    PersonnelCategories: [
      // Level 1
      "MSH_CATEGORIES_MANAGEMENT",
      // Level 2
      "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
      // Level 3
      "MSH_CATEGORIES_JOBTITLE_MANAGEMENT",
      "MSH_CATEGORIES_SUPPORT_DEPARTMENT_MANAGEMENT",
      "MSH_CATEGORIES_BRANCH_MANAGEMENT",
      "MSH_CATEGORIES_DEPARTMENT_MANAGEMENT",
      "MSH_CATEGORIES_SALE_AREA_MANAGEMENT",
      "MSH_CATEGORIES_SALE_BLOCK_MANAGEMENT",
      "MSH_CATEGORIES_BUSINESS_MANAGEMENT",
    ],
    OnLeaveCategories: [
      // Level 1
      "MSH_CATEGORIES_MANAGEMENT",
      // Level 2
      "MSH_CATEGORIES_WORK_AND_LEAVE_MANAGEMENT",
      // Level 3
      
    ],
    ProjectCategories: [
      // Level 1
      "MSH_CATEGORIES_MANAGEMENT",
      // Level 2
      "MSH_CATEGORIES_WORK_AND_LEAVE_MANAGEMENT",
      // Level 3
      "MSH_CATEGORIES_HOLIDAY_MANAGEMENT",
      "MSH_CATEGORIES_BUSINESS_CATEGORY_MANAGEMENT",
    ],
    CommissionConfirmation: [
      // Level 1
      "MSH_CATEGORIES_MANAGEMENT",
      // Level 2
      "MSH_CATEGORIES_COMMISSION_CONFIRMATION_MANAGEMENT",
      // Level 3
    ],
    AdminManagement: ["MSH_ADMIN_MANAGEMENT"],
  },
  MENU: {
    MENU_QLNS: {
      role: [
        // Level 1
        "MSH_HUMAN_RESOURCE_MANAGEMENT",
        // Level 2
        "MSH_EMPLOYEE_MANAGEMENT",
        "MSH_TIMEKEEPING_MANAGEMENT",
        "MSH_LEAVE_MANAGEMENT",
        "MSH_MONTHLY_CONFIRMATION_OF_WORK_AND_TITLE",
        "MSH_MONTHLY_SALARY_REQUEST",
        // Level 3
        "MSH_LIST_TIMEKEEPING_MANAGEMENT",
        "MSH_PLACE_TIMEKEEPING_MANAGEMENT",
        "MSH_REPORT_TIMEKEEPING_MANAGEMENT",
      ],
      CHILDREN: {
        CHILDREN_QLNV: {
          role: [
            // Level 1
            "MSH_HUMAN_RESOURCE_MANAGEMENT",
            // Level 2
            "MSH_EMPLOYEE_MANAGEMENT",
          ],
        },
        CHILDREN_QLCC: {
          role: [
            // Level 1
            "MSH_HUMAN_RESOURCE_MANAGEMENT",
            // Level 2
            "MSH_TIMEKEEPING_MANAGEMENT",
            // Level 3
            "MSH_LIST_TIMEKEEPING_MANAGEMENT",
            "MSH_PLACE_TIMEKEEPING_MANAGEMENT",
            "MSH_REPORT_TIMEKEEPING_MANAGEMENT",
          ],
          TAB: {
            TAB_DSCC: {
              role: [
                // Level 1
                "MSH_HUMAN_RESOURCE_MANAGEMENT",
                // Level 2
                "MSH_TIMEKEEPING_MANAGEMENT",
                // Level 3
                // "MSH_LIST_TIMEKEEPING_MANAGEMENT",
                "MSH_REPORT_TIMEKEEPING_MANAGEMENT",
              ],
            },
            TAB_DSDDCC: {
              role: [
                // Level 1
                "MSH_HUMAN_RESOURCE_MANAGEMENT",
                // Level 2
                "MSH_TIMEKEEPING_MANAGEMENT",
                // Level 3
                // "MSH_LIST_TIMEKEEPING_MANAGEMENT",
                "MSH_PLACE_TIMEKEEPING_MANAGEMENT",
              ],
            },
          },
        },
        CHILDREN_XNP: {
          role: [
            // Level 1
            "MSH_HUMAN_RESOURCE_MANAGEMENT",
            // Level 2
            "MSH_LEAVE_MANAGEMENT",
          ],
        },
        CHILDREN_XNC_AND_CDHT: {
          role: [
            // Level 1
            "MSH_HUMAN_RESOURCE_MANAGEMENT",
            // Level 2
            "MSH_MONTHLY_CONFIRMATION_OF_WORK_AND_TITLE",
          ],
        },
        CHILDREN_XNL: {
          role: [
            // Level 1
            "MSH_HUMAN_RESOURCE_MANAGEMENT",
            // Level 2
            "MSH_MONTHLY_SALARY_REQUEST",
          ],
        },
      },
    },
    MENU_QLNH: {
      role: [
        // Level 1
        "MSH_INVENTORY_MANAGEMENT",
        // Level 2
        "MSH_PROJECT_MANAGEMENT",
        "MSH_PRODUCT_AND_APARTMENT_MANAGEMENT",
        "MSH_BOOKING_BATCH_MANAGEMENT",
        "MSH_CONTRACT_MANAGEMENT",
      ],
      CHILDREN: {
        CHILDREN_QLDA: {
          role: [
            // Level 1
            "MSH_INVENTORY_MANAGEMENT",
            // Level 2
            "MSH_PROJECT_MANAGEMENT",
          ],
        },
        CHILDREN_QLSP_AND_CH: {
          role: [
            // Level 1
            "MSH_INVENTORY_MANAGEMENT",
            // Level 2
            "MSH_PRODUCT_AND_APARTMENT_MANAGEMENT",
          ],
        },
        CHILDREN_DN_BOOKING: {
          role: [
            // Level 1
            "MSH_INVENTORY_MANAGEMENT",
            // Level 2
            "MSH_BOOKING_BATCH_MANAGEMENT",
          ],
        },
        CHILDREN_CONTRACT: {
          role: [
            // Level 1
            "MSH_INVENTORY_MANAGEMENT",
            // Level 2
            "MSH_CONTRACT_MANAGEMENT",
          ],
        },
        CHILDREN_BOOKING: {
          role: [
            // Level 1
            "MSH_INVENTORY_MANAGEMENT",
            // Level 2
            "MSH_BOOKING_MANAGEMENT",
          ],
        },
      },
    },
    MENU_QLBH: {
      role: [
        // Level 1
        "MSH_SALES_MANAGEMENT",
        // Level 2
        "MSH_OPENING_SALE_MANAGEMENT",
        "MSH_CUSTOMER_MANAGEMENT",
        "MSH_BOOKINGMANAGEMENT_MANAGEMENT",
        "MSH_LOCKING_MANAGEMENT",
        "MSH_CONFIRMATION_SALES_CONTRACT",
      ],
      CHILDREN: {
        CHILDREN_QLDMB: {
          role: [
            // Level 1
            "MSH_SALES_MANAGEMENT",
            // Level 2
            "MSH_OPENING_SALE_MANAGEMENT",
          ],
        },
        CHILDREN_QLKH: {
          role: [
            // Level 1
            "MSH_SALES_MANAGEMENT",
            // Level 2
            "MSH_CUSTOMER_MANAGEMENT",
          ],
        },
        CHILDREN_QL_BOOKING: {
          role: [
            // Level 1
            "MSH_SALES_MANAGEMENT",
            // Level 2
            "MSH_BOOKINGMANAGEMENT_MANAGEMENT",
          ],
        },
        CHILDREN_QL_LOCKING: {
          role: [
            // Level 1
            "MSH_SALES_MANAGEMENT",
            // Level 2
            "MSH_LOCKING_MANAGEMENT",
          ],
        },
        CHILDREN_XNHDMB: {
          role: [
            // Level 1
            "MSH_SALES_MANAGEMENT",
            // Level 2
            "MSH_CONFIRMATION_SALES_CONTRACT",
          ],
        },
      },
    },
    MENU_QLDM: {
      role: [
        // Level 1
        "MSH_CATEGORIES_MANAGEMENT",
        // Level 2
        "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
        "MSH_CATEGORIES_WORK_AND_LEAVE_MANAGEMENT",
        "MSH_CATEGORIES_PROJECT_MANAGEMENT",
        "MSH_CATEGORIES_PRODUCT_MANAGEMENT",
        // Level 3
        "MSH_CATEGORIES_JOBTITLE_MANAGEMENT",
        "MSH_CATEGORIES_SUPPORT_DEPARTMENT_MANAGEMENT",
        "MSH_CATEGORIES_BRANCH_MANAGEMENT",
        "MSH_CATEGORIES_DEPARTMENT_MANAGEMENT",
        "MSH_CATEGORIES_SALE_AREA_MANAGEMENT",
        "MSH_CATEGORIES_SALE_BLOCK_MANAGEMENT",
        "MSH_CATEGORIES_BUSINESS_MANAGEMENT",
        "MSH_CATEGORIES_HOLIDAY_MANAGEMENT",
        "MSH_CATEGORIES_BUSINESS_CATEGORY_MANAGEMENT",
        "MSH_CATEGORIES_PROJECT_DIVISION_MANAGEMENT",
        "MSH_CATEGORIES_PROJECT_PROJECTTYPE_MANAGEMENT",
        "MSH_CATEGORIES_PROJECT_APARTMENTTYPE_MANAGEMENT",
        "MSH_CATEGORIES_PROJECT_INVESTOR_MANAGEMENT",
        "MSH_CATEGORIES_PROJECT_CITY_MANAGEMENT",
        "MSH_CATEGORIES_PROJECT_DIRECTION_MANAGEMENT",
        "MSH_CATEGORIES_PROJECT_PROPERTY_MANAGEMENT",
        "MSH_CATEGORIES_PROJECT_JOB_MANAGEMENT",
        "MSH_CATEGORIES_PROJECT_PDV_MANAGEMENT",
      ],
      CHILDREN: {
        CHILDREN_NS: {
          role: [
            // Level 1
            "MSH_CATEGORIES_MANAGEMENT",
            // Level 2
            "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
            // Level 3
            "MSH_CATEGORIES_JOBTITLE_MANAGEMENT",
            "MSH_CATEGORIES_SUPPORT_DEPARTMENT_MANAGEMENT",
            "MSH_CATEGORIES_BRANCH_MANAGEMENT",
            "MSH_CATEGORIES_DEPARTMENT_MANAGEMENT",
            "MSH_CATEGORIES_SALE_AREA_MANAGEMENT",
            "MSH_CATEGORIES_SALE_BLOCK_MANAGEMENT",
            "MSH_CATEGORIES_BUSINESS_MANAGEMENT",
          ],
          TAB: {
            TAB_CD_AND_PB_AND_CN: {
              role: [
                // Level 1
                "MSH_CATEGORIES_MANAGEMENT",
                // Level 2
                "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                // Level 3
                "MSH_CATEGORIES_JOBTITLE_MANAGEMENT",
                "MSH_CATEGORIES_SUPPORT_DEPARTMENT_MANAGEMENT",
                "MSH_CATEGORIES_BRANCH_MANAGEMENT",
              ],
              CHILDREN_TAB: {
                CHILDREN_TAB_DSCD: {
                  role: [
                    "MSH_CATEGORIES_MANAGEMENT",
                    "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                    "MSH_CATEGORIES_JOBTITLE_MANAGEMENT",
                  ],
                },
                CHILDREN_TAB_DSPB: {
                  role: [
                    "MSH_CATEGORIES_MANAGEMENT",
                    "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                    "MSH_CATEGORIES_SUPPORT_DEPARTMENT_MANAGEMENT",
                  ],
                },
                CHILDREN_TAB_DSCN: {
                  role: [
                    "MSH_CATEGORIES_MANAGEMENT",
                    "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                    "MSH_CATEGORIES_BRANCH_MANAGEMENT",
                  ],
                },
              },
            },
            TAB_KBP_AND_V_AND_K: {
              role: [
                // Level 1
                "MSH_CATEGORIES_MANAGEMENT",
                // Level 2
                "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                // Level 3
                "MSH_CATEGORIES_DEPARTMENT_MANAGEMENT",
                "MSH_CATEGORIES_SALE_AREA_MANAGEMENT",
                "MSH_CATEGORIES_SALE_BLOCK_MANAGEMENT",
                "MSH_CATEGORIES_BUSINESS_MANAGEMENT",
              ],
              CHILDREN_TAB: {
                CHILDREN_TAB_KBP: {
                  role: [
                    "MSH_CATEGORIES_MANAGEMENT",
                    "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                    "MSH_CATEGORIES_DEPARTMENT_MANAGEMENT",
                  ],
                },
                CHILDREN_TAB_V: {
                  role: [
                    "MSH_CATEGORIES_MANAGEMENT",
                    "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                    "MSH_CATEGORIES_SALE_AREA_MANAGEMENT",
                  ],
                },
                CHILDREN_TAB_K: {
                  role: [
                    "MSH_CATEGORIES_MANAGEMENT",
                    "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                    "MSH_CATEGORIES_SALE_BLOCK_MANAGEMENT",
                  ],
                },
                CHILDREN_TAB_PLNVKD: {
                  role: [
                    "MSH_CATEGORIES_MANAGEMENT",
                    "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                    "MSH_CATEGORIES_BUSINESS_MANAGEMENT",
                  ],
                },
              },
            },
            TAB_PJ: {
              role: [
                // Level 1
                "MSH_CATEGORIES_MANAGEMENT",
                // Level 2
                "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                // Level 3
                
              ],
              // CHILDREN_TAB: {
              //   CHILDREN_TAB_KBP: {
              //     role: [
              //       "MSH_CATEGORIES_MANAGEMENT",
              //       "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
              //       "MSH_CATEGORIES_DEPARTMENT_MANAGEMENT",
              //     ],
              //   },
              //   CHILDREN_TAB_V: {
              //     role: [
              //       "MSH_CATEGORIES_MANAGEMENT",
              //       "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
              //       "MSH_CATEGORIES_SALE_AREA_MANAGEMENT",
              //     ],
              //   },
              //   CHILDREN_TAB_K: {
              //     role: [
              //       "MSH_CATEGORIES_MANAGEMENT",
              //       "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
              //       "MSH_CATEGORIES_SALE_BLOCK_MANAGEMENT",
              //     ],
              //   },
              //   CHILDREN_TAB_PLNVKD: {
              //     role: [
              //       "MSH_CATEGORIES_MANAGEMENT",
              //       "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
              //       "MSH_CATEGORIES_BUSINESS_MANAGEMENT",
              //     ],
              //   },
              // },
            },
          },
        },
        CHILDREN_C_AND_NP: {
          role: [
            // Level 1
            "MSH_CATEGORIES_MANAGEMENT",
            // Level 2
            "MSH_CATEGORIES_WORK_AND_LEAVE_MANAGEMENT",
            // Level 3
            "MSH_CATEGORIES_HOLIDAY_MANAGEMENT",
            "MSH_CATEGORIES_BUSINESS_CATEGORY_MANAGEMENT",
          ],
        },
        CHILDREN_DA: {
          role: [
            // Level 1
            "MSH_CATEGORIES_MANAGEMENT",
            // Level 2
            "MSH_CATEGORIES_PROJECT_MANAGEMENT",
            // Level 3
            "MSH_CATEGORIES_PROJECT_DIVISION_MANAGEMENT",
            "MSH_CATEGORIES_PROJECT_PROJECTTYPE_MANAGEMENT",
            "MSH_CATEGORIES_PROJECT_APARTMENTTYPE_MANAGEMENT",
            "MSH_CATEGORIES_PROJECT_INVESTOR_MANAGEMENT",
            "MSH_CATEGORIES_PROJECT_CITY_MANAGEMENT",
            "MSH_CATEGORIES_PROJECT_DIRECTION_MANAGEMENT",
            "MSH_CATEGORIES_PROJECT_PROPERTY_MANAGEMENT",
            "MSH_CATEGORIES_PROJECT_JOB_MANAGEMENT",
            "MSH_CATEGORIES_PROJECT_PDV_MANAGEMENT",
          ],
          TAB: {
            TAB_DA: {
              role: [
                // Level 1
                "MSH_CATEGORIES_MANAGEMENT",
                // Level 2
                "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                // Level 3
                "MSH_CATEGORIES_PROJECT_DIVISION_MANAGEMENT",
                "MSH_CATEGORIES_PROJECT_PROJECTTYPE_MANAGEMENT",
                "MSH_CATEGORIES_PROJECT_APARTMENTTYPE_MANAGEMENT",
              ],
              CHILDREN_TAB: {
                CHILDREN_TAB_DSPK: {
                  role: [
                    "MSH_CATEGORIES_MANAGEMENT",
                    "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                    "MSH_CATEGORIES_PROJECT_DIVISION_MANAGEMENT",
                  ],
                },
                CHILDREN_TAB_LHDA: {
                  role: [
                    "MSH_CATEGORIES_MANAGEMENT",
                    "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                    "MSH_CATEGORIES_PROJECT_PROJECTTYPE_MANAGEMENT",
                  ],
                },
                CHILDREN_TAB_DSCH: {
                  role: [
                    "MSH_CATEGORIES_MANAGEMENT",
                    "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                    "MSH_CATEGORIES_PROJECT_APARTMENTTYPE_MANAGEMENT",
                  ],
                },
              },
            },
            TAB_CDT: {
              role: [
                // Level 1
                "MSH_CATEGORIES_MANAGEMENT",
                // Level 2
                "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                // Level 3
                "MSH_CATEGORIES_INVESTOR_MANAGEMENT",
              ],
              CHILDREN_TAB: {
                CHILDREN_TAB_KBP: {
                  role: [
                    "MSH_CATEGORIES_MANAGEMENT",
                    "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                    "MSH_CATEGORIES_INVESTOR_MANAGEMENT",
                  ],
                },
              },
            },
            TAB_KH: {
              role: [
                // Level 1
                "MSH_CATEGORIES_MANAGEMENT",
                // Level 2
                "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                // Level 3
                "MSH_CATEGORIES_PROJECT_CUSTOMER_TYPE_MANAGEMENT",
              ],
              CHILDREN_TAB: {
                CHILDREN_CUSTOMER_TYPE: {
                  role: [
                    "MSH_CATEGORIES_MANAGEMENT",
                    "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                    "MSH_CATEGORIES_PROJECT_CUSTOMER_TYPE_MANAGEMENT",
                  ],
                },
              },
            },
            TAB_K: {
              role: [
                // Level 1
                "MSH_CATEGORIES_MANAGEMENT",
                // Level 2
                "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                // Level 3
                "MSH_CATEGORIES_PROJECT_CITY_MANAGEMENT",
                "MSH_CATEGORIES_PROJECT_DIRECTION_MANAGEMENT",
                "MSH_CATEGORIES_PROJECT_PROPERTY_MANAGEMENT",
                "MSH_CATEGORIES_PROJECT_JOB_MANAGEMENT",
                "MSH_CATEGORIES_PROJECT_PDV_MANAGEMENT",
              ],
              CHILDREN_TAB: {
                CHILDREN_TAB_CITY: {
                  role: [
                    "MSH_CATEGORIES_MANAGEMENT",
                    "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                    "MSH_CATEGORIES_PROJECT_CITY_MANAGEMENT",
                  ],
                },
                CHILDREN_TAB_DIRECTION: {
                  role: [
                    "MSH_CATEGORIES_MANAGEMENT",
                    "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                    "MSH_CATEGORIES_PROJECT_DIRECTION_MANAGEMENT",
                  ],
                },
                CHILDREN_TAB_PROPERTY: {
                  role: [
                    "MSH_CATEGORIES_MANAGEMENT",
                    "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                    "MSH_CATEGORIES_PROJECT_PROPERTY_MANAGEMENT",
                  ],
                },
                CHILDREN_TAB_JOB: {
                  role: [
                    "MSH_CATEGORIES_MANAGEMENT",
                    "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                    "MSH_CATEGORIES_PROJECT_JOB_MANAGEMENT",
                  ],
                },
                CHILDREN_TAB_PDV: {
                  role: [
                    "MSH_CATEGORIES_MANAGEMENT",
                    "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                    "MSH_CATEGORIES_PROJECT_PDV_MANAGEMENT",
                  ],
                },
              },
            },
          },
        },
        CHILDREN_SP: {
          role: [
            // Level 1
            "MSH_CATEGORIES_MANAGEMENT",
            // Level 2
            "MSH_CATEGORIES_PRODUCT_MANAGEMENT",
          ],
        },
      },
    },
    MENU_QLDS: {
      role: [
        // Level 1
        "MSH_INCOME_MANAGEMENT",
        // Level 2
        "MSH_CACULATION_MANAGEMENT",
        "MSH_COMMISSION_CONFIRMATION",
        "MSH_REVENUE_MANAGEMENT",
      ],
      CHILDREN: {
        CHILDREN_THH: {
          role: [
            // Level 1
            "MSH_INCOME_MANAGEMENT",
            // Level 2
            "MSH_CACULATION_MANAGEMENT",
          ],
        },
        CHILDREN_XNHH: {
          role: [
            // Level 1
            "MSH_INCOME_MANAGEMENT",
            // Level 2
            "MSH_COMMISSION_CONFIRMATION",
          ],
        },
        CHILDREN_QLDS: {
          role: [
            // Level 1
            "MSH_INCOME_MANAGEMENT",
            // Level 2
            "MSH_REVENUE_MANAGEMENT",
          ],
        },
      },
    },
    MENU_QL_ADMIN: {
      role: [
        // Level 1
        "MSH_ADMIN_MANAGEMENT",
      ],
      CHILDREN: {},
    },
  },
};
