import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import iconExcel from "../../assets/images/employee/iconExcel.svg";
import plus from "../../assets/images/product/plus.svg";
import TableOpenForSale from "./tableOpenForSale";
import AddOpenForSale from "./formOpenForSale/addOpenForSale";
import { exportFile } from "../../utils/tools";
import toast from "react-hot-toast";
import { useSaleOpeningEvent } from "../../hooks/useSaleOpeningEvent";
import { saleOpeningEventCall } from "../../api/saleOpeningEvent";

export default function Booking() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [paramExport, setParamExport] = useState(null);

  const { refetch, isRefetching, entries, saleOpeningEventData } =
    useSaleOpeningEvent();

  const handleExport = async () => {
    const checkInColumnMapping = {
      code: "Mã đợt MB",
      name: "Tên đợt mở bán",
      project: "Tên dự án",
      product: "Sản phẩm",
      startTime: "TG bắt đầu",
      endTime: "TG kết thúc",
      price: "Số tiền booking",
      creator: "Nhân viên tạo",
      createdAt: "Ngày tạo",
    };
    if (saleOpeningEventData?.length) {
      const data = await saleOpeningEventCall.export(entries, paramExport);
      if (data) {
        exportFile(data, checkInColumnMapping, "bang_dot_mo_ban.xlsx");
        toast.success("Download file thành công");
      } else {
        toast.error("Không có dữ liệu");
      }
    } else toast.error("Không có dữ liệu");
  };

  const onSubmitAdd = async (data) => {
    const toastCreate = toast.loading("Đang thêm đợt mở bán ...");
    try {
      let filteredData = Object.fromEntries(
        Object.entries(data).filter(
          ([_, value]) => value !== "" && value !== undefined && value !== null
        )
      );
      await saleOpeningEventCall.create(filteredData);

      await refetch();
      onClose();
      toast.success("Thêm thành công");
    } catch (error) {
      console.log({ error });
      toast.dismiss(toastCreate);
    }
    toast.dismiss(toastCreate);
  };

  return (
    <Box
      w={{ base: "100%" }}
      backgroundColor="var(--color-backgroundmain)"
      p={{ base: "24px 16px 16px" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={{ base: "74px" }}>
          <Text fontSize={{ base: "20px" }} fontWeight={600}>
            Quản lý đợt mở bán
          </Text>
        </Flex>
        <Flex alignItems={"center"} gap={"16px"}>
          <Button
            display={"flex"}
            alignItems={"center"}
            p={"10px 16px 10px 12px"}
            gap={"8px"}
            h={"44px"}
            backgroundImage="var(--linear-gradient-excel)"
            _hover={{
              filter: "brightness(90%)",
            }}
            onClick={handleExport}
          >
            <Image src={iconExcel} />
            <Text
              fontSize={{ base: "15px" }}
              fontWeight={600}
              color="var(--color-main)"
            >
              Xuất excel
            </Text>
          </Button>
          <Button
            w={"176px"}
            h={"44px"}
            display={"flex"}
            alignItems={"center"}
            p={"10px 16px 10px 12px"}
            gap={"8px"}
            backgroundImage="var(--color-button)"
            _hover={{
              filter: "brightness(90%)",
            }}
            onClick={onOpen}
          >
            <Image src={plus} />
            <Text
              fontSize={{ base: "15px" }}
              fontWeight={600}
              color="var(--color-main)"
            >
              Đợt MBán mới
            </Text>
          </Button>
        </Flex>
      </Flex>
      <Box mt={{ base: "23px" }}>
        <TableOpenForSale
          isRefetchingWithCreate={isRefetching}
          paramData={setParamExport}
        />
      </Box>
      <AddOpenForSale
        isOpen={isOpen}
        onClose={onClose}
        isEdit={false}
        onSubmit={onSubmitAdd}
      />
    </Box>
  );
}
