import {
  loginFailed,
  loginStart,
  loginSuccess,
  logOutFailed,
  logOutStart,
  logOutSuccess,
} from "./authSlice";
import {
  deleteUserFailed,
  deleteUsersSuccess,
  deleteUserStart,
  getUsersFailed,
  getUsersStart,
  getUsersSuccess,
} from "./userSlice";
import { clientAPI } from "../../api/client";
import toast from "react-hot-toast";

export const loginUser = async (user, fcmToken, dispatch, navigate) => {
  dispatch(loginStart());
  try {
    const res = await clientAPI("post", "/employee/login", user);
    dispatch(loginSuccess(res));
    if (res?.accessToken && fcmToken) {
      await clientAPI("post", "/fcm/web/save-token", { token: fcmToken });
    }
    navigate("/");
    toast.success("Đăng nhập thành công");
  } catch (err) {
    dispatch(loginFailed());
    if (err.response) {
      const statusCode = err.response.status;
      const message = err.response.data.message;

      if (statusCode === 403) {
        toast.error("Không có quyền truy cập");
      } else if (statusCode === 404) {
        toast.error("Tài khoản không tồn tại!");
      } else if (statusCode === 401) {
        toast.error("Mật khẩu không chính xác!");
      } else {
        toast.error("Đăng nhập thất bại");
      }

      console.log({ message: message, status: statusCode });
    } else {
      toast.error("Đăng nhập thất bại");
      console.log({ message: err.message });
    }
  }
};

export const logOut = async (dispatch, navigate) => {
  dispatch(logOutStart());
  try {
    const res = await clientAPI("post", "/employee/logout");
    dispatch(logOutSuccess());
    navigate("/login");
  } catch (err) {
    dispatch(logOutFailed());
  }
};
