import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
  Button,
} from "@chakra-ui/react";
import CloseIconBlack from "../../../assets/images/apartment/x-circle.svg";
import {
  InputControl,
  SelectControl,
} from "../../../components/form/formControls";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useCategories } from "../../../hooks/useCategories";
import { formatPrice } from "../../../utils/utils";

const schema = Yup.object().shape({
  code: Yup.string().required("Mã căn hộ là bắt buộc"),
  name: Yup.string().required("Tên căn hộ là bắt buộc"),
  apartmentTypeId: Yup.string().required("Loại hình là bắt buộc"),
  directionId: Yup.string().required("Hướng là bắt buộc"),
  propertyId: Yup.string().required("Tính chất là bắt buộc"),
  projectId: Yup.string().required("Dự án là bắt buộc"),
  divisionId: Yup.string().required("Phân khu là bắt buộc"),
  area: Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      if (typeof originalValue === "string") {
        return originalValue.trim() === "" ? null : Number(originalValue);
      }
      return originalValue === null ? null : Number(originalValue);
    })
    .required("Diện tích đất là bắt buộc"),
  builtUpArea: Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      if (typeof originalValue === "string") {
        return originalValue.trim() === "" ? null : Number(originalValue);
      }
      return originalValue === null ? null : Number(originalValue);
    })
    .required("Diện tích xây dựng là bắt buộc"),
  length: Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      if (typeof originalValue === "string") {
        return originalValue.trim() === "" ? null : Number(originalValue);
      }
      return originalValue === null ? null : Number(originalValue);
    })
    .required("Chiều dài là bắt buộc"),
  width: Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      if (typeof originalValue === "string") {
        return originalValue.trim() === "" ? null : Number(originalValue);
      }
      return originalValue === null ? null : Number(originalValue);
    })
    .required("Chiều rộng là bắt buộc"),
  numberOfBedrooms: Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      if (typeof originalValue === "string") {
        return originalValue.trim() === "" ? null : Number(originalValue);
      }
      return originalValue === null ? null : Number(originalValue);
    })
    .required("Phòng ngủ là bắt buộc"),
  numberOfToilets: Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      if (typeof originalValue === "string") {
        return originalValue.trim() === "" ? null : Number(originalValue);
      }
      return originalValue === null ? null : Number(originalValue);
    })
    .required("Phòng vệ sinh là bắt buộc"),
  totalValue: Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      if (typeof originalValue === "string") {
        return originalValue.trim() === "" ? null : Number(originalValue);
      }
      return originalValue === null ? null : Number(originalValue);
    })
    .required("Giá trị là bắt buộc"),
  priceForCommission: Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      if (typeof originalValue === "string") {
        return originalValue.trim() === "" ? null : Number(originalValue);
      }
      return originalValue === null ? null : Number(originalValue);
    })
    .required("Giá tính hoa hồng là bắt buộc"),
});

const FormNew = ({ isOpen, onClose, isEdit, onSubmit, defaultValues }) => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues || {},
  });

  const [focusStates, setFocusStates] = useState({});
  const [displayValuePriceForCommission, setDisplayValuePriceForCommission] =
    useState(0);
  const [displayValueTotalValue, setDisplayValueTotalValue] = useState(0);
  const onChangePriceForCommission = useCallback(
    (event) => {
      const { value } = event.target;
      const numericValue = value.replace(/[^0-9]/g, "");
      setDisplayValuePriceForCommission(formatPrice(numericValue));
      setValue("priceForCommission", Number(numericValue), {
        shouldValidate: true,
      });
    },
    [setValue, watch, formatPrice]
  );
  const onChangeValueTotalValue = useCallback(
    (event) => {
      const { value } = event.target;
      const numericValue = value.replace(/[^0-9]/g, "");
      setDisplayValueTotalValue(formatPrice(numericValue));
      setValue("totalValue", Number(numericValue), { shouldValidate: true });
    },
    [setValue, watch, formatPrice]
  );

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
      if (defaultValues) {
        setDisplayValuePriceForCommission(
          formatPrice(defaultValues?.priceForCommission || 0)
        );
        setDisplayValueTotalValue(formatPrice(defaultValues?.totalValue || 0));
      }
    }
  }, [isOpen, defaultValues, reset]);

  const { constantData } = useCategories(null, watch("projectId"));

  const formatEntries = (entries) =>
    Object.entries(entries || {}).map(([key, value]) => ({
      value: key,
      label: value,
    }));

  const directions = useMemo(
    () => formatEntries(constantData?.direction),
    [constantData?.direction]
  );
  const projects = useMemo(
    () => formatEntries(constantData?.project),
    [constantData?.project]
  );
  const apartmentTypes = useMemo(
    () => formatEntries(constantData?.apartmentType),
    [constantData?.apartmentType]
  );
  const divisions = useMemo(
    () => formatEntries(constantData?.division),
    [constantData?.division]
  );
  const propertys = useMemo(
    () => formatEntries(constantData?.property),
    [constantData?.property]
  );

  const handleFocus = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: false }));
  };

  const onchangeApartmentType = (option) => {
    setValue("apartmentTypeId", option);
  };
  const onchangeDirection = (option) => {
    setValue("directionId", option);
  };
  const onchangeProperty = (option) => {
    setValue("propertyId", option);
  };
  const onchangeProject = (option) => {
    clearErrors("divisionId");
    setValue("projectId", option);
  };
  const onchangeDivision = (option) => {
    if (!watch("projectId")) {
      setError("divisionId", {
        type: "manual",
        message: "Bạn phải chọn dự án trước khi chọn phân khu",
      });
      return;
    }
    setValue("divisionId", option);
  };

  const submitHandler = (data) => {
    // console.log(data);
    onSubmit(data);
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="var(--color-main)"
        borderRadius={"16px"}
        h={"86.5vh"}
        w={"100%"}
        maxW={"625px"}
        overflowY={"auto"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
          "&::-moz-scrollbar": {
            width: "4px",
          },
          "&::-moz-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
        }}
      >
        <form onSubmit={handleSubmit(submitHandler)}>
          <Flex minH={"800px"} flexDirection={"column"}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"10px"}
              padding={"25px 24px"}
              borderBottom={"1px solid var(--bg-line-employee)"}
            >
              <Text
                color="var(--color-header-employee)"
                fontSize={"18px"}
                fontWeight={"600"}
              >
                Tạo căn hộ mới
              </Text>
              <Box cursor={"pointer"} onClick={onClose}>
                <Image src={CloseIconBlack} />
              </Box>
            </Flex>
            <Box flex={1} w={"545px"} m={"24px auto"}>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
                mb={"16px"}
              >
                <InputControl
                  label="Mã căn hộ"
                  placeholder="Nhập mã căn hộ"
                  errors={errors.code}
                  register={register("code")}
                  onFocus={() => handleFocus("code")}
                  onBlur={() => handleBlur("code")}
                  state={focusStates["code"]}
                />
                <InputControl
                  label="Tên căn hộ"
                  placeholder="Nhập tên căn hộ"
                  errors={errors.name}
                  register={register("name")}
                  onFocus={() => handleFocus("name")}
                  onBlur={() => handleBlur("name")}
                  state={focusStates["name"]}
                />
              </Flex>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
                mb={"16px"}
              >
                <SelectControl
                  label="Loại hình"
                  dataOption={apartmentTypes}
                  placeholder="Chọn loại hình căn hộ"
                  selectedMain={watch("apartmentTypeId")}
                  onChange={onchangeApartmentType}
                  onFocus={() => handleFocus("apartmentTypeId")}
                  onBlur={() => handleBlur("apartmentTypeId")}
                  state={focusStates["apartmentTypeId"]}
                  registerProps={register("apartmentTypeId")}
                  errorMessage={errors.apartmentTypeId}
                  mwidth="100%"
                />
                <SelectControl
                  label="Hướng"
                  dataOption={directions}
                  placeholder="Chọn hướng căn hộ"
                  selectedMain={watch("directionId")}
                  onChange={onchangeDirection}
                  onFocus={() => handleFocus("directionId")}
                  onBlur={() => handleBlur("directionId")}
                  state={focusStates["directionId"]}
                  registerProps={register("directionId")}
                  errorMessage={errors.directionId}
                  mwidth="100%"
                />
              </Flex>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
                mb={"16px"}
              >
                <SelectControl
                  label="Tính chất"
                  dataOption={propertys}
                  placeholder="Chọn tính chất căn hộ"
                  selectedMain={watch("propertyId")}
                  onChange={onchangeProperty}
                  onFocus={() => handleFocus("propertyId")}
                  onBlur={() => handleBlur("propertyId")}
                  state={focusStates["propertyId"]}
                  registerProps={register("propertyId")}
                  errorMessage={errors.propertyId}
                  mwidth="100%"
                />
                <SelectControl
                  label="Dự án"
                  dataOption={projects}
                  placeholder="Chọn dự án"
                  selectedMain={watch("projectId")}
                  onChange={onchangeProject}
                  onFocus={() => handleFocus("projectId")}
                  onBlur={() => handleBlur("projectId")}
                  state={focusStates["projectId"]}
                  registerProps={register("projectId")}
                  errorMessage={errors.projectId}
                  mwidth="100%"
                />
              </Flex>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
                mb={"16px"}
              >
                <SelectControl
                  label="Phân khu"
                  dataOption={divisions}
                  placeholder="Chọn phân khu"
                  selectedMain={watch("divisionId")}
                  onChange={onchangeDivision}
                  onFocus={() => handleFocus("divisionId")}
                  onBlur={() => handleBlur("divisionId")}
                  state={focusStates["divisionId"]}
                  registerProps={register("divisionId")}
                  errorMessage={errors.divisionId}
                />
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"17px"}
                >
                  <InputControl
                    type="number"
                    label="DT đất (m2)"
                    placeholder="ví dụ:120"
                    onFocus={() => handleFocus("area")}
                    onBlur={() => handleBlur("area")}
                    state={focusStates["area"]}
                    errors={errors.area}
                    register={register("area")}
                  />
                  <InputControl
                    type="number"
                    label="DT xây dựng (m2)"
                    placeholder="ví dụ:12"
                    onFocus={() => handleFocus("builtUpArea")}
                    onBlur={() => handleBlur("builtUpArea")}
                    state={focusStates["builtUpArea"]}
                    errors={errors.builtUpArea}
                    register={register("builtUpArea")}
                  />
                </Flex>
              </Flex>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
                mb={"16px"}
              >
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"17px"}
                >
                  <InputControl
                    type="number"
                    label="Chiều dài (m)"
                    placeholder="ví dụ:10"
                    onFocus={() => handleFocus("length")}
                    onBlur={() => handleBlur("length")}
                    state={focusStates["length"]}
                    errors={errors.length}
                    register={register("length")}
                  />
                  <InputControl
                    type="number"
                    label="Chiều rộng (m)"
                    placeholder="ví dụ:15"
                    onFocus={() => handleFocus("width")}
                    onBlur={() => handleBlur("width")}
                    state={focusStates["width"]}
                    errors={errors.width}
                    register={register("width")}
                  />
                </Flex>
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"17px"}
                  maxW={"259px"}
                >
                  <InputControl
                    type="number"
                    label="Phòng ngủ"
                    placeholder="Số phòng ngủ"
                    onFocus={() => handleFocus("numberOfBedrooms")}
                    onBlur={() => handleBlur("numberOfBedrooms")}
                    state={focusStates["numberOfBedrooms"]}
                    errors={errors.numberOfBedrooms}
                    register={register("numberOfBedrooms")}
                  />
                  <InputControl
                    type="number"
                    label="Phòng VS"
                    placeholder="Số phòng VS"
                    onFocus={() => handleFocus("numberOfToilets")}
                    onBlur={() => handleBlur("numberOfToilets")}
                    state={focusStates["numberOfToilets"]}
                    errors={errors.numberOfToilets}
                    register={register("numberOfToilets")}
                  />
                </Flex>
              </Flex>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
                mb={"16px"}
              >
                <InputControl
                  // type="number"
                  label="Giá trị"
                  placeholder="Ví dụ: 1000000000 "
                  onFocus={() => handleFocus("totalValue")}
                  onBlur={() => handleBlur("totalValue")}
                  state={focusStates["totalValue"]}
                  onChange={onChangeValueTotalValue}
                  value={displayValueTotalValue}
                  errors={errors.totalValue}
                  register={register("totalValue")}
                />
                <InputControl
                  // type="number"
                  label="Giá tính hoa hồng"
                  onChange={onChangePriceForCommission}
                  value={displayValuePriceForCommission}
                  placeholder="Ví dụ: 15000000000 "
                  onFocus={() => handleFocus("priceForCommission")}
                  onBlur={() => handleBlur("priceForCommission")}
                  state={focusStates["priceForCommission"]}
                  errors={errors.priceForCommission}
                  register={register("priceForCommission")}
                />
              </Flex>
            </Box>
            <Flex
              flex={1}
              pr={"24px"}
              alignItems={"center"}
              gap={"16px"}
              justifyContent={"flex-end"}
              // position={"sticky"}
              bottom={"24px"}
              right={"24px"}
              left={"50%"}
            >
              {isEdit ? (
                <>
                  <Button
                    w={"112px"}
                    h={"44px"}
                    lineHeight={"18px"}
                    fontSize={"15px"}
                    fontWeight={600}
                    color={"var(--color-option-employee-hover)"}
                    border={"1px solid var(--color-option-employee-hover)"}
                    bg={"var(--color-main)"}
                    onClick={onClose}
                    _hover={{
                      filter: "brightness(90%)",
                    }}
                  >
                    Hủy
                  </Button>
                  <Button
                    type="submit"
                    w={"112px"}
                    h={"44px"}
                    lineHeight={"18px"}
                    fontSize={"15px"}
                    fontWeight={600}
                    color={"var(--color-main)"}
                    bg={"var(--linear-gradient-employee)"}
                    _hover={{
                      filter: "brightness(90%)",
                    }}
                  >
                    Lưu
                  </Button>
                </>
              ) : (
                <Button
                  type="submit"
                  padding={"13px 20px"}
                  lineHeight={"18px"}
                  fontSize={"15px"}
                  fontWeight={600}
                  h={"44px"}
                  color={"var(--color-main)"}
                  bg={"var(--linear-gradient-employee)"}
                  _hover={{
                    filter: "brightness(90%)",
                  }}
                >
                  Thêm căn hộ
                </Button>
              )}
            </Flex>
          </Flex>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default FormNew;
