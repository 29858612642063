import { Box, Flex, GridItem, Image, Input, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import imageIcon from "../../assets/images/projectManagement/image.svg";
import trashIcon from "../../assets/images/projectManagement/trash-2.svg";
// import { ChevronDownIcon } from "@chakra-ui/icons";

export const FormImage = ({
  data,
  selectedMain,
  number,
  onChangeImage,
  onDeleteImage,
}) => {
  //   const [isFocused, setIsFocused] = useState(false);

  const changeImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      onChangeImage(data?.id, imageUrl);
    }
  };

  return (
    <GridItem>
      <Flex
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        gap={"8px"}
        w={"100%"}
        flexDirection={"column"}
      >
        <Text>Ảnh {number + 1}</Text>
        <Box
          position={"relative"}
          pt={"56.37%"}
          overflow={"hidden"}
          display={"block"}
          h={0}
          w={"100%"}
        >
          <Image
            src={data?.value}
            position={"absolute"}
            top={0}
            left={0}
            right={0}
            bottom={0}
            objectFit={"cover"}
            w={"100%"}
            h={"100%"}
            borderRadius={"8px"}
          />
          <Flex position={"absolute"} right={"8px"} bottom={"8px"}>
            <Box
              padding={"8px"}
              bg={"var(--color-main)"}
              borderRadius={"50%"}
              cursor={"pointer"}
              mr={"8px"}
              position={"relative"}
            >
              <Input
                type="file"
                accept="image/*"
                multiple
                opacity={0}
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                cursor="pointer"
                onChange={changeImage}
              />
              <Image
                src={imageIcon}
                //   onClick={deleteData}
                _hover={{
                  filter: "var(--filter-red)",
                }}
              />
            </Box>
            <Box
              padding={"8px"}
              bg={"var(--color-main)"}
              borderRadius={"50%"}
              cursor={"pointer"}
              onClick={() => onDeleteImage(data?.id)}
            >
              <Image
                src={trashIcon}
                //   onClick={deleteData}
                _hover={{
                  filter: "var(--filter-red)",
                }}
              />
            </Box>
          </Flex>
        </Box>
      </Flex>
    </GridItem>
  );
};

const API_URL = process.env.REACT_APP_BACKEND_API || "http://localhost:3001";
export const FormInputImage = ({
  data,
  onChangeImage,
  label,
  type,
  placeholder = "",
  value,
}) => {
  const [nameFile, setNameFile] = useState("");

  useEffect(() => {
    if (value) {
      setNameFile(value);
    }
  }, [value]);

  const changeImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNameFile(file?.name);
    }
    onChangeImage(event, type);
  };

  return (
    <GridItem>
      <Flex
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        gap={"8px"}
        w={"100%"}
        flexDirection={"column"}
      >
        <Text
          color={"var(--color-label-employee)"}
          fontSize={"12px"}
          fontWeight={400}
        >
          {label}
        </Text>
        <Flex
          position={"relative"}
          w={"100%"}
          border={"1px solid var(--border-input-employee)"}
          borderRadius={"8px"}
          p={"0 16px"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Input
            p={"0"}
            border={"none"}
            outline={"none"}
            fontSize={"14px"}
            sx={{
              "&::placeholder": {
                color: "var(--color-placeHolder-employee)",
              },
            }}
            color={"var(--color-secondary)"}
            placeholder={placeholder}
            value={nameFile}
            readOnly
            onClick={(e) => {
              e.stopPropagation();
              if (data?.path) {
                const url = API_URL + "/" + data?.path;
                window.open(url, "_blank"); // Mở URL trong tab mới
              }
            }}
            cursor={"pointer"}
          />
          <Input
            type="file"
            accept="image/*"
            multiple
            opacity={0}
            position="absolute"
            top={0}
            right={0}
            width="30%"
            height="100%"
            cursor="pointer"
            onChange={changeImage}
          />
          <Image
            cursor={"pointer"}
            src={imageIcon}
            //   onClick={deleteData}
            w={"16px"}
            h={"16px"}
            _hover={{
              filter: "var(--filter-red)",
            }}
          />
        </Flex>
      </Flex>
    </GridItem>
  );
};
