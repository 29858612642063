import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  HStack,
  Img,
  Button,
  Image,
  MenuItem,
  MenuList,
  MenuButton,
  Menu,
  useDisclosure,
  Skeleton,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { formatTableValue } from "./formatTable";
import chevronLeft from "../../assets/images/employee/chevron-left.svg";
import chevronRight from "../../assets/images/employee/chevron-right.svg";
import ReactPaginate from "react-paginate";
import SearchableSelect from "../../components/selectForm";
import iconEdit from "../../assets/images/product/edit.svg";
import icontrash from "../../assets/images/product/trash.svg";
import { AiOutlineMore } from "react-icons/ai";
import DeleteDialog from "../../components/dialog/delete";
import useDebounce from "../../hooks/useDebounce";
import { useApartment } from "../../hooks/useApartments";
import { useCategories } from "../../hooks/useCategories";
import toast from "react-hot-toast";
import FormNew from "./formApartments/fromNew";
import { apartmentCall } from "../../api/apartment";

const TableApartments = ({ isRefetchingWithCreate, paramData }) => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  const [popupDelete, setPopupDelete] = useState(false);
  const [search, setSearch] = useState("");
  const [projectId, setProjectId] = useState(null);
  const [apartmentTypeId, setApartmentTypeId] = useState(null);
  const [dataEdit, setDataEdit] = useState(null);

  const debouncedSearchTerm = useDebounce(search, 500);
  const onChangeSearch = useCallback((e) => {
    const { value } = e.target;
    setSearch(value);
  });

  const {
    apartmentData,
    refetch,
    isLoading,
    isRefetching,
    totalPages,
    setCurrentPage,
    currentPage,
    entries,
    startEntry,
    endEntry,
  } = useApartment(debouncedSearchTerm, projectId, apartmentTypeId);

  // refech create
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }
  const prevIsRefetchingWithCreate = usePrevious(isRefetchingWithCreate);

  useEffect(() => {
    if (
      isRefetchingWithCreate &&
      prevIsRefetchingWithCreate !== isRefetchingWithCreate
    ) {
      refetch();
    }
  }, [isRefetchingWithCreate, prevIsRefetchingWithCreate, refetch]);

  useEffect(() => {
    paramData({
      search: debouncedSearchTerm,
      projectId,
      apartmentTypeId,
    });
  }, [debouncedSearchTerm, projectId, apartmentTypeId]);

  const { constantData } = useCategories();

  const formatEntries = (entries) =>
    Object.entries(entries || {}).map(([key, value]) => ({ key, value }));

  const projects = useMemo(
    () => formatEntries(constantData?.project),
    [constantData?.project]
  );
  const apartmentTypes = useMemo(
    () => formatEntries(constantData?.apartmentType),
    [constantData?.apartmentType]
  );

  const handlePageChange = (selectedPage) => {
    setPopupDelete(false);
    setCurrentPage(selectedPage.selected + 1);
    refetch();
  };

  const tableData = {
    headers: [
      {
        label: "",
        key: "",
      },
      {
        label: "STT",
        key: "",
      },
      {
        label: "Mã căn hộ",
        key: "code",
      },
      {
        label: "Tên căn hộ",
        key: "name",
      },
      {
        label: "DT đất",
        key: "area",
      },
      {
        label: "DT xây dựng",
        key: "builtUpArea",
      },
      {
        label: "Chiều rộng",
        key: "width",
      },
      {
        label: "Chiều dài",
        key: "length",
      },
      {
        label: "Phòng ngủ",
        key: "numberOfBedrooms",
      },
      {
        label: "Phòng VS",
        key: "numberOfToilets",
      },
      {
        label: "Hướng",
        key: "direction",
      },
      {
        label: "Loại hình",
        key: "apartmentType",
      },
      {
        label: "Tính chất",
        key: "property",
      },
      {
        label: "Dự án",
        key: "project",
      },
      {
        label: "Phân khu",
        key: "division",
      },
      {
        label: "Giá trị",
        key: "totalValue",
      },
      {
        label: "Giá tính HH",
        key: "priceForCommission",
      },
    ],
    data: apartmentData,
  };

  const handlePopupDelete = (e) => {
    setPopupDelete(true);
    setDataEdit(e?.edit);
    onOpen();
  };

  const handlePopup = (e) => {
    setPopupDelete(false);
    setDataEdit(e?.edit);
    onOpen();
  };

  const onSubmitEdit = async (data) => {
    const toastCreate = toast.loading("Đang sửa căn hộ ...");
    try {
      const { id, ...dataEdit } = data;
      await apartmentCall.update(id, dataEdit);

      await refetch();
      onToggle();
      onClose();
      toast.success("Sửa thành công");
    } catch (error) {
      console.log({ error });
      toast.dismiss(toastCreate);
    }
    toast.dismiss(toastCreate);
  };

  const onSubmitDelete = async () => {
    const toastEdit = toast.loading("Đang xóa dữ liệu ...");
    try {
      const { id } = dataEdit; 
      await apartmentCall.delete(id);
      await refetch();
      setDataEdit(null);
      onClose();
      toast.success("Xóa thành công");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Có lỗi xảy ra khi xóa");
    }
    toast.dismiss(toastEdit);
  };

  return (
    <>
      <Flex flexDirection={"column"} gap={{ base: "16px" }}>
        <Flex
          backgroundColor="var(--color-main)"
          borderRadius={{ base: "12px" }}
          flexDirection={"column"}
        >
          <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={{ base: "32px" }}
            p={"16px 16px 12px 16px"}
          >
            <Flex alignItems={"center"} gap={{ base: "32px" }} flex={"1"}>
              <InputGroup
                maxW="506px"
                borderRadius={{ base: "8px" }}
                w={{ base: "100%" }}
              >
                <Input
                  placeholder="Nhập mã, tên căn hộ ..."
                  type="text"
                  borderRadius={{ base: "8px" }}
                  border={{ base: "1px solid var(--color-boder)" }}
                  onChange={onChangeSearch}
                />
                <InputRightElement
                  borderTopRightRadius={"8px"}
                  borderBottomRightRadius={"8px"}
                  pointerEvents="none"
                  cursor={"pointer"}
                  backgroundImage="var(--color-button)"
                >
                  <SearchIcon color="var(--color-main)" />
                </InputRightElement>
              </InputGroup>
              <Flex alignItems={"center"} gap={"24px"}>
                <SearchableSelect
                  isShowSearch={false}
                  options={projects}
                  initialSelectedOption={{ key: 0, value: "Chọn dự án" }}
                  setValue={setProjectId}
                />
                <SearchableSelect
                  isShowSearch={false}
                  options={apartmentTypes}
                  initialSelectedOption={{ key: 0, value: "Chọn loại hình" }}
                  setValue={setApartmentTypeId}
                />
              </Flex>
            </Flex>
          </Flex>
          <Box width={"100%"} height={"100%"}>
            <Skeleton isLoaded={!isLoading && !isRefetching}>
              <TableContainer w={"100%"}>
                <Table w={"100%"} variant="unstyled">
                  <Thead
                    w={"100%"}
                    h={"41px"}
                    color="white"
                    fontWeight="400"
                    backgroundColor="#F9FAFD"
                  >
                    <Tr h={{ base: "41px" }}>
                      {tableData.headers.map((e, index) => {
                        let width = "auto";
                        let textAlign = "auto";
                        if (e.key === "") {
                          width = "2%";
                          textAlign = "start";
                        } else if (
                          e.key === "code" ||
                          e.key === "name" ||
                          e.key === "area" ||
                          e.key === "builtUpArea" ||
                          e.key === "width" ||
                          e.key === "length"
                        ) {
                          width = "5%";
                          textAlign = "start";
                        } else if (
                          e.key === "numberOfBedrooms" ||
                          e.key === "numberOfToilets"
                        ) {
                          width = "5%";
                          textAlign = "center";
                        } else if (e.key === "project") {
                          width = "25%";
                          textAlign = "end";
                        } else {
                          width = "10%";
                          textAlign = "end";
                        }
                        return (
                          <Td
                            p={"16px 16px 8px 16px"}
                            key={index}
                            border={"none"}
                            color={"#51607B"}
                            fontSize={{ base: "14px" }}
                            w={width}
                          >
                            <Box textAlign={textAlign}>{e.label}</Box>
                          </Td>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody w={"100%"} h={"100%"}>
                    {tableData.data?.length > 0 ? (
                      tableData.data?.map((e, rowIndex) => {
                        const items = { ...e };
                        delete items.id;
                        delete items.edit;
                        delete items.detail;
                        const keyValues = Object.keys(items);
                        return (
                          <Tr key={rowIndex} h={"72px"}>
                            <Td
                              p={"16px 16px 8px 16px"}
                              w={"2%"}
                              bg={
                                rowIndex % 2 === 0
                                  ? "var(--color-main)"
                                  : "var(--fill-avatar)"
                              }
                            >
                              <Menu>
                                <MenuButton
                                  _hover={{ backgroundColor: "none" }}
                                  _active={{ backgroundColor: "none" }}
                                  background="none"
                                  as={Button}
                                >
                                  <AiOutlineMore fontSize={"20px"} />
                                </MenuButton>
                                <MenuList
                                  fontSize={{ base: "14px" }}
                                  fontWeight={500}
                                  lineHeight={"140%"}
                                  color={"var(--color-info-employee)"}
                                >
                                  <MenuItem
                                    gap={"16px"}
                                    onClick={() => handlePopup(e)}
                                  >
                                    <Image src={iconEdit} /> Chỉnh sửa
                                  </MenuItem>
                                  <MenuItem
                                    gap={"16px"}
                                    onClick={() => handlePopupDelete(e)}
                                  >
                                    <Image src={icontrash} /> Xóa SP căn hộ
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            </Td>
                            <Td
                              bg={
                                rowIndex % 2 === 0
                                  ? "var(--color-main)"
                                  : "var(--fill-avatar)"
                              }
                            >
                              {rowIndex + 1}
                            </Td>
                            {keyValues.map((keyvalue, index) => {
                              let width = "auto";
                              let widthText = "100%";
                              let textAlign = "auto";
                              let whiteSpace = "inherit";
                              if (keyvalue === "") {
                                width = "2%";
                                textAlign = "start";
                              } else if (
                                keyvalue === "code" ||
                                keyvalue === "name" ||
                                keyvalue === "area" ||
                                keyvalue === "builtUpArea" ||
                                keyvalue === "width" ||
                                keyvalue === "length"
                              ) {
                                width = "5%";
                                textAlign = "start";
                              } else if (
                                keyvalue === "numberOfBedrooms" ||
                                keyvalue === "numberOfToilets"
                              ) {
                                width = "5%";
                                textAlign = "center";
                              } else if (keyvalue === "project") {
                                width = "40%";
                                textAlign = "end";
                                whiteSpace = "normal";
                                widthText = "293px";
                              } else {
                                width = "10%";
                                textAlign = "end";
                              }
                              return (
                                <Td
                                  p={"16px 16px 8px 16px"}
                                  w={width}
                                  key={index}
                                  bg={
                                    rowIndex % 2 === 0
                                      ? "var(--color-main)"
                                      : "var(--fill-avatar)"
                                  }
                                >
                                  <Box
                                    fontSize={{ base: "14px" }}
                                    lineHeight={"19.6px"}
                                    textAlign={textAlign}
                                    fontWeight={500}
                                    color={"#293755"}
                                    w={widthText}
                                    whiteSpace={whiteSpace}
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    display="-webkit-box"
                                    sx={{
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                    }}
                                  >
                                    {formatTableValue(e[keyvalue], keyvalue)}
                                  </Box>
                                </Td>
                              );
                            })}
                          </Tr>
                        );
                      })
                    ) : (
                      <Tr>
                        <Td
                          colSpan={tableData.headers.length}
                          textAlign="center"
                          padding={"40px 0"}
                        >
                          Không có dữ liệu
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </Skeleton>
            <Box mt={10} mr={5} mb={5} ml={5}>
              <Flex alignItems={"center"} justifyContent={"space-between"}>
                <Text>
                  Showing {startEntry} to {endEntry} of {entries} entries
                </Text>
                <HStack spacing={2} justify="flex-end">
                  <ReactPaginate
                    className="pagination-custom"
                    pageCount={totalPages}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    breakClassName={"ellipsis"}
                    breakLabel={"..."}
                    previousLabel={
                      <Img
                        w={"16px"}
                        minW={"16px"}
                        h={"16px"}
                        src={chevronLeft}
                      />
                    }
                    nextLabel={
                      <Img
                        w={"16px"}
                        minW={"16px"}
                        h={"16px"}
                        src={chevronRight}
                      />
                    }
                    renderOnZeroPageCount={null}
                    forcePage={currentPage - 1}
                  />
                </HStack>
              </Flex>
            </Box>
          </Box>
        </Flex>
      </Flex>
      {!popupDelete ? (
        <FormNew
          isOpen={isOpen}
          onClose={onClose}
          isEdit={true}
          defaultValues={dataEdit}
          onSubmit={onSubmitEdit}
        />
      ) : (
        <DeleteDialog
          isOpen={isOpen}
          onClose={onClose}
          title="Xóa sản phẩm và căn hộ"
          description={
            <Flex display={"inline"}>
              "Bạn có chắc chắn muốn xóa sản phẩm và căn hộ{" "}
              <Text fontWeight={"600"} display={"inline"}>
                {dataEdit?.name}
              </Text>{" "}
              này không?"
            </Flex>
          }
          action={() => onSubmitDelete()}
        />
      )}
    </>
  );
};

export default TableApartments;
