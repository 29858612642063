import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
  Button,
  Stack,
  Checkbox,
  InputGroup,
  Input,
  InputRightElement,
} from "@chakra-ui/react";
import CloseIconBlack from "../../../assets/images/booking/x-circle.svg";
import CloseFile from "../../../assets/images/booking/Frame.svg";
import { useForm } from "react-hook-form";
import { SearchIcon } from "@chakra-ui/icons";
import { useCategories } from "../../../hooks/useCategories";
import PopupImport from "../../../components/popup/PopupImport";
import { exportFile } from "../../../utils/tools";
import toast from "react-hot-toast";
import { apartmentSaleOpeningEventLinkCall } from "../../../api/apartmentSaleOpeningEventLink";
import useDebounce from "../../../hooks/useDebounce";
import { useApartment } from "../../../hooks/useApartments";

const columnMappingReverse = {
  "Tên sản phẩm": "apartmentName",
  "Tên đợt mở bán": "saleOpeningEventName",
};

const AddProduct = ({ isOpen, onClose, data, defaultValues, onSubmit }) => {
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);
  const { apartmentData } = useApartment(
    debouncedSearch,
    defaultValues?.projectId
  );
  const constantData = apartmentData?.reduce(
    (acc, item) => ({ ...acc, [item.id]: item.name }),
    {}
  );
  const [popupVisible, setPopupVisible] = useState(false);

  const formatEntries = (entries) =>
    Object.entries(entries || {}).map(([key, value]) => ({ key, value }));

  const apartments = useMemo(
    () => formatEntries(constantData),
    [apartmentData]
  );

  const [selectedValues, setSelectedValues] = useState([]);

  const onChangeSearch = useCallback((e) => {
    const { value } = e.target;
    setSearch(value);
  });

  const fetchData = useCallback(async () => {
    try {
      const dataManagerEmployee =
        await apartmentSaleOpeningEventLinkCall.getBookingEventId(
          defaultValues?.id
        );

      if (dataManagerEmployee?.items) {
        dataManagerEmployee?.items.map((item) => {
          setSelectedValues((prev) => {
            return [...prev, item?.apartmentId];
          });
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [defaultValues?.id]);

  useEffect(() => {
    setSelectedValues([]);
    setSearch("");
    if (defaultValues?.id) {
      fetchData();
    } else {
      setSelectedValues([]);
    }
  }, [isOpen, popupVisible]);

  const handleCheckboxChange = (key) => {
    setSelectedValues((prev) => {
      if (prev.includes(key)) {
        return prev.filter((item) => item !== key);
      } else {
        return [...prev, key];
      }
    });
  };

  const { handleSubmit } = useForm({
    defaultValues: defaultValues || {},
  });

  const submitHandler = () => {
    if (Array.isArray(selectedValues) && defaultValues?.id) {
      const list = selectedValues.map((item) => ({
        apartmentId: item,
        saleOpeningEventId: defaultValues?.id,
      }));

      const data = {
        saleOpeningEventId: defaultValues?.id,
        list: list,
      };

      onSubmit(data);
    }
  };

  const handleExportSample = () => {
    const columnMapping = {
      apartmentName: "Tên sản phẩm",
      saleOpeningEventName: "Tên đợt mở bán",
    };
    const data = [
      {
        apartmentName: "Căn hộ APT",
        saleOpeningEventName: "Đợt mở bán 1",
      },
      {
        apartmentName: "Căn hộ C",
        saleOpeningEventName: "Đợt mở bán 1",
      },
      {
        apartmentName: "Căn hộ test",
        saleOpeningEventName: "Đợt mở bán 1",
      },
    ];
    try {
      exportFile(data, columnMapping, "bang_dot_mo_ban.xlsx");
      toast.success("Download file thành công");
    } catch (error) {
      toast.error("Export lỗi");
      console.log(error);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          maxW={"625px"}
          backgroundColor="var(--color-main)"
          borderRadius={"16px"}
          h={"85%"}
          maxH={"85%"}
        >
          <form
            style={{
              width: "100%",
            }}
            onSubmit={handleSubmit(submitHandler)}
          >
            <Box
              w={"100%"}
              overflowY={"auto"}
              pb={"92px"}
              sx={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                  width: "4px",
                  borderRadius: "50px",
                  background: "var(--color-background)",
                },
                "&::-moz-scrollbar": {
                  width: "4px",
                },
                "&::-moz-scrollbar-thumb": {
                  width: "4px",
                  borderRadius: "50px",
                  background: "var(--color-background)",
                },
              }}
            >
              <Flex flexDirection={"column"}>
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  gap={"10px"}
                  padding={"25px 24px"}
                  borderBottom={"1px solid var(--bg-line-employee)"}
                >
                  <Text
                    color="var(--color-header-employee)"
                    fontSize={"18px"}
                    fontWeight={"600"}
                  >
                    Thêm sản phẩm vào {defaultValues?.name || "đợt nhận booking"}
                  </Text>
                  <Box cursor={"pointer"} onClick={onClose}>
                    <Image src={CloseIconBlack} />
                  </Box>
                </Flex>
                <Box flex={1} w={"578px"} m={"24px auto"}>
                  <Text
                    fontSize={"16px"}
                    fontWeight={"600"}
                    lineHeight={"19px"}
                    textTransform={"uppercase"}
                    mb={"12px"}
                  >
                    {defaultValues?.projectName || "Tên dự án"}
                  </Text>
                  <InputGroup
                    borderRadius={{ base: "8px" }}
                    w={{ base: "100%" }}
                  >
                    <Input
                      placeholder="Nhập Mã ĐNBK,tên,..."
                      type="text"
                      borderRadius={{ base: "8px" }}
                      border={{ base: "1px solid var(--color-boder)" }}
                      backgroundColor={"var(--fill-avatar)"}
                      onChange={onChangeSearch}
                    />
                    <InputRightElement
                      borderTopRightRadius={"8px"}
                      borderBottomRightRadius={"8px"}
                      pointerEvents="none"
                      cursor={"pointer"}
                      backgroundImage="var(--color-button)"
                    >
                      <SearchIcon color="var(--color-main)" />
                    </InputRightElement>
                  </InputGroup>
                  <Box mt={"24px"}>
                    <Stack gap={"12px"} flexWrap={"wrap"} flexDirection={"row"}>
                      {apartments.map((item, key) => (
                        <Checkbox
                          key={key}
                          p={"16px 20px"}
                          gap={"24px"}
                          fontSize={"14px"}
                          lineHeight={"17px"}
                          fontWeight={"500"}
                          maxW={"283px"}
                          w={"100%"}
                          border={"1px solid var(--color-boder)"}
                          borderRadius={"8px"}
                          onChange={() => handleCheckboxChange(item.key)}
                          isChecked={selectedValues.includes(item.key)}
                        >
                          {item.value}
                        </Checkbox>
                      ))}
                    </Stack>
                  </Box>
                </Box>
                <Box
                  position={"fixed"}
                  bottom={"40px"}
                  w={"100%"}
                  borderTop={" 0.5px solid var(--border-input-employee)"}
                  maxWidth={"625px"}
                >
                  <Flex
                    w={"100%"}
                    bg={"var(--color-main)"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    p={"24px"}
                    borderBottomLeftRadius={"8px"}
                    borderBottomRightRadius={"8px"}
                  >
                    <Button
                      display="flex"
                      alignItems="center"
                      p="10px 16px"
                      h={"44px"}
                      gap="8px"
                      backgroundImage="var(--color-button)"
                      onClick={() => setPopupVisible(true)}
                      _hover={{
                        filter: "brightness(90%)",
                      }}
                    >
                      <Image src={CloseFile} />
                      <Text
                        fontSize="15px"
                        fontWeight={600}
                        color="var(--color-main)"
                      >
                        Tải file lên
                      </Text>
                    </Button>
                    <Flex gap={"16px"} justifyContent={"flex-end"}>
                      <Button
                        w={"112px"}
                        h={"44px"}
                        color={"var(--color-option-employee-hover)"}
                        border={"1px solid var(--color-option-employee-hover)"}
                        bg={"var(--color-main)"}
                        onClick={onClose}
                        _hover={{
                          filter: "brightness(90%)",
                        }}
                      >
                        Hủy
                      </Button>
                      <Button
                        type="submit"
                        w={"112px"}
                        h={"44px"}
                        color={"var(--color-main)"}
                        bg={"var(--color-button)"}
                        _hover={{
                          filter: "brightness(80%)",
                        }}
                      >
                        Xong
                      </Button>
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </form>
        </ModalContent>
      </Modal>
      {popupVisible && (
        <PopupImport
          isOpen={popupVisible}
          onClose={() => setPopupVisible(false)}
          refetchCheckInData={fetchData}
          columnMappingReverse={columnMappingReverse}
          handleExport={handleExportSample}
          titleAdd="Thêm danh sách sản phẩm"
          inputAdd="Nhập danh sách sản phẩm"
          type={"apartmentSaleOpeningEventLink"}
          id={defaultValues?.id}
        />
      )}
    </>
  );
};

export default AddProduct;
