import React, { useState } from "react";

import {
  Box,
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import SalesPerson from "./salesPerson";
import { useTab } from "../../contexts/useTab";
import BusinessSupportStaff from "./businessSupportStaff";
import iconButton from "../../assets/images/employee/icon-button.svg";
import FormEmployee from "../../components/form/formEmployee";
import { useEmployee } from "../../hooks/useEmployee";
import { commonCall } from "../../api/common";
import toast from "react-hot-toast";
import { employeeCall } from "../../api/employee";
import iconExcel from "../../assets/images/employee/iconExcel.svg";
import { exportFile } from "../../utils/tools";
import { businessCategoryCall } from "../../api/businessCategory";

export default function Employee() {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const [paramSalesPerson, setParamSalesPerson] = useState(null);
  const [paramBusinessSupportStaff, setParamBusinessSupportStaff] =
    useState(null);

  const { employeeTab, setEmployeeTab } = useTab();
  const dataSalesPerson = useEmployee(1);
  const dataBusinessSupportStaff = useEmployee(0);

  const { refetch, isRefetching } = useEmployee(employeeTab);

  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const tabEmployee = [
    {
      title: "Nhân viên khối kinh doanh",
      content: (
        <SalesPerson
          department={1}
          isRefetchingWithCreate={isRefetching}
          paramData={setParamSalesPerson}
        />
      ),
    },
    {
      title: "NV khối hỗ trợ kinh doanh",
      content: (
        <BusinessSupportStaff
          department={0}
          isRefetchingWithCreate={isRefetching}
          paramData={setParamBusinessSupportStaff}
        />
      ),
    },
    {
      title: "Ban giám đốc",
      content: (
        <SalesPerson
          department={2}
          isRefetchingWithCreate={isRefetching}
          paramData={setParamSalesPerson}
        />
      ),
    },
  ];
  const onSubmit = async (data) => {
    const toastCreate = toast.loading("Đang thêm nhân viên ...");

    try {
      let filteredData = Object.fromEntries(
        Object.entries(data).filter(
          ([_, value]) => value !== "" && value !== undefined && value !== null
        )
      );
      if (filteredData?.file) {
        const { filePath } = await commonCall.uploadAvatar(filteredData?.file);
        if (filePath) filteredData.avatar = filePath;
      }
      delete filteredData.file;
      // test
      // filteredData.code = `MSH${Math.floor(1000 + Math.random() * 9000)}`;
      //
      await employeeCall.create({ ...filteredData });
      await refetch();
      onToggle();
      toast.success("Thêm thành công");
    } catch (error) {
      console.log({ error });
      toast.dismiss(toastCreate);
    }
    toast.dismiss(toastCreate);
  };

  const handleExport = async () => {
    const employeeColumnMapping = {
      avatar: "Ảnh đại diện",
      code: "Mã nhân viên",
      fullName: "Họ tên",
      department: "Loại nhân viên",
      jobPosition: "Chức vụ",
      supportDepartment: "Phòng ban",
      businessCategory: "Loại nhân viên kinh doanh",
      guaranteeType: "Loại bảo lãnh",
      guaranteeDate: "Ngày bảo lãnh",
      jobTitleId: "Chức danh",
      saleBlock: "Khối",
      saleArea: "Vùng",
      branch: "Chi nhánh",
      phone: "SĐT",
      email: "Email",
      identificationNumber: "Số CCCD",
      dateOfIdentification: "Ngày cấp CCCD",
      placeOfIdentification: "Nơi cấp CCCD",
      currentAddress: "Chỗ ở hiện tại",
      bankAccount: "STK",
      bankName: "Ngân hàng",
      approvedDatess: "Địa chỉ thường trú",
      startTime: "Ngày vào làm",
      endTime: "Ngày nghỉ",
      status: "TTHĐ",
      customDateFormat: "Ngày ký quỹ",
    };

    if (Number(employeeTab) === 1) {
      const entries = dataBusinessSupportStaff?.entries || 999;
      const data = await businessCategoryCall.export(
        entries,
        0,
        paramBusinessSupportStaff
      );
      if (data) {
        exportFile(
          data,
          employeeColumnMapping,
          "bang_nhan_vien_khoi_ho_tro_kinh_doanh.xlsx"
        );
        toast.success("Download file thành công");
      } else {
        toast.error("Không có dữ liệu");
      }
    } else {
      const entries = dataSalesPerson?.entries || 999;
      const data = await businessCategoryCall.export(
        entries,
        1,
        paramSalesPerson
      );
      if (data) {
        exportFile(
          data,
          employeeColumnMapping,
          "bang_nhan_vien_khoi_kinh_doanh.xlsx"
        );
        toast.success("Download file thành công");
      } else {
        toast.error("Không có dữ liệu");
      }
    }
  };

  return (
    <Box
      minH="calc(100vh - 80px)"
      w={{ base: "100%" }}
      backgroundColor="var(--color-backgroundmain)"
      p={{ base: "24px 16px 16px 15px" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={{ base: "74px" }}>
          <Text fontSize={{ base: "20px" }} fontWeight={600}>
            Quản lý nhân viên
          </Text>
          <Flex
            justifyContent={{ base: "space-around", xl: "flex-start" }}
            gap={{ base: "24px" }}
          >
            {tabEmployee.map((e, index) => {
              return (
                <Flex
                  alignItems={"center"}
                  justifyContent={"center"}
                  key={index}
                  onClick={() => setEmployeeTab(index)}
                  cursor={"pointer"}
                  padding={"12px 5px 0px 5px"}
                  zIndex={"10"}
                >
                  <Text
                    position={"relative"}
                    paddingBottom={"8px"}
                    _before={{
                      content: '""',
                      position: "absolute",
                      bottom: "-1px",
                      left: 0,
                      width: "100%",
                      height: {
                        base: employeeTab === index ? "2px" : "0",
                      },
                      backgroundColor: "var(--color-secondary)",
                    }}
                    fontSize={{
                      base: "16px",
                    }}
                    fontWeight={600}
                    lineHeight={"normal"}
                    fontFamily="var(--font-heading-main)"
                    color={
                      employeeTab === index
                        ? "var(--color-secondary)"
                        : "#9D9D9D"
                    }
                    textAlign={"center"}
                    whiteSpace="nowrap"
                  >
                    {e?.title}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
        <Flex gap={"16px"}>
          <Button
            display={"flex"}
            alignItems={"center"}
            p={"10px 16px 10px 12px"}
            gap={"8px"}
            h={"44px"}
            backgroundImage="var(--linear-gradient-excel)"
            _hover={{
              filter: "brightness(90%)",
            }}
            onClick={handleExport}
          >
            <Image src={iconExcel} />
            <Text
              fontSize={{ base: "15px" }}
              fontWeight={600}
              color="var(--color-main)"
            >
              Xuất excel
            </Text>
          </Button>
          <Menu>
            <MenuButton
              w={"180px"}
              h={"44px"}
              as={Button}
              backgroundImage="var(--color-button)"
              _hover={{
                filter: "brightness(90%)",
              }}
              _active={{
                filter: "brightness(90%)",
              }}
            >
              <Flex alignItems={"center"} p={"10px 0px 10px 0px"} gap={"8px"}>
                <Image src={iconButton} />
                <Text
                  fontSize={{ base: "15px" }}
                  fontWeight={600}
                  color="var(--color-main)"
                >
                  Thêm nhân viên
                </Text>
              </Flex>
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  setSelectedDepartment(0);
                  onOpen();
                }}
              >
                Khối hỗ trợ kinh doanh
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSelectedDepartment(1);
                  onOpen();
                }}
              >
                Khối kinh doanh
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSelectedDepartment(2);
                  onOpen();
                }}
              >
                Ban giám đốc
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>

        {/* <Button
          display={"flex"}
          alignItems={"center"}
          p={"10px 16px 10px 12px"}
          gap={"8px"}
          backgroundImage="var(--color-button)"
          onClick={onOpen}
          _hover={{
            filter: "brightness(90%)",
          }}
        >
          <Image src={iconButton} />
          <Text
            fontSize={{ base: "15px" }}
            fontWeight={600}
            color="var(--color-main)"
          >
            Thêm nhân viên
          </Text>
        </Button> */}
      </Flex>
      <Box mt={{ base: "23px" }}>{tabEmployee[employeeTab].content}</Box>
      <FormEmployee
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        department={selectedDepartment}
      />
    </Box>
  );
}
