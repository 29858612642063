import {
  Box,
  Button,
  Collapse,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useState } from "react";
import rateIcon from "../../assets/images/projectManagement/rate.svg";
import { formatTableValue } from "../../pages/commissionConfirmation/formatTable";
import iconApproved from "../../assets/images/receiveSalary/check-circle.svg";
import ChevronUpIcon from "../../assets/images/chevron-up.png";
import ChevronDownIcon from "../../assets/images/chevron-down.svg";
import { useForm } from "react-hook-form";
import { formatDateDb } from "../../utils/tools";

const FormAdvanceRate = ({
  period,
  calculateCommissionRate,
  success,
  commission,
  onChangeJobTitle,
  date,
  onChangeBonus,
  checkRate,
  onHandlePay,
  openItems,
  toggleContent
}) => {
  const { register } = useForm();

  const [isPay, checkIsPay] = useState(true);


  const dataTable = {
    headers: [
      {
        label: "Diễn giải",
        key: "interpretation",
      },
      {
        label: "Nhân viên",
        key: "employee",
      },
      {
        label: "Ngày công",
        key: "workingDay",
      },
      {
        label: "Hoa hồng",
        key: "serviceFee",
      },
    ],
    data: commission,
  };

  const onCheckRate = () => {
    checkIsPay(checkRate());

    if(checkRate() && onHandlePay){
      onHandlePay(calculateCommissionRate);
    }
  };

  const onChangeActionBonus = (value) => {
    checkIsPay(true);
    onChangeBonus(value.target.value);
  };

  return (
    <>
      <Flex justify="space-between" align="center" mb={4} mt={"16px"}>
        <Text
          fontSize={"16px"}
          lineHeight={"19px"}
          fontWeight={"600"}
          color={"var(--color-header-employee)"}
        >
          Bảng tính hoa hồng nhân viên tham gia - Đợt {period}
        </Text>
        <Button
          bg={"none"}
          outline={"none"}
          border={"none"}
          p={"0px"}
          m={"0px"}
          onClick={() => toggleContent(period)}
          rightIcon={
            openItems ? (
              <Image src={ChevronDownIcon} />
            ) : (
              <Image src={ChevronUpIcon} />
            )
          }
          size="sm"
        ></Button>
      </Flex>
      <Collapse in={openItems} animateOpacity>
        <Box maxW={"545px"} margin={"0 auto"} w={"100%"} pt={"16px"}>
          {success ? (
            <Flex
              w={"100%"}
              p={"14px 16px"}
              alignItems={"center"}
              gap={"27px"}
              justifyContent={"space-between"}
              bg={"var(--color-background-2)"}
              borderRadius={"8px"}
            >
              <Box>
                <Text fontSize={"12px"} color={"var(--color-black-4)"}>
                  Tỷ lệ thanh toán tương ứng
                </Text>
                <Text
                  mt={"8px"}
                  fontSize={"14px"}
                  color={"var(--color-info-employee)"}
                  fontWeight={500}
                >
                  {calculateCommissionRate}%
                </Text>
              </Box>
              <Flex alignItems={"center"} gap={"8px"}>
                <Image src={iconApproved} />
                <Text
                  color={"var(--color-status-employ)"}
                  fontSize={"14px"}
                  fontWeight={500}
                >
                  Đã chốt ngày {date ? formatDateDb(date) : 'date'}
                </Text>
              </Flex>
            </Flex>
          ) : (
            <form>
              <Flex w={"100%"} gap={"36px"} alignItems={"flex-end"}>
                <FormControl
                  display={"flex"}
                  flexDirection={"column"}
                  gap={{ base: "6px" }}
                  maxW={"259px"}
                >
                  <FormLabel
                    fontSize={"12px"}
                    fontWeight={400}
                    color="var(--text-color-Subtittle)"
                    lineHeight={"15px"}
                    m={{ base: "0" }}
                  >
                    Tỷ lệ đợt ứng
                  </FormLabel>
                  <Flex
                    justifyContent={"space-between"}
                    gap={"8px"}
                    alignItems={"center"}
                    flexDirection={"column"}
                  >
                    <InputGroup
                      mt={"0"}
                      p={{ base: "10px 14px 10px 12px" }}
                      alignItems={"center"}
                      h={"44px"}
                      border={"0.5px solid var(--Line)"}
                      borderRadius={"8px"}
                      _focus={{
                        boxShadow: "none",
                      }}
                    >
                      <Input
                        type="number"
                        color="var(--text-color-Subtittle)"
                        fontWeight={500}
                        fontSize={"14px"}
                        border={"none"}
                        p={"0px"}
                        placeholder={
                          onChangeJobTitle ? "0.0" : "Nhập hệ số thưởng"
                        }
                        // type={"text"}
                        _focus={{
                          boxShadow: "none",
                        }}
                        min={0}
                        max={100}
                        borderRight={"1px solid #DAE0EB"}
                        borderRadius={"0"}
                        h={"24px"}
                        value={calculateCommissionRate || ""}
                        onChange={(e) => onChangeActionBonus(e)}
                        step="any"
                        {...register}
                      />
                      <Image src={rateIcon} ml={"16px"} />
                    </InputGroup>
                    {!isPay && (
                      <Text
                        fontSize={{ base: "12px" }}
                        color={"red"}
                        lineHeight={"15px"}
                      >
                        Tổng tỉ lệ tương ứng phải nhỏ hơn 100%
                      </Text>
                    )}
                  </Flex>
                </FormControl>
                <Button
                  p={"13px 32px"}
                  h={"44px"}
                  minH={"44px"}
                  lineHeight={"18px"}
                  fontSize={"15px"}
                  fontWeight={600}
                  color={"var(--color-main)"}
                  borderRadius={"8px"}
                  bg={"var(--linear-gradient-employee)"}
                  _hover={{
                    filter: "brightness(90%)",
                  }}
                  mb={!isPay ? "23px" : "0px"}
                  onClick={() => onCheckRate()}
                >
                  Tính toán
                </Button>
              </Flex>
            </form>
          )}

          <Box
            background={"var(--fill-avatar)"}
            mt={"16px"}
            borderRadius={"8px"}
          >
            <TableContainer w={"100%"}>
              <Table w={"100%"} variant="unstyled">
                <Thead
                  w={"100%"}
                  h={"41px"}
                  color="white"
                  fontWeight="400"
                  backgroundColor="#F9FAFD"
                  borderBottom={"1px solid var(--bg-line-employee)"}
                >
                  <Tr h={{ base: "41px" }}>
                    {dataTable.headers.map((e, index) => {
                      let width = "auto";
                      let textAlign = "auto";
                      if (e.key === "employee") {
                        width = "2%";
                        textAlign = "start";
                      } else if (e.key === "employeeJobTitle") {
                        width = "5%";
                        textAlign = "start";
                      } else {
                        width = "10%";
                        textAlign = "start";
                      }
                      return (
                        <Td
                          p={"16px 16px 8px 16px"}
                          key={index}
                          border={"none"}
                          color={"#51607B"}
                          fontSize={{ base: "14px" }}
                          w={width}
                        >
                          <Box textAlign={textAlign}>{e.label}</Box>
                        </Td>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody w={"100%"} h={"100%"}>
                  {dataTable.data?.map((e, rowIndex) => {
                    const keyValues = Object.keys(e);
                    return (
                      <Tr
                        key={rowIndex}
                        h={"44px"}
                        backgroundColor={
                          rowIndex % 2 === 0
                            ? "var(--color-main)"
                            : "var(--fill-avatar)"
                        }
                      >
                        {keyValues.map((keyvalue, index) => {
                          let width = "auto";
                          let widthText = "100%";
                          let textAlign = "auto";
                          let whiteSpace = "inherit";
                          if (keyvalue === "employee") {
                            width = "40%";
                            textAlign = "start";
                          } else if (keyvalue === "employeeJobTitle") {
                            width = "40%";
                            textAlign = "start";
                          } else {
                            width = "20%";
                            textAlign = "start";
                            whiteSpace = "inherit";
                          }
                          return (
                            <Td p={"16px 16px 8px 16px"} w={width} key={index}>
                              <Box
                                fontSize={{ base: "14px" }}
                                lineHeight={"19.6px"}
                                textAlign={textAlign}
                                fontWeight={500}
                                color={"var(--color-info-employee)"}
                                w={widthText}
                                whiteSpace={whiteSpace}
                                overflow="hidden"
                                textOverflow="ellipsis"
                                display="-webkit-box"
                                sx={{
                                  WebkitLineClamp: "2",
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                {formatTableValue(e[keyvalue], keyvalue)}
                              </Box>
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Collapse>
      {success ? (
        <Box
          mt={success > 0 ? "24px" : "0px"}
          h={"1px"}
          w={"100%"}
          bg={"var(--color-black-2)"}
        ></Box>
      ) : (
        ""
      )}
    </>
  );
};

export default FormAdvanceRate;
