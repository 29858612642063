import {
  Box,
  Flex,
  Text,
  Modal,
  ModalContent,
  ModalOverlay,
  Image,
  Button,
} from "@chakra-ui/react";
import React, { memo, useEffect, useState } from "react";
import IconX from "../../../assets/images/booking/x-circle.svg";
import { InputControl } from "../../../components/form/formControls";
import { useForm } from "react-hook-form";
const baseURL = process.env.REACT_APP_BACKEND_API || "http://localhost:3001";

const FormDetail = memo(
  ({ isOpen, onClose, onSubmit, isEdit, defaultValues }) => {
    const {
      reset,
      register,
      handleSubmit,
      watch,
      formState: { errors },
    } = useForm({
      defaultValues: defaultValues || {},
    });

    const [focusStates, setFocusStates] = useState({});
    const [infident1, setInfident1] = useState(null);
    const [infident2, setInfident2] = useState(null);
    const [imagePayment, setImagePayment] = useState(null);
    const [inputColors, setInputColors] = useState({
      dateStart: "var(--text-gray-form)",
      dateEnd: "var(--text-gray-form)",
    });

    useEffect(() => {
      reset(defaultValues);
      if (defaultValues?.id) {
        const fetchData = async () => {
          try {
            setInfident1(null);
            setInfident2(null);
            setImagePayment(null);

            if (defaultValues?.images && Array.isArray(defaultValues?.images)) {
              for (const item of defaultValues?.images) {
                if (item?.type === "IDENTIFICATION_BEFORE") {
                  setInfident1(item?.path);
                }
                if (item?.type === "IDENTIFICATION_AFTER") {
                  setInfident2(item?.path);
                }
                if (item?.type === "TRANSACTION") {
                  setImagePayment(item?.path);
                }
              }
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        fetchData();
      }
    }, [isOpen]);

    const handleFocus = (field) => {
      setFocusStates((prev) => ({ ...prev, [field]: true }));
    };

    const handleBlur = (field) => {
      setFocusStates((prev) => ({ ...prev, [field]: false }));
    };

    const handleDateChange = (e, inputName) => {
      const selectedDate = e.target.value;

      setInputColors((prevColors) => ({
        ...prevColors,
        [inputName]: selectedDate
          ? "var(--color-info-employee)"
          : "var(--color-text-unclick)",
      }));
    };

    const submitHandler = (data) => {
      if (!infident1?.status) {
        data.infident1 = infident1;
      }
      if (!infident2?.status) {
        data.infident2 = infident2;
      }
      if (!imagePayment?.status) {
        data.imagePayment = imagePayment;
      }
      onSubmit(data);
    };

    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          backgroundColor="var(--color-main)"
          borderRadius={"16px"}
          position={"relative"}
          w={"100%"}
          maxW={"625px"}
        >
          <form onSubmit={handleSubmit(submitHandler)}>
            <Box
              paddingBottom={"80px"}
              h={"86.5vh"}
              w={"100%"}
              overflowY={"scroll"}
              sx={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                  width: "4px",
                  borderRadius: "50px",
                  background: "var(--color-background)",
                },
                "&::-moz-scrollbar": {
                  width: "4px",
                },
                "&::-moz-scrollbar-thumb": {
                  width: "4px",
                  borderRadius: "50px",
                  background: "var(--color-background)",
                },
              }}
            >
              <Flex
                alignItems={"center"}
                p={"25px 24px 24px 24px"}
                position={"relative"}
                borderBottom={"1px solid var(--border-input-employee)"}
              >
                <Text
                  color={"var(--color-header-employee)"}
                  fontSize={"16px"}
                  fontWeight={"600"}
                >
                  {isEdit ? "Chỉnh sửa HDMB" : "Tạo HDMB"}
                </Text>
                <Box
                  position={"absolute"}
                  top={"24px"}
                  right={"24px"}
                  cursor={"pointer"}
                  onClick={onClose}
                >
                  <Image src={IconX} />
                </Box>
              </Flex>
              <Box
                paddingTop={"24px"}
                paddingBottom={"41px"}
                maxW={"545px"}
                margin={"0 auto"}
              >
                <Box>
                  <Text
                    color={"var(--color-header-employee)"}
                    fontSize={"16px"}
                    fontWeight={"600"}
                  >
                    Thông tin chung
                  </Text>
                  <Box mt={"16px"} borderRadius={"8px"}>
                    <Flex>
                      <InputControl
                        label="Tên hợp đồng mua bán"
                        placeholder="Nhập tên hợp đồng mua bán"
                        errors={errors.name}
                        register={register("name")}
                        onFocus={() => handleFocus("name")}
                        onBlur={() => handleBlur("name")}
                        state={focusStates["name"]}
                        mwidth="100%"
                      />
                    </Flex>
                    <Flex mt={"16px"} gap={"27px"}>
                      <InputControl
                        label="Mã hợp đồng"
                        colorIp={inputColors.code}
                        state={focusStates["code"]}
                        onFocus={() => handleFocus("code")}
                        onBlur={() => handleBlur("code")}
                        valueMain={watch("code")}
                        errors={errors?.code}
                        register={register("code")}
                      />
                      <InputControl
                        label={"Ngày ký hợp đồng"}
                        type="date"
                        colorIp={inputColors.time}
                        state={focusStates["time"]}
                        onFocus={() => handleFocus("time")}
                        onBlur={() => handleBlur("time")}
                        onChange={(e) => handleDateChange(e, "time")}
                        valueMain={watch("time")}
                        errors={errors?.time}
                        register={register("time")}
                      />
                    </Flex>
                    <Flex mt={"16px"} gap={"27px"}>
                      <InputControl
                        label="Dự án"
                        value={defaultValues?.project}
                        readonly={true}
                      />
                      <InputControl
                        label="Sản phẩm"
                        value={defaultValues?.apartment}
                        readonly={true}
                      />
                    </Flex>
                    <Flex mt={"16px"} gap={"27px"}>
                      <InputControl
                        label="Phiếu tính giá"
                        placeholder="Nhập phiếu tính giá"
                        errors={errors.invoice}
                        register={register("invoice")}
                        onFocus={() => handleFocus("invoice")}
                        onBlur={() => handleBlur("invoice")}
                        state={focusStates["invoice"]}
                        // mwidth={"100%"}
                      />
                      {/* <InputControl
                        label={"TG đặt cọc"}
                        type="date"
                        colorIp={inputColors.bookingTime}
                        state={focusStates["bookingTime"]}
                        onFocus={() => handleFocus("bookingTime")}
                        onBlur={() => handleBlur("bookingTime")}
                        onChange={(e) => handleDateChange(e, "bookingTime")}
                        valueMain={watch("bookingTime")}
                        errors={errors?.bookingTime}
                        register={register("bookingTime")}
                      /> */}
                    </Flex>
                    {isEdit && (
                      <Flex mt={"12px"} gap={"27px"}>
                        <Box flex={"1"}>
                          <Text
                            color={"var(--color-label-employee)"}
                            fontSize={"12px"}
                          >
                            Người lập HĐMB
                          </Text>
                          <Text
                            color={"var(--color-option-employee)"}
                            fontSize={"14px"}
                            fontWeight={"500"}
                            mt={"8px"}
                          >
                            {defaultValues?.creator || ""}
                          </Text>
                        </Box>
                        <Box flex={"1"}>
                          <Text
                            color={"var(--color-label-employee)"}
                            fontSize={"12px"}
                          >
                            Ngày lập HĐMB
                          </Text>
                          <Text
                            color={"var(--color-option-employee)"}
                            fontSize={"14px"}
                            fontWeight={"500"}
                            mt={"8px"}
                          >
                            {defaultValues?.createdAt || ""}
                          </Text>
                        </Box>
                      </Flex>
                    )}
                  </Box>
                </Box>
                <Box mt={"24px"}>
                  <Text
                    color={"var(--color-header-employee)"}
                    fontSize={"16px"}
                    fontWeight={"600"}
                  >
                    Thông tin khách hàng
                  </Text>
                  <Box mt={"16px"}>
                    <Flex maxW="259px">
                      <InputControl
                        label="Tên khách hàng"
                        value={defaultValues?.customer}
                        mwidth="259px"
                        readonly={true}
                      />
                    </Flex>
                  </Box>
                </Box>
                <Box mt={"16px"}>
                  <Flex gap={"27px"}>
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Ảnh CCCD mặt trước
                      </Text>
                      <Box
                        mt={"8px"}
                        border={"0.5px solid var(--bg-line-employee)"}
                        w={"259px"}
                        height={"146px"}
                        borderRadius={"8px"}
                      >
                        {infident1 && (
                          <Image
                            borderRadius={"11px"}
                            w={"100%"}
                            h={"100%"}
                            objectFit={"cover"}
                            src={`${baseURL}/${infident1}`}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Ảnh CCCD mặt trước
                      </Text>
                      <Box
                        mt={"8px"}
                        border={"0.5px solid var(--bg-line-employee)"}
                        w={"259px"}
                        height={"146px"}
                        borderRadius={"8px"}
                      >
                        {infident2 && (
                          <Image
                            borderRadius={"11px"}
                            w={"100%"}
                            h={"100%"}
                            objectFit={"cover"}
                            src={`${baseURL}/${infident2}`}
                          />
                        )}
                      </Box>
                    </Box>
                  </Flex>
                </Box>
                <Box mt={"16px"}>
                  <Flex>
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Ảnh ủy nhiệm chi
                      </Text>
                      <Flex
                        mt={"8px"}
                        border={"0.5px solid var(--bg-line-employee)"}
                        borderRadius={"8px"}
                        justifyContent={"center"}
                      >
                        <Box borderRadius={"11px"} w={"202px"} h={"300px"}>
                          {imagePayment && (
                            <Image
                              borderRadius={"11px"}
                              w={"100%"}
                              h={"100%"}
                              objectFit={"cover"}
                              src={`${baseURL}/${imagePayment}`}
                            />
                          )}
                        </Box>
                      </Flex>
                    </Box>
                  </Flex>
                </Box>
              </Box>
              <Box
                position={"absolute"}
                w={"100%"}
                bottom={"0"}
                bg={"var(--color-main)"}
                borderTop={"0.5px solid var(--bg-line-employee)"}
                borderRadius={"0px 0px 16px 16px"}
                zIndex={3}
              >
                <Flex p={"24px"} gap={"16px"} justifyContent={"flex-end"}>
                  <Button
                    w={"112px"}
                    p={"13px 20px"}
                    fontSize={"15px"}
                    fontWeight={600}
                    color={"var(--color-option-employee-hover)"}
                    borderRadius={"8px"}
                    border={"1px solid var(--color-option-employee-hover)"}
                    bg={"var(--color-main)"}
                    _hover={{
                      filter: "brightness(90%)",
                    }}
                    onClick={onClose}
                  >
                    Hủy
                  </Button>
                  <Button
                    type="submit"
                    w={"112px"}
                    p={"13px 20px"}
                    fontSize={"15px"}
                    fontWeight={600}
                    color={"var(--color-main)"}
                    borderRadius={"8px"}
                    bg={"var(--color-option-employee-hover)"}
                    _hover={{
                      filter: "brightness(90%)",
                    }}
                  >
                    Lưu
                  </Button>
                </Flex>
              </Box>
            </Box>
          </form>
        </ModalContent>
      </Modal>
    );
  }
);

export default FormDetail;
